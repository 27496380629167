
import React,{ Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import Header from '../Components/Header/header';

class PrivateGuard extends Component {
    dontShowAfterLogin = [
        '/signin',
        '/login',
        '/verify',
        '/reset-password',
        '/forgot-password'
    ];
    render() {
        const route = this.props.location?.pathname;

        let returnData = "";

        if (localStorage.getItem("token") && localStorage.getItem("userData")) {
            if (!this.dontShowAfterLogin.includes(route)) {
                returnData = <Route {...this.props} />;
            } else {
                returnData = (
                    <Redirect
                        to={{
                            pathname: "/home",
                            state: { from: this.props.location },
                        }}
                    />
                );
            }

        } else {
            console.log("LOGINED OUT")
            if (this.dontShowAfterLogin.includes(route)) {

                returnData = <Route {...this.props} />;
            } else {
                // returnData = <Route {...this.props} />;
                returnData = (
                    <Redirect
                        to={{
                            pathname: "/signin",
                            state: { from: this.props.location },
                        }}
                    />
                );
            }
        }

        return <div>{returnData}</div>;
    }
}

export default PrivateGuard;
