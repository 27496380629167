import React, {useEffect, useMemo, useState} from "react";
import {userActions, authActions, cartAction} from "../../Redux/Actions/index";
import { useDispatch, useSelector } from "react-redux";
import {
  validateEditProfileForm,
  validateChangePasswordForm,
  validateAddressEdit
} from "../../Validation";
import "../Profile/profile.scss";
import {
  Elements,
} from "@stripe/react-stripe-js";
import {NotificationManager} from "react-notifications";
import { loadStripe } from "@stripe/stripe-js";
import 'react-phone-number-input/style.css';
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';
import {resetError} from "../../Redux/Actions/commonAction";
import {resetSuccess} from "../../Redux/Actions/authActions";
import {resetProfileMessage} from "../../Redux/Actions/userAction";
import Visa from "../../Images/visa.png";
import Master from "../../Images/master.png";
import AmericanExpress from "../../Images/american_express.png";
import PaymentMethod from "./PaymentMethod";

const stripeElement = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);
const Profile = () => {
  const dispatch = useDispatch();

  const [editModel, setEditModel] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const userAddressData = useSelector((state) => state.userReducer.userData);
  const commonReducer = useSelector((state) => state.commonReducer);
  const [phoneInput, setPhoneInput] = useState('');
  const profileEdit = useSelector((state) => state.userReducer.profileUpdatedData);
  const authReducerData = useSelector((state) => state.authReducer);
  const { getCountriesList, getStateListByCountries, getCitiesByState } = useSelector((state) => state.userReducer);

  const [changePassState, setChangePass] = useState({
    oldPassword: "",
    confirmNewPassword: "",
    newPassword: "",
  });
  const [errorChangePassword, setErrorChangePassword] = useState({
    oldPassword: [],
    confirmNewPassword: [],
    newPassword: [],
  });
  const [state, setState] = useState({
    fullName: "",
    email: "",
    password: "",
    phoneNumber: "",
    address: "",
    city: "",
    country: "",
    state: "",
    country_id: null,
    state_id: null,
    city_id: null
  });

  const [addressState, setAddressState] = useState({
    address: "",
    city: "",
    country: "",
    state: "",
    zipCode: "",
    country_id: null,
    state_id: null,
    city_id: null
  });
  const [error, setError] = useState({
    address: [],
    city: [],
    country: [],
    state: [],
    zipCode: [],
  });
  const [profileError, setProfileError] = useState({
    full_name: [],
    email: [],
    phonenumber: [],
  });
  const [userAgreementData, setUserAgreementData] = useState({
    discount_percent: '',
    order_units: ''
  });
  const initPaymentMethodObj = {
    pId: '',
    userId: '',
    stripeCustomerId: '',
    last4: '',
    brand: '',
    country: '',
    exp_month: '',
    exp_year: '',
    funding: '',
    isRemoving: false
  };
  const [userPaymentMethod, setUserPaymentMethod] = useState(initPaymentMethodObj);

  useEffect(() => {
    (async () => {
      const id = await JSON.parse(localStorage.getItem("userData")).id;
      await dispatch(await userActions.userDataById(id));
      await dispatch(userActions.getCountries())
      if (getCountriesList) {
        if(userAddressData.country_id) {
          await dispatch(userActions.getStates(userAddressData.country_id))
        }
        if(userAddressData.state_id) {
          await dispatch(userActions.getCities(userAddressData.state_id))
        }
        // setState(userAddressData);
        setAddressState(userAddressData);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const id = await JSON.parse(localStorage.getItem("userData")).id;
      if(!!authReducerData.authData.isOrganisationUser) {
        const orgUserPromise = await Promise.all([
          dispatch(await userActions.userAgreementById(id)),
          dispatch(await userActions.userPaymentMethod(id))
        ]);
        const userAgreementData = orgUserPromise[0];
        const userPaymentMethodObj = orgUserPromise[1];
        if(userAgreementData.success && Object.keys(userAgreementData.data).length) {
          setUserAgreementData(userAgreementData.data);
        }
        if(userPaymentMethodObj.success && Object.keys(userPaymentMethodObj.data).length) {
          setUserPaymentMethod(userPaymentMethodObj.data);
        }
      }
    })();
  }, [authReducerData.authData.isOrganisationUser]);

  const renderPhoneInput = (userPhone) => {
    const phnInput = <PhoneInput
        defaultCountry="US"
        countries={["US"]}
        addInternationalOption={false}
        class="form-control"
        placeholder="(xxx) xxx-xxxx"
        value={userPhone? formatPhoneNumber(userPhone): state?.phonenumber}
        limitMaxLength={true}
        onChange={handlePhoneNumberChange}/>;

    setPhoneInput(phnInput);
  };

  useEffect(() => {
    if (userAddressData.id !== state?.id) {
      setState(userAddressData);
      setAddressState(userAddressData);
      if (userAddressData.country_id) {
        (async () => {
          if(userAddressData.country_id) {
            await dispatch(userActions.getStates(userAddressData.country_id))
          }
          if(userAddressData.state_id) {
            await dispatch(userActions.getCities(userAddressData.state_id))
          }
        })()
      }
      renderPhoneInput(userAddressData.phonenumber || null);
    }
  }, [userAddressData]);

  useEffect(() => {
    setState(userAddressData);
  }, [userAddressData.id]);


  function handleChangePassword(event) {
    const { name, value } = event.target;
    setChangePass((prevState) => ({ ...prevState, [name]: value }));
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  function handlePhoneNumberChange(value) {
    setState((prevState) => ({ ...prevState, 'phonenumber' : value }));
  }
  function handleChangeInAddressModel(event) {
    const { name, value } = event.target;
    if(name === 'zipCode') {
      const regEx = /^\b\d{0,5}\b$/;
      if (value === '' || regEx.test(value)) {
        setAddressState((prevState) => ({ ...prevState, [name]: value }));
      }
      return;
    }
    setAddressState((prevState) => ({ ...prevState, [name]: value }));
  }

  async function onSaveAddress(event) {
    event.preventDefault();

    const results = await validateAddressEdit(addressState);
    let err = results.error
    if (results.isFormValid === false) {
      // setError(results.error);
      setError(err)
    }
    if (results?.isFormValid) {
      err = {
        address: [],
        city: [],
        country: [],
        state: [],
        zipCode: [],
      };
      setError(err)
      const id = await JSON.parse(localStorage.getItem("userData")).id;
      await setState(addressState);

      addressState["fullName"] = state.full_name;
      addressState["phoneNumber"] = state.phonenumber;
      addressState["email"] = state.email;

      // validate the shipping address
      const validateAddress = await dispatch(userActions.validateAddress(addressState));
      if(!validateAddress.isSuccess) {
        NotificationManager.error(validateAddress.errorMessage);
        return;
      }
      await dispatch(userActions.updateProfile(addressState));
      await dispatch(authActions.matchToken());
      await dispatch(await userActions.userDataById(id));
      setEditModel(false);
    }
  }

  const onChangePassword = async (e) => {
    e.preventDefault();
    const results = await validateChangePasswordForm(changePassState);
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (!results.isFormValid) {
      setErrorChangePassword(results.error);
    }
    if (results?.isFormValid) {
      setEditPassword(false)
      setErrorChangePassword({
        oldPassword: [],
        confirmNewPassword: [],
        newPassword: [],
      });
      changePassState.email = userData.email;
      await dispatch(authActions.changePassword(changePassState));
    }
  };

  const onSubmit = async () => {
    const results = await validateEditProfileForm(state);
    console.log("state in results", results);
    if (results.isFormValid === false) {
      let err = results.error;
      setProfileError(err)
    }
    if (results?.isFormValid) {
      setProfileError({
        full_name: [],
        email: [],
        phonenumber: [],
      });
      state["fullName"] = state.full_name;
      state["phoneNumber"] = state.phonenumber;
      await dispatch(userActions.updateProfile(state));
      await dispatch(authActions.matchToken());
      const id = await JSON.parse(localStorage.getItem("userData")).id;
      await dispatch(await userActions.userDataById(id));
    }
  };


  const countryChange = async (e) => {
    e.preventDefault()
    let selectedCountry = getCountriesList.find((state) => state.id === (parseInt(e.target.value)))
    console.log(`seleredCountry`, selectedCountry)
    addressState[`country`] = selectedCountry.name
    addressState[`country_id`] = selectedCountry.id
    setAddressState({ ...addressState })
    await dispatch(userActions.getStates(selectedCountry.id))
  }

  const stateChange = async (e) => {
    e.preventDefault()
    let selectedState = getStateListByCountries.find((state) => state.id === (parseInt(e.target.value)))
    addressState[`state`] = selectedState.state_name
    addressState[`state_id`] = parseInt(selectedState.id)
    await dispatch(userActions.getCities(parseInt(selectedState.id)))
  }
  const cityChange = (e) => {
    let selectedCity = getCitiesByState.find((city) => city.id === (parseInt(e.target.value)))
    addressState[`city`] = selectedCity.city
    addressState[`city_id`] = selectedCity.id
    setAddressState(prevMovies => ({ ...prevMovies, ...addressState }))
    // setAddressState(addressState)
  };


  useEffect(() => {
    setAddressState(addressState)
  }, [addressState]);

  const onSetEditPassword = (value) => {
    setEditPassword(value);
    setChangePass({
      oldPassword: "",
      confirmNewPassword: "",
      newPassword: "",
    });
    setErrorChangePassword({
      oldPassword: [],
      confirmNewPassword: [],
      newPassword: [],
    });
  };

  const message1 = useMemo(() => {
    if(profileEdit?.message) {
      const val = profileEdit?.message ?
          NotificationManager.success(profileEdit.message):
          null;
      dispatch(resetProfileMessage());
      return val;
    }

  }, [profileEdit]);

  const message2 = useMemo(() => {
    if(authReducerData?.message) {
      const val =  authReducerData?.message && authReducerData?.success ? (
          NotificationManager.success(authReducerData?.message)
      ) : authReducerData?.message && !authReducerData?.success ? (
          NotificationManager.error(authReducerData?.message)
      ) : null;
      dispatch(resetSuccess());
      return val;
    }

  }, [authReducerData]);

  const message3 = useMemo(() => {
    if(commonReducer?.errorMsg) {
      const val = commonReducer?.errorMsg && commonReducer?.success !== true ? (
          NotificationManager.error(commonReducer?.errorMsg || authReducerData?.message)
      ) : null;
      dispatch(resetError());
      return val;
    }
  }, [commonReducer]);

  const handlePaymentMethodRemove = async () => {
    if (!window.confirm('Are you sure you want to remove this payment method')) {
      return;
    }
    setUserPaymentMethod(prev => ({...prev, isRemoving: true}));
    const id = await JSON.parse(localStorage.getItem("userData")).id;
    const res = await dispatch(userActions.removePaymentMethod(id));
    if(!res.success) {
      NotificationManager.error(res.errorMessage);
    } else {
      NotificationManager.success(res.message);
    }
    setUserPaymentMethod(initPaymentMethodObj);
  };

  return (
    <div className="profile-main">
      <div className="container">
        <h2>Personal Information</h2>
        {message1 || message2 || message3}
        <form>
          <div className="form-group">
            <label>Full Name</label>
            <input
              type="text"
              class="form-control"
              name="full_name"
              value={state?.full_name}
              onChange={(e) => handleChange(e)}
            />
            {profileError?.full_name[0] ? (
              <p className="error-validation">{profileError?.full_name[0]}</p>
            ) : null}
          </div>
          <div className="form-group">
            <label>Email Address</label>
            <input
              type="email"
              class="form-control"
              disabled
              name="email"
              value={state?.email}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {profileError?.email[0] ? (
            <p className="error-validation">{profileError?.email[0]}</p>
          ) : null}
          <div className="form-group">
            <label>
              Password{" "}
              <span data-toggle="modal" onClick={() => onSetEditPassword(true)}>
                Change Password
              </span>
            </label>
            <input
              type="password"
              class="form-control"
              value="**************"
              disabled
            // onChange={(e) => handleChange(e)}
            />
          </div>

          <div className="form-group">
            <label>Phone Number</label>
            {phoneInput}

            {/*<input*/}
            {/*  type="tel"*/}
            {/*  class="form-control"*/}
            {/*  name="phonenumber"*/}
            {/*  value={state?.phonenumber}*/}
            {/*  onChange={}*/}
            {/*/>*/}
            {profileError?.phonenumber[0] ? (
              <p className="error-validation">{profileError?.phonenumber[0]}</p>
            ) : null}
          </div>
        </form>

        <div className={"d-flex flex-row"}>
          <div className="address-group col-lg-6 pl-0">
            <div className="">
              <h2>Manage Address</h2>
              <div className="col-lg-12 pl-0">
                <div className="address-desc active">
                  <div className="default-desc">
                    <h4>{state?.full_name}</h4>
                  </div>
                  {state?.address ? <p> Address: {state?.address} </p> : null}
                  {state?.country ? <p> Country: {state?.country} </p> : null}
                  {state?.state ? <p> State: {state?.state} </p> : null}
                  {state?.city ? <p> City: {state?.city} </p> : null}
                  {state?.zipCode ? <p> Zip Code: {state?.zipCode} </p> : null}
                  <div className="address-crud">
                    <ul>
                      <li onClick={() => setEditModel(true)}>
                        {state?.address ? "Edit" : "Add Address"}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="address-group col-lg-6 pl-0">
            {
              !!authReducerData.authData.isOrganisationUser &&
              <div className={"d-flex flex-column"}>
                <div className="address-group col-lg-12 pl-0 mb-0">
                  <div className="">
                    <h2>User Agreement</h2>
                    <div className="col-lg-12 pl-0">
                      Allow <b>{userAgreementData.discount_percent}</b>% discount to the organization if the order exceeds <b>{userAgreementData.order_units}</b> units.
                    </div>
                  </div>
                </div>
                <div className="address-group payment col-lg-12 pl-0">
                    <h2>Payment Methods</h2>
                  {
                    userPaymentMethod.pId ?
                        (
                            <>
                              <div className="col-lg-12 pl-0">
                                <p>This payment method will be used by your employees to make payment for the orders they make</p>
                                <div className={'card-detail-container'}>
                                  <div>
                                    { userPaymentMethod.brand === 'visa' && <img src={Visa} alt="visa"/>}
                                    { userPaymentMethod.brand === 'mastercard' && <img src={Master} alt="master_card"/>}
                                    { userPaymentMethod.brand === 'amex' && <img src={AmericanExpress} alt="american_express"/>}
                                  </div>
                                  <div className="card-detail">
                                    <div>**** **** **** {userPaymentMethod.last4}</div>
                                    <div className={"ending"}>Ending in <span>{`${userPaymentMethod.exp_month}/${userPaymentMethod.exp_year}` }</span></div>
                                    <span></span>
                                  </div>
                                  <div>
                                    <button
                                        type={"button"}
                                        className="red-btn-border"
                                        onClick={handlePaymentMethodRemove}
                                    >
                                      <span id="button-text">
                                          Remove
                                          <span className={userPaymentMethod.isRemoving && "spinner-border"}></span>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                        ):
                        (
                            <>
                              <div className="col-lg-12 pl-0">
                                <div>
                                  <img src={Visa} alt="visa"/>
                                  <img src={Master} alt="master_card"/>
                                  <img src={AmericanExpress} alt="american_express"/>
                                </div>
                                <p>All transactions are secure and encrypted.</p>
                                <div className="stripe-container">
                                  <Elements stripe={stripeElement}>
                                    <PaymentMethod
                                        setUserPaymentMethod={setUserPaymentMethod}
                                    />
                                  </Elements>
                                </div>
                              </div>
                            </>
                        )
                  }
                </div>

              </div>
            }
          </div>
        </div>

        <div>
          <button className="red-btn" onClick={(e) => onSubmit(e)}>
            Update Profile
            <span
              className={commonReducer.loading === true ? "spinner-border" : ""}
            ></span>
          </button>
        </div>
      </div>

      {editPassword === true ? (
        <div
          className="modal"
          id="change-inner"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Change Password</h4>
                <button
                  type="button"
                  class="close"
                  onClick={() => setEditPassword(false)}
                >
                  <span>
                    <i class="far fa-times-circle"></i>
                  </span>
                </button>
              </div>

              <div className="modal-body">
                <form className="change-form">
                  <div class="form-group">
                    <label for="email">Old Password</label>
                    <input
                      type="password"
                      name="oldPassword"
                      class="form-control"
                      onChange={(e) => {
                        handleChangePassword(e);
                      }}
                    />
                    {errorChangePassword?.oldPassword[0] ? (
                      <p className="error-validation">
                        {errorChangePassword?.oldPassword[0]}
                      </p>
                    ) : null}
                  </div>
                  <div class="form-group">
                    <label for="email">New Password</label>
                    <input
                      type="password"
                      class="form-control"
                      name="newPassword"
                      onChange={(e) => {
                        handleChangePassword(e);
                      }}
                    />
                    {errorChangePassword?.newPassword[0] ? (
                      <p className="error-validation">
                        {errorChangePassword?.newPassword[0]}
                      </p>
                    ) : null}
                  </div>
                  <div class="form-group">
                    <label for="email">Confirm Password</label>
                    <input
                      type="password"
                      class="form-control"
                      name="confirmNewPassword"
                      onChange={(e) => {
                        handleChangePassword(e);
                      }}
                    />
                    {errorChangePassword?.confirmNewPassword[0] ? (
                      <p className="error-validation">
                        {errorChangePassword?.confirmNewPassword[0]}
                      </p>
                    ) : null}
                  </div>
                  <div className="text-right">
                    <button
                      onClick={(e) => onChangePassword(e)}
                      className="red-btn"
                    >
                      Change Password
                      <span
                        className={
                          commonReducer.loading === true ? "spinner-border" : ""
                        }
                      ></span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {editModel === true ? (
        <div
          className="modal"
          id="edit-inner"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Address</h4>
                <button
                  type="button"
                  class="close"
                  onClick={() => setEditModel(false)}
                >
                  {/*  data-dismiss="modal" */}
                  <span>
                    <i class="far fa-times-circle"></i>
                  </span>
                </button>
              </div>

              <div className="modal-body">
                <form className="change-form">
                  <div class="form-group">
                    <label for="email">Address </label>
                    <input
                      name="address"
                      value={addressState.address}
                      onChange={(e) => {
                        handleChangeInAddressModel(e);
                      }}
                      type="text"
                      class="form-control"
                    />
                    {error?.address[0] ? (
                      <p className="error-validation">{error?.address[0]}</p>
                    ) : null}
                  </div>
                  <div className="row">
                    <div class="form-group col-md-6">
                      <label for="email">Country</label>
                      <select
                        className="form-control"
                        onChange={(e) => countryChange(e)}
                        value={addressState?.country_id || ''}
                      >
                        <option value="" selected disabled>
                          Select Country
                            </option>
                        {
                          getCountriesList?.map((country, index) =>
                            <option key={index}
                              value={country.id} >
                              {country.name}
                            </option>
                          )}
                      </select>

                      {/* <input
                        type="text"
                        value={addressState.city}
                        class="form-control"
                        name="city"
                        onChange={(e) => {
                          handleChangeInAddressModel(e);
                        }}
                      /> */}
                      {error?.country[0] ? (
                        <p className="error-validation">{error?.country[0]}</p>
                      ) : null}
                    </div>
                    <div class="form-group col-md-6">
                      <label for="email">State</label>
                      <select
                        className="form-control"
                        value={addressState?.state_id}
                        onChange={(e) => stateChange(e)}>
                        <option value="" selected disabled>
                          Select State
                            </option>
                        {
                          getStateListByCountries?.map((state, index) =>
                            <option key={index}
                              value={state.id}>
                              {state.state_name}
                            </option>
                          )}
                      </select>

                      {error?.state[0] ? (
                        <p className="error-validation">{error?.state[0]}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div class="form-group col-md-6">
                      <label for="email">City</label>
                      <select
                        value={addressState?.city_id}
                        class="form-control"
                        onChange={(e) => cityChange(e)}>
                        <option
                          value=""
                          selected
                          disabled>
                          Select City
                          </option>
                        {
                          getCitiesByState?.map((city, index) =>
                            <option key={index}
                              value={city.id}>
                              {city.city}
                            </option>
                          )}
                      </select>
                      {/* <input
                        type="text"
                        class="form-control"
                        value={addressState.country}
                        name="country"
                        onChange={(e) => {
                          handleChangeInAddressModel(e);
                        }}
                      /> */}
                      {error?.city[0] ? (
                        <p className="error-validation">{error?.city[0]}</p>
                      ) : null}

                    </div>
                    <div class="form-group col-md-6">
                      <label for="zipCode">Zip Code</label>
                      <input
                        type="text"
                        value={addressState.zipCode}
                        class="form-control"
                        name="zipCode"
                        onChange={(e) => {
                          handleChangeInAddressModel(e);
                        }}
                      />
                      {error?.zipCode[0] ? (
                        <p className="error-validation">{error?.zipCode[0]}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="text-right">
                    <button
                      onClick={(e) => onSaveAddress(e)}
                      className="red-btn"
                    >
                      Save Changes
                      <span
                        className={commonReducer.loading === true ? "spinner-border" : ""}
                      ></span>
                    </button>
                    {/* data-dismiss="modal" aria-label="Close"  data-target="#edit-inner" */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Profile;
