import Api from "../../Api";
import { handleError } from "./commonAction";

export const getProductByCategory = (slug, start, limit) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/product/category/${slug}?start=${start}&limit=${limit}`);

    console.log("USER_DATA_BY_ID", Date.now())

    await dispatch({
      type: "PRODUCTS_BY_CATEGORIES",
      payload: { data: res?.data?.data },
      timeStamp: Date.now()
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};


export const checkValidQuantity = (itemId, quantity) => async (dispatch) => {
  try {
    let cartItems = [], selectedQuantity = 0;
    if (localStorage.getItem("cartItems")) {
      cartItems = JSON.parse(localStorage.getItem("cartItems"));
    }
    if (cartItems && cartItems.length > 0) {
      const selectedCartItem = cartItems.find(elem => (elem.id === itemId));
      selectedQuantity =  selectedCartItem? selectedCartItem.selectedQuantity: 0;
    }

    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/product/check/product-quantity?productId=${itemId}&quantity=${quantity+selectedQuantity}`);

    dispatch({
      type: "CHECK_VALID_QUANTITY_COMPLETE",
      payload: { data: res.data }
    });

    dispatch({ type: "LOADING_COMPLETED" });
    return { data: res.data };
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const searchProduct = (searchKeyword) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/product/search?limit=5&start=0&search=${searchKeyword}`);
    console.log("search result from backend", JSON.stringify(res));
    dispatch({ type: "LOADING_COMPLETED" });
    //   await dispatch({
    //     type: "CLEAR_PRODUCT_LIST"
    // });
    dispatch({
      type: "PRODUCTS_BY_CATEGORIES",
      payload: { data: res.data?.data },
      timeStamp: Date.now()
    });
    dispatch({
      type: "SEARCH_LIST",
      payload: { data: res.data?.data },
    });
  }
  catch (error) {
    console.log("catch");
    dispatch(handleError(error));
  }
}

export const getProductDetail = (itemId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/product/detail/${itemId}`);
    console.log("data from backend", res);
    dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "PRODUCT_DETAIL",
      payload: { data: res.data?.data },
    });
  } catch (error) {
    console.log("catch");
    dispatch(handleError(error));
  }
}

export const selectCategoryPage = (categoryId) => async (dispatch) => {
  dispatch({
    type: "CATEGORY_ID",
    payload: categoryId,
  });
}


export const clearProductList = () => async (dispatch) => {
  console.log("ATTEND-FOR-CLEARLIST")
  dispatch({ type: "CLEAR_PRODUCT_LIST" });
};

// list all subscription for a user

export const getSubscriptionByUserId =(userId,subscriptionType)=>async(dispatch)=>{
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/subscriptions/${userId}?subscriptionType=${subscriptionType}`);
    console.log("data from backend", res);
    dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "SUBSCRIPTION_LIST",
      payload: { data: res.data?.data },
    });
  } catch (error) {
    console.log("catch");
    dispatch(handleError(error));
  }
}

export const deleteSubscription = (subscriptionId)=>async(dispatch)=>{
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/stripe/cancelSubscription`,{subscriptionId});
    console.log("data from backend", res);
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    console.log("catch");
    dispatch(handleError(error));
  }
}


export const getCategory = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/product/category`);

    // console.log("USER_DATA_BY_ID*********", JSON.stringify(res.data.data))

    await dispatch({
      type: "CATEGORIES",
      payload: { data: res?.data?.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};

export const resetCheckQuantity = () => (dispatch) => {
  dispatch({ type: "RESET_CHECK_QUANTITY" });
};
