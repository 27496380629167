export const initialState = {
    kitId: '',
    testName: '',
    testImage: '',
    firstName: '',
    lastName: '',
    dob: '',
    sex: '',
    addressLine1: '',
    suit: '',
    country: '',
    state: '',
    city: '',
    zip: '',
    phoneNumber: '',
    emailId: '',
    isSaving: false,
};

export const kitReducer = (state = initialState, action) => {

    switch (action.type) {
        case "KIT_ID_VERIFIED":
            const emailId = JSON.parse(localStorage.getItem("userData")).email;
            return {
                ...state,
                kitId: action.payload.registerKitId,
                testName: action.payload.testName,
                testImage: action.payload.testImage,
                emailId
            };

        case "KIT_TESTER_DETAIL_ADD":
            return {
                ...state,
                ...action.payload
            };

        case "SAVING_KIT_ADD":
            return {
                ...state,
                isSaving: true
            };

        case "SAVED_KIT_ADD":
            return {
                ...state,
                isSaving: false
            };

        case "SAVED_KIT_FAILURE":
            return {
                ...state,
                isSaving: false
            };

        case "KIT_REGISTRATION_RESET":
            return {
                ...initialState
            };

        default:
            return state;
    }
};
