import React, {useEffect, useState} from "react";
import "../Contact/contact.scss";
import Message from "../../Images/message.png";
import { submitQuery, resetSuccess } from '../../Redux/Actions/orderAction'
import { resetError } from '../../Redux/Actions/commonAction'
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import {NotificationManager} from "react-notifications";
import PhoneInput from "react-phone-number-input";


const Contact = (props) => {
  const dispatch = useDispatch()
  const commonData = useSelector((state) => state.commonReducer);
  const [formData, setFormData] = useState({
    subject: '',
    email: JSON.parse(localStorage.getItem('userData'))?.email,
    name: '',
    phoneNumber: "",
    text: ''
  })

  const [error, setError] = useState({
    subjectError: '',
    emailError: '',
    nameError: '',
    textError: ''
  })
  const errorRes = useSelector((state) => state.commonReducer);
  const messageRes = useSelector((state)=>state.orderReducer.message);

useState(()=>{
return ()=>{
  dispatch({ type: "CLEAN_ERROR" });
  dispatch({ type: "CLEAN_MESSAGE" });
}
},[])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((Prev) => ({ ...Prev, [name]: value }))
  }

  const handlePhoneChange = (value) => {
    setFormData((Prev) => ({ ...Prev, 'phoneNumber': value }))
  }

  const handleValidation = () => {

    let flag = false;
    let err = {}
    if (_.isEmpty(formData.subject)) {
      err['subjectError'] = 'Field is required';
      flag = true
    }
    if (_.isEmpty(formData.email)) {
      err['emailError'] = 'Field is required';
      flag = true
    }
    if (_.isEmpty(formData.name)) {
      err['nameError'] = 'Field is required';
      flag = true
    }
    if (_.isEmpty(formData.text)) {
      err['textError'] = 'Field is required';
      flag = true
    }
    setError({ ...err });
    console.log("err",err,flag,formData);
    return flag
  }
  const handleSubmit = async(e) => {
    e.preventDefault();

    if (!handleValidation()) {
       await dispatch(submitQuery(formData))
       window.scrollTo(0,100)
    }
  }

  const successMsg = useEffect(() => {
    if(messageRes) {
      NotificationManager.success(messageRes);
      dispatch(resetSuccess());
      return;
    }
  }, [messageRes]);

  const errorMsg = useEffect(() => {
    if(errorRes?.errorMsg) {
      NotificationManager.error(errorRes?.errorMsg);
      dispatch(resetError());
      return;
    }
  }, [errorRes?.errorMsg]);

  return (
    <div className="contact-main">
      <div className="container" style={{position:'relative'}}>
        <h2>Support</h2>
        <p>
          Let's get in touch. Drop us a message and we'll get back to you as
          soon as possible.
        </p>

        {
          messageRes ? successMsg : errorRes?.errorMsg ? errorMsg : null
        }
        <form >
          <div className="contact-heading">
            <div className="contact-img">
              <img src={Message} />
            </div>
            <h4>Customer Support</h4>
          </div>
          <div class="form-group">
            <label>Subject<sup>*</sup></label>
            <input type="text" name='subject' class="form-control" value={formData.subject} onChange={(e) => handleChange(e)} />

              {error?.subjectError ? <p className="error-validation">{error.subjectError}</p> : null}
          </div>
          <div class="form-group">
            <label>Your Name<sup>*</sup></label>
            <input type="text" name={'name'} class="form-control" value={formData.name} onChange={(e) => handleChange(e)} />

            {error?.nameError ? <p className="error-validation">{error.nameError}</p> : null}
          </div>
          <div class="form-group">
            <label>Email Address<sup>*</sup></label>
            <input type="email" name={'email'} class="form-control" value={formData.email} onChange={(e) => handleChange(e)} />

            {error?.emailError ? <p className="error-validation">{error.emailError}</p> : null}
          </div>
          <div class="form-group">
            <label>Contact number(optional)</label>
            <PhoneInput
                defaultCountry="US"
                countries={["US"]}
                addInternationalOption={false}
                class="form-control"
                placeholder="(xxx) xxx-xxxx"
                value={formData.phoneNumber}
                limitMaxLength={true}
                onChange={handlePhoneChange}/>
          </div>
          <div class="form-group">
            <label>Your Enquiry<sup>*</sup></label>
            <textarea class="form-control" name={'text'} rows="5" value={formData.text} onChange={(e) => handleChange(e)} ></textarea>

            {error?.textError ? <p className="error-validation">{error.textError}</p> : null }
          </div>
          <button className="red-btn" onClick={(e) => handleSubmit(e)}>
            Send Message <span className={commonData.loading === true ? "spinner-border" : ""}></span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
