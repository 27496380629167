import React,{ createRef, useEffect, useState, useRef } from "react";
import { useSelector,useDispatch } from "react-redux";
import SignupForm from "../../Modules/signup";
import LoginForm from "../../Modules/login";

import "../Signin/signin.scss";
import {NotificationManager} from "react-notifications";

const scrollToRef = (ref) => window.scrollTo(0, 230)

const Signin = (props) => {
  const dispatch = useDispatch();
  let myDivToFocus = createRef();
  const myRef = useRef(null)
  const [state, setState] = useState({ isSignupActive: true, activeId: "signUp" })
  const errorRes = useSelector((state) => state.commonReducer);
  const authMessage = useSelector((state) => state.authReducer.message);
useEffect(()=>{
console.log("authMessage",authMessage)
},[authMessage])
  return (
    <div className="signin-main-all" >
      <div className="sigin-desc" ref={myDivToFocus}>
        <div className="container">
          <h2>Your Cart</h2>
          <div className="signin-process" >
            <ul>
              <li>
                <div className="check-round active"></div>
                <p className="active">Sign In</p>
              </li>
              <li>
                <div className="check-round"></div>
                <p>Shipping</p>
              </li>
              <li>
                <div className="check-round"></div>
                <p>Billing</p>
              </li>
              <li>
                <div className="check-round"></div>
                <p>Confirmation</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="cutomer-details">
        <div className="container">
          <div className="form-toggle">
            <div className="toggle-button">
              <p className={state.isSignupActive === true ? "active" : ""} onClick={() => {
                setState((prev) => ({ ...prev, isSignupActive: true }));
                dispatch({type: 'CLEAN_ERROR'})
                dispatch({ type: "CLEAR_LOGIN" });
              }}>Sign Up</p>
              <p className={state.isSignupActive === false ? "active" : ""} onClick={() => {
                setState((prev) => ({ ...prev, isSignupActive: false }));
                dispatch({type: 'CLEAN_ERROR'})
                dispatch({ type: "CLEAR_LOGIN" });
              }}>Login</p>
            </div>
            {/* {authMessage} const executeScroll = () => scrollToRef(myRef)
            {errorRes.errorMsg} */}

            { authMessage ?
                NotificationManager.success(authMessage)
                : errorRes?.errorMsg ? NotificationManager.error(errorRes?.errorMsg)
                    : null
            }

            {state.isSignupActive === true ? <SignupForm scrollToRef={scrollToRef} myRef={myRef}  myDivToFocus={myDivToFocus} /> : <LoginForm scrollToRef={scrollToRef} myRef={myRef}  myDivToFocus={myDivToFocus} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
