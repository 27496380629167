import React, {useCallback, useEffect, useState} from "react";
import {employeeAction} from "../../Redux/Actions/index";
import { useDispatch, useSelector } from "react-redux";
import "../Employees/employees.scss";
import {NotificationManager} from "react-notifications";
import { debounce } from 'lodash';
import { formatPhoneNumber } from 'react-phone-number-input';

const Employees = (props) => {
    let dispatch = useDispatch();
    const authData = useSelector((state) => state.authReducer.authData);

    const [isLoadingEmployees, setIsLoadingEmployees] = useState(false);
    const [employeeList, setEmployeeList] = useState([]);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        (async () => {
            if(authData.isOrganisationUser === 0) {
                return;
            }
            await fetchEmployees();
        })();
    }, []);

    useEffect(() => {
      if(authData.isOrganisationUser === 0) {
          NotificationManager.error('You do not have permission to access this page');
          props.history.push('/');
          return;
      }
    }, [authData.isOrganisationUser]);

    const fetchEmployees = async (searchValue) => {
        setIsLoadingEmployees(true);
        const employeeList = await dispatch(employeeAction.getEmployeeList(searchValue));
        setIsLoadingEmployees(false);
        if(employeeList.isSuccess) {
            setEmployeeList(employeeList.data);
        }
    };

    const onSearchEmployees = (e) => {
        const value = e.target.value;
        setSearchText(value);
        if(value.length > 3) {
            debounceSearchCall(value? value.trim().toLowerCase(): '');
        }
        if(!value || value.length === 0) {
            fetchEmployees();
        }
    };

    const debounceSearchCall = useCallback(debounce(fetchEmployees, 1000), []);

    return (
        <div className="employees-main">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-4">
                        <div className="heading">
                            <label>My Employees</label>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8">
                        <div className="employee-table-head-right">
                            <div className="employee-table-search mr-2">
                                <input className="form-control"
                                       type="text" placeholder="search"
                                       value={searchText}
                                       onChange={(e) => onSearchEmployees(e)}
                                />

                                <span className={"add-btn"} title={"Add Employee"}
                                    onClick={() => props.history.push("/add-employee")}
                                >
                                  <i className="fas fa-plus"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Address</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                                isLoadingEmployees?
                                    (<tr>
                                        <td className="text-center" colSpan={6}>
                                    <span
                                        className={"spinner-border"}
                                    ></span>
                                        </td>
                                    </tr>) :
                                    (
                                        employeeList.length === 0?
                                            (
                                                <tr><td className="text-center" colSpan={6}>
                                                   There are no employees added for your organization
                                                </td></tr>
                                            ):
                                            employeeList.map((employee, index) => {
                                                return (
                                                    <tr key={employee.id}>
                                                        <td>{employee.full_name}</td>
                                                        <td>{employee.email}</td>
                                                        <td>{employee.phonenumber? formatPhoneNumber(employee.phonenumber): ''}</td>
                                                        <td className={'w-25'}>
                                                            {employee.address}, {employee.city}, {employee.state}, {employee.country} - {employee.zipCode}
                                                        </td>
                                                        <td><span className={employee.isActive === 1? 'active': 'inactive'}> {employee.isActive === 1? 'Active': 'Inactive'}</span></td>
                                                        <td><button className="red-btn-border" onClick={()=>{props.history.push(`/employees/${employee.id}`)}}>view</button></td>
                                                    </tr>
                                                )
                                            })
                                    )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Employees;
