import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {NotificationManager} from 'react-notifications';

import "./covid.scss";
import { covidAction } from "../../Redux/Actions";

const CovidAssessment = (props) => {
    const dispatch = useDispatch();
    const covidReducer = useSelector((state) => state.covidReducer);
    const initError = {
        future_travel_recreation: '',
        possible_social_distancing: '',
        closed_contact: '',
        risk_resident: '',
        work_in_healthcare: '',
        risk_workplace: '',
        asked_for_test: '',
        symptoms_experienced: '',
        no_symptoms_selected: false,
        symptoms_date_selected: false,
        is_acknowledge: '',
    };

    const [onSavingAssessment, setOnSavingAssessment] = useState(false);
    const [assessmentDetail, setAssessmentDetail] = useState({
        future_travel_recreation: null,
        possible_social_distancing: null,
        closed_contact: null,
        risk_resident: null,
        work_in_healthcare: null,
        risk_workplace: null,
        asked_for_test: null,
        symptoms_experienced: null,
        symptoms: {
            is_chills: false,
            is_headache: false,
            is_congestion: false,
            is_muscle_ache: false,
            is_cough: false,
            is_nausea: false,
            is_diarrhea: false,
            is_loss_of_taste: false,
            is_fatigue: false,
            is_difficult_breathing: false,
            is_fever: false,
            is_sore_throat: false,
            symptom_date: null,
        },
        is_acknowledge: false
    });
    const [error, setError] = useState(initError);

    useEffect(() => {
        if(!covidReducer.searchLocation) {
            props.history.push('/covid-19-testing');
        }
    }, []);

    useEffect(() => {
        if(covidReducer.error) {
            NotificationManager.error(covidReducer.error);
            dispatch(covidAction.resetError());
        }
    }, [covidReducer.error]);

    const onSubmit = (e) => {
        e.preventDefault();
    };

    function handleRadioChange(event) {
        const { name, value } = event.target;
        setAssessmentDetail((prevState) => ({ ...prevState, [name]: value }));
        setError((prevState) => ({ ...prevState, [name]: null }));
    }

    function handleCbChange(event) {
        const { name, checked } = event.target;
        setAssessmentDetail((prevState) => ({
            ...prevState,
            symptoms: {
                ...prevState.symptoms,
                [name]: checked
            }
        }));
        setError((prevState) => ({ ...prevState, no_symptoms_selected: false }));
    }

    function handleSymptomDateChange(event) {
        const { name, value } = event.target;
        setAssessmentDetail((prevState) => ({
            ...prevState,
            symptoms: {
                ...prevState.symptoms,
                [name]: value
            }
        }));
        setError((prevState) => ({ ...prevState, symptoms_date_selected: false }));
    }

    function handleAcknowledgeChange(event) {
        const { name, checked } = event.target;
        setAssessmentDetail((prevState) => ({
            ...prevState,
            [name]: checked
        }));
        if(checked) {
            setError(prev => ({
                ...prev,
                is_acknowledge: null
            }))
        }
    }

    const validateForm = () => {
        let isError = false;
        if(!assessmentDetail.future_travel_recreation) {
            setError(prev => {
                return {
                    ...prev,
                    future_travel_recreation: 'This field is required'
                }
            });
            isError = true;
        }
        if(!assessmentDetail.possible_social_distancing) {
            setError(prev => {
                return {
                    ...prev,
                    possible_social_distancing: 'This field is required'
                }
            });
            isError = true;
        }
        if(!assessmentDetail.closed_contact) {
            setError(prev => {
                return {
                    ...prev,
                    closed_contact: 'This field is required'
                }
            });
            isError = true;
        }
        if(!assessmentDetail.risk_resident) {
            setError(prev => {
                return {
                    ...prev,
                    risk_resident: 'This field is required'
                }
            });
            isError = true;
        }
        if(!assessmentDetail.work_in_healthcare) {
            setError(prev => {
                return {
                    ...prev,
                    work_in_healthcare: 'This field is required'
                }
            });
            isError = true;
        }
        if(!assessmentDetail.risk_workplace) {
            setError(prev => {
                return {
                    ...prev,
                    risk_workplace: 'This field is required'
                }
            });
            isError = true;
        }
        if(!assessmentDetail.asked_for_test) {
            setError(prev => {
                return {
                    ...prev,
                    asked_for_test: 'This field is required'
                }
            });
            isError = true;
        }
        if(!assessmentDetail.symptoms_experienced) {
            setError(prev => {
                return {
                    ...prev,
                    symptoms_experienced: 'This field is required'
                }
            });
            isError = true;
        }
        let selectedSymptoms = false;
        Object.keys(assessmentDetail.symptoms).forEach((key) => {
            if(assessmentDetail.symptoms[key]) {
                selectedSymptoms = true;
            }
        });
        if(assessmentDetail.symptoms_experienced === '1' && !selectedSymptoms) {
            setError(prev => {
                return {
                    ...prev,
                    no_symptoms_selected: true
                }
            });
            isError = true;
        }
        if(assessmentDetail.symptoms_experienced === '1' && !assessmentDetail.symptoms.symptom_date) {
            setError(prev => {
                return {
                    ...prev,
                    symptoms_date_selected: true
                }
            });
            isError = true;
        }
        if(!assessmentDetail.is_acknowledge) {
            setError(prev => {
                return {
                    ...prev,
                    is_acknowledge: 'This field is required'
                }
            });
            isError = true;
        }
        return isError;
    };

    const onSaveAssessment = () => {
        let isError = validateForm();

        if(isError) {
            NotificationManager.error("Missing or invalid information. Please check the form again.");
            return;
        }
        setError(initError);
        setOnSavingAssessment(true);
        dispatch(covidAction.setAssessment(assessmentDetail));
        setOnSavingAssessment(false);
        props.history.push('/covid-19-testing/covid-scheduler');
    };

    return (
        <div id="assessment-container">
            <div className="container">
                <div id="assessment-row" className="row justify-content-center align-items-center">
                    <div id="assessment-box" className="col-md-12">
                        <form id="assessment-form" className="form" onSubmit={onSubmit}>
                            <h2 className="text-info mb-3">Answer a few questions for no-cost testing</h2>
                            <h4 className="text-info">Preventing the spread of COVID-19</h4>

                            <div className={"alert alert-secondary"}>
                                <span className={"fa fa-info-circle"}></span> All fields are required unless marked optional. There may be more questions based on your
                                answer.
                            </div>

                            <div className={"section"}>
                                <h5>Travel and exposure</h5>
                                <div className={"section-content col-md-12"}>
                                    <div>
                                        <div className="form-group" data-hasError={!!error.future_travel_recreation}>
                                            <label>
                                                <b>Are you seeking a test to prevent possible spread of COVID-19 for future travel or recreation</b> (according to CDC guidelines)?
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row" onChange={(e) => handleRadioChange(e)}>
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="future_travel_recreation" value="1" className={'mr-2'}
                                                        checked={assessmentDetail.future_travel_recreation === '1'}
                                                    />
                                                    Yes
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="future_travel_recreation" value="0" className={'mr-2'}
                                                        checked={assessmentDetail.future_travel_recreation === '0'}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"mt-4"}>
                                        <div className="form-group" data-hasError={!!error.possible_social_distancing}>
                                            <label>
                                                <b>Are you seeking a test to prevent possible spread of COVID-19 after being at a place where social distancing was not possible?</b>
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row"  onChange={(e) => handleRadioChange(e)}>
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="possible_social_distancing" value="1" className={'mr-2'}
                                                        checked={assessmentDetail.possible_social_distancing === '1'}
                                                    />
                                                    Yes
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="possible_social_distancing" value="0" className={'mr-2'}
                                                        checked={assessmentDetail.possible_social_distancing === '0'}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"mt-4"}>
                                        <div className="form-group" data-hasError={!!error.closed_contact}>
                                            <label>
                                                <b>Have you had close contact with someone with a confirmed case of COVID-19?</b>
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row" onChange={(e) => handleRadioChange(e)}>
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="closed_contact" value="1" className={'mr-2'}
                                                        checked={assessmentDetail.closed_contact === '1'}
                                                    />
                                                    Yes
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="closed_contact" value="0" className={'mr-2'}
                                                        checked={assessmentDetail.closed_contact === '0'}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"mt-4"}>
                                        <div className="form-group" data-hasError={!!error.risk_resident}>
                                            <label>
                                                <b>Are you a resident in a special setting where the risk of COVID-19 transmission may be high?</b>
                                                    (This may include long-term care, correctional and detention facilities; homeless shelters; assisted-living facilities and group homes.)
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row" onChange={(e) => handleRadioChange(e)}>
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="risk_resident" value="1" className={'mr-2'}
                                                        checked={assessmentDetail.risk_resident === '1'}
                                                    />
                                                    Yes
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="risk_resident" value="0" className={'mr-2'}
                                                        checked={assessmentDetail.risk_resident === '0'}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"section"}>
                                <h5>Workplace risks</h5>
                                <div className={"section-content col-md-12"}>
                                    <div>
                                        <div className="form-group" data-hasError={!!error.work_in_healthcare}>
                                            <label>
                                                <b>Do you work in health care?</b>
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row" onChange={(e) => handleRadioChange(e)}>
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="work_in_healthcare" value="1" className={'mr-2'}
                                                        checked={assessmentDetail.work_in_healthcare === '1'}
                                                    />
                                                    Yes
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="work_in_healthcare" value="0" className={'mr-2'}
                                                        checked={assessmentDetail.work_in_healthcare === '0'}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"mt-4"}>
                                        <div className="form-group" data-hasError={!!error.risk_workplace}>
                                            <label>
                                                <b>Do you work in a special setting where the risk of COVID-19 transmission may be high?</b>
                                                    (This may include long-term care, correctional and detention facilities; homeless shelters; assisted-living facilities and group homes.)
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row"  onChange={(e) => handleRadioChange(e)}>
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="risk_workplace" value="1" className={'mr-2'}
                                                        checked={assessmentDetail.risk_workplace === '1'}
                                                    />
                                                    Yes
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="risk_workplace" value="0" className={'mr-2'}
                                                        checked={assessmentDetail.risk_workplace === '0'}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"section"}>
                                <h5>Medical history</h5>
                                <div className={"section-content col-md-12"}>
                                    <div>
                                        <div className="form-group" data-hasError={!!error.asked_for_test}>
                                            <label>
                                                <b>Have you been asked or referred to get tested by a health care provider?</b>
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row" onChange={(e) => handleRadioChange(e)}>
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="asked_for_test" value="1" className={'mr-2'}
                                                        checked={assessmentDetail.asked_for_test === '1'}
                                                    />
                                                    Yes
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="asked_for_test" value="0" className={'mr-2'}
                                                        checked={assessmentDetail.asked_for_test === '0'}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"mt-4"}>
                                        <div className="form-group" data-hasError={!!error.symptoms_experienced}>
                                            <label>
                                                <b>Have you experienced any symptoms in the last 14 days?</b>
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row"  onChange={(e) => handleRadioChange(e)}>
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="symptoms_experienced" value="1" className={'mr-2'}
                                                        checked={assessmentDetail.symptoms_experienced === '1'}
                                                    />
                                                    Yes
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="symptoms_experienced" value="0" className={'mr-2'}
                                                        checked={assessmentDetail.symptoms_experienced === '0'}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        assessmentDetail.symptoms_experienced === '1' &&
                                        <div className={"mt-4"} data-hasError={error.no_symptoms_selected}>
                                            <label>
                                                <b>Which symptoms have you experienced?</b> (Select any that apply)
                                            </label>
                                            <div className="form-group checklist">
                                                <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                    <label className="radio-inline mr-3 w-50">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_chills"
                                                            checked={assessmentDetail.symptoms.is_chills}
                                                            onChange={(e) => handleCbChange(e)}
                                                        />
                                                        <span>Chills</span>
                                                    </label>
                                                    <label className="radio-inline mr-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_headache"
                                                            checked={assessmentDetail.symptoms.is_headache}
                                                            onChange={(e) => handleCbChange(e)}
                                                        />
                                                        <span>Headache</span>
                                                    </label>
                                                </div>
                                                <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                    <label className="radio-inline mr-3 w-50">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_congestion"
                                                            checked={assessmentDetail.symptoms.is_congestion}
                                                            onChange={(e) => handleCbChange(e)}
                                                        />
                                                        <span>Congestion or runny nose</span>
                                                    </label>
                                                    <label className="radio-inline mr-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_muscle_ache"
                                                            checked={assessmentDetail.symptoms.is_muscle_ache}
                                                            onChange={(e) => handleCbChange(e)}
                                                        />
                                                        <span>Muscle or body aches</span>
                                                    </label>
                                                </div>
                                                <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                    <label className="radio-inline mr-3 w-50">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_cough"
                                                            checked={assessmentDetail.symptoms.is_cough}
                                                            onChange={(e) => handleCbChange(e)}
                                                        />
                                                        <span>Cough</span>
                                                    </label>
                                                    <label className="radio-inline mr-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_nausea"
                                                            checked={assessmentDetail.symptoms.is_nausea}
                                                            onChange={(e) => handleCbChange(e)}
                                                        />
                                                        <span>Nausea or vomiting</span>
                                                    </label>
                                                </div>
                                                <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                    <label className="radio-inline mr-3 w-50">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_diarrhea"
                                                            checked={assessmentDetail.symptoms.is_diarrhea}
                                                            onChange={(e) => handleCbChange(e)}
                                                        />
                                                        <span>Diarrhea</span>
                                                    </label>
                                                    <label className="radio-inline mr-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_loss_of_taste"
                                                            checked={assessmentDetail.symptoms.is_loss_of_taste}
                                                            onChange={(e) => handleCbChange(e)}
                                                        />
                                                        <span>New loss of taste or smell</span>
                                                    </label>
                                                </div>
                                                <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                    <label className="radio-inline mr-3 w-50">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_fatigue"
                                                            checked={assessmentDetail.symptoms.is_fatigue}
                                                            onChange={(e) => handleCbChange(e)}
                                                        />
                                                        <span>Fatigue</span>
                                                    </label>
                                                    <label className="radio-inline mr-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_difficult_breathing"
                                                            checked={assessmentDetail.symptoms.is_difficult_breathing}
                                                            onChange={(e) => handleCbChange(e)}
                                                        />
                                                        <span>Shortness of breath or difficulty breathing</span>
                                                    </label>
                                                </div>
                                                <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                    <label className="radio-inline mr-3 w-50">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_fever"
                                                            checked={assessmentDetail.symptoms.is_fever}
                                                            onChange={(e) => handleCbChange(e)}
                                                        />
                                                        <span>Fever</span>
                                                    </label>
                                                    <label className="radio-inline mr-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_sore_throat"
                                                            checked={assessmentDetail.symptoms.is_sore_throat}
                                                            onChange={(e) => handleCbChange(e)}
                                                        />
                                                        <span>Sore throat</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div data-hasError={error.symptoms_date_selected}>
                                                Approximately what date did your symptoms begin?
                                                <input type="date" name="symptom_date"
                                                       value={assessmentDetail.symptoms.symptom_date}
                                                       onChange={(e) => handleSymptomDateChange(e)} />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="col-md-12 p-0 pl-4 d-flex flex-row align-items-center">
                                <label className="radio-inline mr-3" data-hasError={!!error.is_acknowledge}>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="is_acknowledge"
                                        onChange={(e) => handleAcknowledgeChange(e)}
                                    />
                                    <b className={'ml-3 pt-1 d-block'}>I acknowledge that I have answered these questions truthfully to the best of my knowledge.</b>
                                </label>
                            </div>

                            <div className="form-group mt-4">
                                <button type="submit" className="btn red-btn btn-md"
                                        onClick={onSaveAssessment}
                                > Submit
                                    <span className={onSavingAssessment ? "spinner-border" : ""}></span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CovidAssessment;
