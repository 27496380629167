import React, { useEffect, useState } from "react";
import { getSubscriptionByUserId, deleteSubscription } from '../../Redux/Actions/productAction'
import "../Subscription/subscription.scss";
import pills from "../../Images/pills.svg";
import { useSelector, useDispatch } from "react-redux";
import { THREE_MONTHS_SUB, SIX_MONTHS_SUB, TWELVE_MONTHS_SUB } from '../../constant'
import { NotificationManager } from "react-notifications";

const Subscription = (props) => {
  const [cancelSubscription, setCancelSubscription] = useState(false)
  const [subscriptionId, setSubsId] = useState(null)
  const closeModalCancelSubscription = () => setCancelSubscription(false)
  const [subscriptionType, setSubscriptionType] = useState(THREE_MONTHS_SUB)
  const [totalPercent, setTotalPercent] = useState(25)
  const userId = JSON.parse(localStorage.getItem('userData')).id
  const subscriptionList = useSelector((state) => state.productReducer.subscriptionList);
  const dispatch = useDispatch();

  const authData = useSelector((state) => state.authReducer.authData);

  useEffect(() => {
    (async () => {
      if (subscriptionType === THREE_MONTHS_SUB) {
        setTotalPercent(25)
      }
      if (subscriptionType === SIX_MONTHS_SUB) {
        setTotalPercent(20)
      }
      if (subscriptionType === TWELVE_MONTHS_SUB) {
        setTotalPercent(15)
      }
      await dispatch(getSubscriptionByUserId(userId, subscriptionType))

    })()
  }, [subscriptionType])

  useEffect(() => {
    if(authData.userType !== 'Normal User') {
      NotificationManager.error('You do not have permission to access this page');
      props.history.push('/');
      return;
    }
  }, [authData.isOrganisationUser]);


  const cancelSubscriptionById = async (subscriptionId) => {
    console.log("subscriptionId", subscriptionId)
    await dispatch(deleteSubscription(subscriptionId))
    setCancelSubscription();
    await dispatch(getSubscriptionByUserId(userId, subscriptionType))
  }
  // const renderModal = (subscriptionId) => {
  //   return (
  //     <Modal
  //       className="modal admin-modal edit-user-modal"
  //       id="myModal"
  //       show={cancelSubscription}
  //       onHide={closeModalCancelSubscription}
  //       centered
  //     >
  //       <Modal.Header closeButton>
  //         <Modal.Title style={{ color: "#250C6D" }}>
  //           Are you sure Want to cancel this Subscription?
  //                 </Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body style={{ color: "#9285B6" }}>
  //         <div
  //           className="button-modal-div"
  //         >
  //           <Button onClick={() => cancelSubscriptionById(subscriptionId)}>Yes</Button>
  //           <Button onClick={closeModalCancelSubscription}>No</Button>
  //         </div>
  //       </Modal.Body>
  //     </Modal>
  //   )
  // }
  return (
    <div className="subscription-main">
      <div className="container">
        <h2>Subscriptions</h2>
        <div className="subscription-desc">
          <div className="package-main">
            <h4>Select Subscription Package</h4>
            <div className="d-none d-sm-none d-md-block d-lg-block">
              <div className="row">
                <div className="col-md-4" onClick={() => setSubscriptionType(THREE_MONTHS_SUB)}>
                  <div className={`package-desc ${subscriptionType === THREE_MONTHS_SUB ? 'active' : null}`}>
                    <h4>3 Months</h4>
                    <p>Save 25%</p>
                  </div>
                </div>

                <div className="col-md-4" onClick={() => setSubscriptionType(SIX_MONTHS_SUB)}>
                  <div className={`package-desc ${subscriptionType === SIX_MONTHS_SUB ? 'active' : null}`} >
                    <h4>6 Months</h4>
                    <p>Save 20%</p>
                  </div>
                </div>

                <div className="col-md-4" onClick={() => setSubscriptionType(TWELVE_MONTHS_SUB)}>
                  <div className={`package-desc ${subscriptionType === TWELVE_MONTHS_SUB ? 'active' : null}`}>
                    <h4>12 Months</h4>
                    <p>Save 15%</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-block d-sm-none">
              <div className="row mobile-view">
                <div className={"div-offer"} onClick={() => setSubscriptionType(THREE_MONTHS_SUB)}>
                  <div className={`package-desc ${subscriptionType === THREE_MONTHS_SUB ? 'active' : null}`}>
                    <h4>3 Months</h4>
                    <p>Save 25%</p>
                  </div>
                </div>

                <div className={"div-offer"} onClick={() => setSubscriptionType(SIX_MONTHS_SUB)}>
                  <div className={`package-desc ${subscriptionType === SIX_MONTHS_SUB ? 'active' : null}`} >
                    <h4>6 Months</h4>
                    <p>Save 20%</p>
                  </div>
                </div>

                <div className={"div-offer"} onClick={() => setSubscriptionType(TWELVE_MONTHS_SUB)}>
                  <div className={`package-desc ${subscriptionType === TWELVE_MONTHS_SUB ? 'active' : null}`}>
                    <h4>12 Months</h4>
                    <p>Save 15%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="previous-order">
            <div className="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Health Kits</th>
                    <th>Price</th>
                    <th>Total({totalPercent}% off)</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {subscriptionList?.length > 0 ? subscriptionList?.map((data, index) => {
                    console.log("LIST", data)
                    return (
                      <tr> <td>
                        <div className="check-home">
                          <div className="pills-desc">
                            <div className="pills-img" >
                              <img src={pills} />
                            </div>
                            <div className="pills-home">
                              <p>{data.name}</p>
                              <div className="pills-select">
                                {/* {data.selectedQuantity} */}
                                <span>Quantity: {data?.orderedQuantity}</span>
                              </div>
                              {data.status ? <div>
                                <span className="cancel-sub" onClick={() => { setCancelSubscription(true); setSubsId(data.subscription_id); }}>Cancel subscription</span>
                              </div> : null
                              }
                            </div>
                          </div>
                        </div>
                      </td>
                        {/* {renderModal(data.subscription_id)} */}
                        <td>${data.price * data?.orderedQuantity}</td>
                        <td>${data.pricePerUnit * data?.orderedQuantity}</td>
                        <td>{data.status ? <span className={"active"}>Active</span> : <span className={"cancelled"}>Cancelled</span>}</td>
                      </tr>
                    )
                  }) : <p>No Subscription</p>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


      {cancelSubscription? (
          <div className="modal" id="removeItem" style={{ display: "block" }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">
                    {" "}
                    Are you sure, you want to cancel this Subscription?
                  </h4>
                  <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      onClick={closeModalCancelSubscription}
                  >
                  <span>
                    <i class="far fa-times-circle"></i>
                  </span>
                  </button>
                </div>

                <div
                    className="modal-body"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                >
                  <button
                      className="red-btn"
                      onClick={() => cancelSubscriptionById(subscriptionId)}
                  >
                    Yes
                  </button>
                  <button
                      className="red-btn"
                      onClick={closeModalCancelSubscription}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
      ) : null}

    </div>
  );
}

export default React.memo(Subscription);
