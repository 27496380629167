import React, { useEffect, useMemo, useState} from "react";
import "../RegisterKit/register-kit.scss";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import {
    saveTesterDetail,
    registerKit,
    resetKitRegistration,
} from '../../Redux/Actions/orderAction'
import PhoneInput from "react-phone-number-input";
import {useHistory} from "react-router";
import {userActions} from "../../Redux/Actions";

const TesterDetail = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [testerDetail, setTesterDetail] = useState({
        kitId: '',
        firstName: '',
        lastName: '',
        dob: '',
        sex: '',
        addressLine1: '',
        suit: '',
        country: '',
        state: '',
        city: '',
        zip: '',
        phoneNumber: '',
    });
    const [step, setStep] = useState('testerDetail');
    const [errorMessage, setErrorMessage] = useState('');
    const [error, setError] = useState('');
    const { getCountriesList, getStateListByCountries, getCitiesByState } = useSelector((state) => state.userReducer);
    const kitRegistrationState = useSelector((state) => state.kitReducer);

    useEffect(() => {
        dispatch(userActions.getCountries())
    }, []);

    useMemo(() => {
        if(kitRegistrationState) {
            if(!kitRegistrationState.kitId || !kitRegistrationState.emailId) {
                return history.push(`/register-kit`);
            }
            setTesterDetail({
                firstName: kitRegistrationState.firstName,
                lastName: kitRegistrationState.lastName,
                dob: kitRegistrationState.dob,
                sex: kitRegistrationState.sex,
                addressLine1: kitRegistrationState.addressLine1,
                suit: kitRegistrationState.suit,
                country: kitRegistrationState.country,
                state: kitRegistrationState.state,
                city: kitRegistrationState.city,
                zip: kitRegistrationState.zip,
                phoneNumber: kitRegistrationState.phoneNumber,
                kitId: kitRegistrationState.kitId,
            });
        }
    }, [kitRegistrationState]);

    function handleChange(event) {
        const { name, value } = event.target;
        if(name === 'zip') {
            const regEx = /^\b\d{0,5}\b$/;
            if (value !== '' && !regEx.test(value)) {
                return;
            }
        }

        setTesterDetail((prevState) => ({ ...prevState, [name]: value }));
        setError((prevState) => ({ ...prevState, [name]: null }));
    }

    function handleCountryChange(event) {
        const { value } = event.target;
        let selectedCountry = getCountriesList.find((country) => country.id === (parseInt(value)));
        dispatch(userActions.getStates(selectedCountry.id));
        setTesterDetail((prevState) => ({ ...prevState, 'country': selectedCountry.name }));
        setError((prevState) => ({ ...prevState, 'country': null }));
    }

    function handleStateChange(event) {
        const { value } = event.target;
        let selectedState = getStateListByCountries.find((state) => state.state_name === value);
        dispatch(userActions.getCities(parseInt(selectedState.id)));
        setTesterDetail((prevState) => ({ ...prevState, 'state': value }));
        setError((prevState) => ({ ...prevState, 'state': null }));
    }

    const validateTesterDetail = () => {
        let isFormValid = true;
        const error = {
            firstName: '',
            lastName: '',
            dob: '',
            sex: '',
            addressLine1: '',
            suit: '',
            country: '',
            state: '',
            city: '',
            zip: '',
            phoneNumber: '',
        };

        if (testerDetail.firstName.trim().length === 0) {
            isFormValid = false;
            error.firstName = "Field is required!";
        }

        if (testerDetail.lastName.trim().length === 0) {
            isFormValid = false;
            error.lastName = "Field is required!";
        }

        if (!testerDetail.dob) {
            isFormValid = false;
            error.dob= "Field is required!";
        }

        if (
            Math.floor(moment(new Date()).diff(moment(testerDetail.dob).format(),'years',true)) < 18
        ) {
            isFormValid = false;
            error.dob= "Age must be above 18";
        }

        if (!testerDetail.sex) {
            isFormValid = false;
            error.sex= "Field is required!";
        }

        if (testerDetail.addressLine1.trim().length === 0) {
            isFormValid = false;
            error.addressLine1 = "Field is required!";
        }

        if (testerDetail.country.trim().length === 0) {
            isFormValid = false;
            error.country = "Field is required!";
        }

        if (testerDetail.state.trim().length === 0) {
            isFormValid = false;
            error.state = "Field is required!";
        }

        if (testerDetail.city.trim().length === 0) {
            isFormValid = false;
            error.city = "Field is required!";
        }

        if (testerDetail.zip.trim().length === 0) {
            isFormValid = false;
            error.zip = "Field is required!";
        }

        if (!testerDetail.phoneNumber || testerDetail.phoneNumber.trim().length === 0) {
            isFormValid = false;
            error.phoneNumber = "Field is required!";
        }
        return {
            isFormValid,
            error
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationCheck = validateTesterDetail();
        if (!validationCheck.isFormValid) {
            setError(validationCheck.error);
            return;
        }

        if(step === 'testerDetail') {
            dispatch(saveTesterDetail(testerDetail));
            window.scrollTo({top: 0, behavior: 'smooth'});
            setStep('review');
        }
        if(step === 'review') {
            const registerResponse = await dispatch(registerKit(testerDetail));
            if(!registerResponse.isSuccess) {
                window.scrollTo({top: 0, behavior: 'smooth'});
                setErrorMessage(registerResponse.errorMessage);
                return;
            }
            dispatch(resetKitRegistration());
            history.push(`/kit-confirmation`);
        }

    };

    const onPrevious = () => {
        if(step === 'testerDetail') {
            history.push(`/register-kit`);
        } else {
            window.scrollTo({top: 0, behavior: 'smooth'});
            setStep('testerDetail');
        }
    };

    function handlePhoneNumberChange(value) {
        setTesterDetail((prevState) => ({ ...prevState, 'phoneNumber' : value }));
        setError((prevState) => ({ ...prevState, 'phoneNumber' : null }));
    }

    const renderPhoneInput = () => {
        const phnInput = <PhoneInput
            defaultCountry="US"
            countries={["US"]}
            addInternationalOption={false}
            class="form-control"
            placeholder="(xxx) xxx-xxxx"
            limitMaxLength={true}
            value={testerDetail.phoneNumber}
            disabled={step === 'review'}
            onChange={handlePhoneNumberChange}/>;

        return phnInput;
    };

    return (
        <div className="container register-kit-main width-50">
            <div className={'text-center'}>
                <h2>Register Your Accugenedx Kit</h2>

                <div className="signin-process">
                    <ul>
                        <li>
                            <div className="check-round">
                                <i className={"fas fa-check"} />
                            </div>
                            <p>Kit Information</p>
                        </li>
                        <li>
                            <div className={`check-round ${step === 'testerDetail' && 'active'}`}>
                                {step === 'testerDetail' ? <span className={'step'}>2</span>: <i className={"fas fa-check"} />}
                            </div>
                            <p>Tester Info</p>
                        </li>
                        <li>
                            <div className={`check-round ${step === 'review' && 'active'}`}>
                                {step === 'review' && <span className={'step'}>3</span>}
                            </div>
                            <p>Review</p>
                        </li>
                        <li>
                            <div className="check-round">
                                <span className={'step'}>4</span>
                            </div>
                            <p>Done</p>
                        </li>
                    </ul>
                </div>
            </div>


            <div className="container mt-4">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-10 col-sm-12 text-center">

                        {
                            errorMessage &&
                            <div className="alert alert-danger alert-dismissible">{errorMessage}</div>
                        }
                        {
                            step === 'testerDetail' ?
                                <label className={'register-label'}>
                                    Please provide us with the tester information in order to authorize and validate your test
                                    for further processing of your sample to provide you with the accurate results. Your information
                                    will be confidential and secured with us
                                </label> :
                                <label className={'register-label'}>
                                    Please review your information before your final submit
                                </label>
                        }
                    </div>
                </div>
                <div className="row d-flex justify-content-center mt-2">
                    <div className="col-md-10 col-sm-12">
                        <form onSubmit={(e) => handleSubmit(e)} className={'tester-detail-form'}>
                            {
                                step === 'review' &&
                                (
                                    <>
                                        <div className="col-md-12 d-flex flex-row p-0">
                                            <div className="p-0 col-md-6 d-flex flex-column">
                                                <h4 className={'section-heading mb-2'}>Kit Information</h4>

                                                <div className="form-group mb-1">
                                                    <label className="m-0 d-block font-weight-bold">Test Name</label>
                                                    <label>{kitRegistrationState.testName}</label>
                                                </div>
                                                <div className="form-group mb-0">
                                                    <label className="m-0 d-block font-weight-bold">Unique Kit ID</label>
                                                    <label>{testerDetail.kitId}</label>
                                                </div>
                                            </div>
                                            {
                                                kitRegistrationState.testImage &&
                                                <div className="col-md-6 p-0 d-none d-md-block">
                                                    <div className="product-img">
                                                        <img alt="test_img" src={kitRegistrationState.testImage} />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </>
                                )
                            }

                            <h4 className={'section-heading mb-2 mt-3'}>Personal Details</h4>

                            <div className="col-md-12 d-flex p-0">
                               <div class="col-md-6 pl-0">
                                   <div className="form-group">
                                       <label class="m-0">First Name*</label>
                                       <input
                                           type="text"
                                           className="form-control"
                                           name="firstName"
                                           value={testerDetail.firstName}
                                           disabled={step === 'review'}
                                           onChange={(e) => handleChange(e)}
                                       />
                                       {error.firstName && (
                                           <p className="error-validation">
                                               {error.firstName}
                                           </p>
                                       )}
                                   </div>
                               </div>
                               <div class="col-md-6 pl-0">
                                   <div className="form-group">
                                       <label class="m-0">Last Name*</label>
                                       <input
                                           type="text"
                                           className="form-control"
                                           name="lastName"
                                           value={testerDetail.lastName}
                                           disabled={step === 'review'}
                                           onChange={(e) => handleChange(e)}
                                       />
                                       {error.lastName && (
                                           <p className="error-validation">
                                               {error.lastName}
                                           </p>
                                       )}
                                   </div>
                               </div>
                            </div>
                            <div className="col-md-12 d-flex p-0">
                               <div class="col-md-6 pl-0">
                                   <div className="form-group">
                                       <label class="m-0">Date Of Birth*</label>
                                       <input
                                           type="date"
                                           className="form-control"
                                           name="dob"
                                           value={testerDetail.dob}
                                           disabled={step === 'review'}
                                           onChange={(e) => handleChange(e)}
                                       />
                                       {error.dob && (
                                           <p className="error-validation">
                                               {error.dob}
                                           </p>
                                       )}
                                   </div>
                               </div>
                               <div class="col-md-6 pl-0">
                                   <div className="form-group">
                                       <label>Sex*</label>
                                       <div className="col-md-12 p-0" onChange={(e) => handleChange(e)}>
                                           <label className="radio-inline mr-3" htmlFor="radios-0">
                                               <input
                                                   type="radio"
                                                   name="sex" id="radios-0" value="Male" className={'mr-2'}
                                                   checked={testerDetail.sex === 'Male'}
                                                   disabled={step === 'review'}
                                               />
                                               Male
                                           </label>
                                           <label className="radio-inline" htmlFor="radios-1">
                                               <input
                                                   type="radio"
                                                   name="sex" id="radios-1" value="Female" className={'mr-2'}
                                                   checked={testerDetail.sex === 'Female'}
                                                   disabled={step === 'review'}
                                               />
                                               Female
                                           </label>
                                       </div>
                                       {error.sex && (
                                           <p className="error-validation">
                                               {error.sex}
                                           </p>
                                       )}
                                   </div>
                               </div>
                            </div>

                            <h4 className={'section-heading mb-2 mt-3'}>Address</h4>

                            <div className="col-md-12 d-flex p-0">
                                <div className="col-md-12 pl-0">
                                    <div className="form-group">
                                        <label className="m-0">Address Line 1*</label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            name={'address'}
                                            name="addressLine1"
                                            value={testerDetail.addressLine1}
                                            disabled={step === 'review'}
                                            onChange={(e) => handleChange(e)}
                                        ></textarea>
                                        {error.addressLine1 && (
                                            <p className="error-validation">
                                                {error.addressLine1}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 d-flex p-0">
                                <div className="col-md-12 pl-0">
                                    <div className="form-group">
                                        <label className="m-0">Apartment, Suite, etc. (Optional)</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="suit"
                                            value={testerDetail.suit}
                                            disabled={step === 'review'}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        {error.suit && (
                                            <p className="error-validation">
                                                {error.suit}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 d-flex p-0">
                                <div className="col-md-6 pl-0">
                                    <div className="form-group">
                                        <label>Country*</label>
                                        <select
                                            className="form-control"
                                            name="country"
                                            value={testerDetail.country}
                                            disabled={step === 'review'}
                                            onChange={(e) => handleCountryChange(e)}
                                        >
                                        <option value="" selected disabled>
                                            Select Country
                                        </option>
                                        {
                                            getCountriesList?.map((country) =>
                                                <option key={country.id}
                                                        value={country.id}
                                                >
                                                    {country.name}
                                                </option>
                                        )}
                                        </select>
                                        {error.country && (
                                            <p className="error-validation">
                                                {error.country}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 d-flex p-0">
                                <div className="col-md-4 pl-0">
                                    <div className="form-group">
                                        <label className="m-0">State*</label>
                                        <select
                                            className="form-control"
                                            name="state"
                                            value={testerDetail.state}
                                            disabled={step === 'review'}
                                            onChange={(e) => handleStateChange(e)}
                                        >
                                        <option value="" selected disabled>
                                            Select State
                                        </option>
                                        {
                                            getStateListByCountries?.map((state) =>
                                                <option key={state.id}
                                                        value={state.state_name}>
                                                    {state.state_name}
                                                </option>
                                        )}
                                        </select>
                                        {error.state && (
                                            <p className="error-validation">
                                                {error.state}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4 pl-0">
                                    <div className="form-group" name="city">
                                        <label className="m-0">City*</label>
                                        <select
                                            className="form-control"
                                            name="city"
                                            disabled={step === 'review'}
                                            onChange={(e) => handleChange(e)}
                                            value={testerDetail.city}
                                        >
                                            <option
                                                value=""
                                                selected
                                                disabled>
                                                Select City
                                            </option>
                                            {
                                                getCitiesByState?.map((city, index) =>
                                                    <option key={index}
                                                            value={city.city}>
                                                        {city.city}
                                                    </option>
                                                )}
                                        </select>
                                        {error.city && (
                                            <p className="error-validation">
                                                {error.city}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4 pl-0">
                                    <div className="form-group">
                                        <label className="m-0">Zip Code*</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name='zip'
                                            value={testerDetail.zip}
                                            disabled={step === 'review'}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        {error.zip && (
                                            <p className="error-validation">
                                                {error.zip}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <h4 className={'section-heading mb-2 mt-3'}>Contact Information</h4>

                            <div className="col-md-12 d-flex p-0">
                                <div className="col-md-12 pl-0">
                                    {
                                        step === 'review' &&
                                        <div className="form-group">
                                            <label className="m-0 d-block font-weight-bold">Email</label>
                                            <label className={'m-0'}>{kitRegistrationState.emailId}</label>
                                        </div>
                                    }

                                    <div className="form-group">
                                        <label className="m-0">Phone Number*</label>
                                        {renderPhoneInput()}
                                        {error.phoneNumber && (
                                            <p className="error-validation">
                                                {error.phoneNumber}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group pt-3 col-sm-12 d-flex justify-content-between pl-0">
                                <div>
                                    <button className="red-btn-border w-100" type="button" onClick={onPrevious}>
                                        <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
                                    </button>
                                </div>
                                <div>
                                    <button className="red-btn w-100" type="submit" onClick={(e) => handleSubmit(e)}>

                                        <span style={{color: '#fff'}}
                                              className={kitRegistrationState.isSaving ? "spinner-border" : ""}
                                        ></span>
                                        &nbsp;
                                        Next <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default TesterDetail;
