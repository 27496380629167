import React, {useMemo, useState} from "react";
import "../RegisterKit/register-kit.scss";
import { useDispatch, useSelector } from "react-redux";
import { checkKitId } from '../../Redux/Actions/orderAction'
import {REGISTER_KIT} from "../../constant";
import {useHistory} from "react-router";

const RegisterKit = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [kitId, setKitId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const handleChange = (e) => {
    const { value } = e.target;
    setKitId(value.trim());
  };
  const commonData = useSelector((state) => state.commonReducer);
  const kitRegistrationState = useSelector((state) => state.kitReducer);

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (kitId.trim().length > 0) {
      const res = await dispatch(checkKitId(kitId));
      if (res && !res.isFound) {
        setErrorMessage(res.message)
        setTimeout(() => {
          setErrorMessage('')
        }, 4000)
      } else {
        history.push(`/tester-info`, {
          prevPage: REGISTER_KIT
        });
      }
    } else {
      setErrorMessage("Kit ID is missing");
    }
  };

  useMemo(() => {
    if(kitRegistrationState.kitId) {
      setKitId(kitRegistrationState.kitId);
    }
  }, [kitRegistrationState]);

  return (
      <div className="text-center container register-kit-main width-50">
        <h2>Register Your Accugenedx Kit</h2>
        <div className="signin-process">
          <ul>
            <li>
              <div className="check-round active">
                <span className={'step'}>1</span>
              </div>
              <p>Kit Information</p>
            </li>
            <li>
              <div className="check-round">
                <span className={'step'}>2</span>
              </div>
              <p>Tester Info</p>
            </li>
            <li>
              <div className="check-round">
                <span className={'step'}>3</span>
              </div>
              <p>Review</p>
            </li>
            <li>
              <div className="check-round">
                <span className={'step'}>4</span>
              </div>
              <p>Done</p>
            </li>
          </ul>
        </div>

        <div className="container mt-4">
          <div className="row d-flex justify-content-center">
            <div className="col-md-6 col-sm-12">

              {
                errorMessage &&
                <div className="alert alert-danger alert-dismissible">{errorMessage}</div>
              }

              <form onSubmit={(e) => handleSubmit(e)}>
                <label className={'register-label'}>
                  Please enter the Unique Kit ID located on the registration slip inside of your kit.
                </label>

                <div className="form-group kit-id">
                  <input
                      type="text"
                      name="registerKitId"
                      className="form-control"
                      placeholder="Unique Kit ID"
                      value={kitId}
                      onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="form-group pt-2">
                  <div className="form-group pt-3 d-flex justify-content-end pl-0">
                    <button className="red-btn w-50" type="submit" onClick={(e) => handleSubmit(e)}>
                       <span style={{color: '#fff'}}
                             className={commonData.loading === true ? "spinner-border" : ""}
                       ></span> &nbsp;
                      Next <i className="fa fa-arrow-right" aria-hidden="true"></i>

                    </button>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  );

};

export default RegisterKit;
