import React from 'react'
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import rootReducer from "./Redux/Reducers/index";

import './index.css';
import App from './App';
import "bootstrap/dist/css/bootstrap.css";
import * as serviceWorker from './serviceWorker';
import ScrollToTop from './Utils/scrollToTop';
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
const middleware = [thunk, logger];
const composeEnacher = composeWithDevTools({trace: true, traceLimit: 25})
const store = createStore(rootReducer, composeEnacher(applyMiddleware(...middleware)));


ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter> 
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
