import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./OrderConfirmation.scss";
import { cartAction, orderAction } from "../../Redux/Actions/index";
import { PAYMENT_PAGE } from '../../constant'
function OrderConfirmation(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (props.history.location?.state?.prevPage === PAYMENT_PAGE) {
        dispatch(orderAction.orderConfirmationDetails(props.match.params.orderId, 'confirmed'))
      } else {
        props.history.push('/payments')
      }
    })()
  }, [])
  const orderDetails = useSelector((state) => state.orderReducer.orderConfirmationDetails);
  const id = JSON.parse(localStorage.getItem("userData"));

  return (
    <div className="container text-center my-5">
      <h2>Order Confirmation</h2>
        <div className="signin-process">
          <ul>
            <li>
              <div className="check-round">
                 <i className={"fas fa-check"} />
              </div>
              <p>Sign In</p>
            </li>
            <li>
              <div className="check-round">
                 <i className={"fas fa-check"} />
              </div>
              <p>Shipping</p>
            </li>
            <li>
              <div className="check-round">
                 <i className={"fas fa-check"} />
              </div>
              <p>Billing</p>
            </li>
            <li>
              <div className="check-round active"></div>
              <p className="active">Confirmation</p>
            </li>
          </ul>
        </div>
      <h2 style={{marginTop:30}}>Thanks for your order</h2>

      <div>
        <p> Your order has been placed successfully. Order Confirmation is sent to <b> {id.email}</b></p>
        <p> Order Number <b>{orderDetails[0]?.orderNumber}</b></p>
      </div>
      <div>
        {orderDetails.map((data, index) => {
          return (
            <div className="d-flex justify-content-center mt-5">
              <div className="confirm-img-resize align-center">
                <img src={ data.image_name} />
              </div>
              <div className="text-left">
                <p> Product Name: {data.name}</p>
                <p> Quantity: {data.orderedQuantity}</p>
                {data?.is_subscription === 1 ? <p style={{color: '#800020'}}>Subscribed</p> : null}
              </div>
            </div>
          );
        })}

      </div>
    </div>
  );
}

export default OrderConfirmation;
