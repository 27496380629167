import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { productActions, cartAction } from "../../Redux/Actions/index";
import "../Home/home.scss";
import { useDispatch, useSelector } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Test from "../../Images/test.png";
import testImg from "../../Images/lab.jpg";
import bloodTest from "../../Images/blood-test.png";
import Tube from "../../Images/sample-tube.png";
import Browser from "../../Images/web-browser.png";
import $ from "jquery";

const Home = (props) => {
  let textLimit = 200;
  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productReducer.productLists);
  const commonData = useSelector((state) => state.commonReducer);

  const [newItem, setNewItem] = useState({});
  const [quantity, setQuantity] = useState({});
  const [isSubscription, setSubscription] = useState(false);
  const { checkQuantitySuccess } = useSelector((state) => state.productReducer);

  useEffect(() => {
    (async () => {
      await dispatch(productActions.getCategory());
      if (props?.location?.state?.detail === false) {
        const state = { ...props.history.location };
        delete state.detail;
        props.history.replace({ ...props.history.location, state });
      } else {
        await dispatch(productActions.getProductByCategory("all-tasks"));
      }
    })();
  }, []);
  const onclickAction = async (item) => {
    props.history.push(`/categories/${item.slug}`);
    await dispatch(productActions.getProductDetail(item.slug));
    dispatch({ type: "CLEAR_SEARCH_LIST" });
  };

  const addToCart = async (item, quantity, isSubscription) => {
    setNewItem(Object.assign({}, item));
    setQuantity(quantity);
    setSubscription(isSubscription);
    await dispatch(productActions.checkValidQuantity(item.id, quantity));
  };

  useEffect(() => {
    if(checkQuantitySuccess) {
      dispatch(cartAction.addToCart(newItem, quantity, isSubscription));
      $('#cart-inner').modal('show');
      dispatch(productActions.resetCheckQuantity());
    }
  }, [checkQuantitySuccess]);

  return (
    <div className="home-all-main">
      <section className="home-main">
        <div className="home-bg"></div>
        <div className="container">
          <div className="slider-well">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              items={1}
              nav
              dots={false}
            >
              <div class="item">
                <div className="item-main">
                  <div className="slider-desc">
                    <h2>Take control of YOUR health</h2>
                    <p>
                      ● Choose from our 10 Tests kits <br></br>● Single or
                      subscriptions available<br></br>● Results in 2-5 days
                      <br></br>● Secure and convenient Results<br></br>
                    </p>
                    <div className="learn-btn">
                      <Link to="/categories" style={{ textDecoration: "none" }}>
                        View Tests
                        <span>
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </Link>
                    </div>
                  </div>
                  {/* <div className="slider-label">
                    <p>General Wellness</p>
                  </div> */}
                </div>
              </div>
              <div class="item">
                <div className="item-main">
                  <div className="slider-desc">
                    <h2>Take control of YOUR health</h2>
                    <p>
                      ● Choose from our 10 Tests kits <br></br>● Single or
                      subscriptions available<br></br>● Results in 2-5 days
                      <br></br>● Secure and convenient Results<br></br>
                    </p>
                    <div className="learn-btn">
                      <Link to="/categories" style={{ textDecoration: "none" }}>
                        View Tests
                        <span>
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </Link>
                    </div>
                  </div>
                  {/* <div className="slider-label">
                    <p>General Wellness</p>
                  </div> */}
                </div>
              </div>
              <div class="item">
                <div className="item-main">
                  <div className="slider-desc">
                    <h2>Take control of YOUR health</h2>
                    <p>
                      ● Choose from our 10 Tests kits <br></br>● Single or
                      subscriptions available<br></br>● Results in 2-5 days
                      <br></br>● Secure and convenient Results<br></br>
                    </p>
                    <div className="learn-btn">
                      <Link to="/categories" style={{ textDecoration: "none" }}>
                        View Tests
                        <span>
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </Link>
                    </div>
                  </div>
                  {/* <div className="slider-label">
                    <p>General Wellness</p>
                  </div> */}
                </div>
              </div>
              <div class="item">
                <div className="item-main">
                  <div className="slider-desc">
                    <h2>Take control of YOUR health</h2>
                    <p>
                      ● Choose from our 10 Tests kits <br></br>● Single or
                      subscriptions available<br></br>● Results in 2-5 days
                      <br></br>● Secure and convenient Results<br></br>
                    </p>
                    <div className="learn-btn">
                      <Link to="/categories" style={{ textDecoration: "none" }}>
                        View Tests
                        <span>
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </Link>
                    </div>
                  </div>
                  {/* <div className="slider-label">
                    <p>General Wellness</p>
                  </div> */}
                </div>
              </div>
            </OwlCarousel>
          </div>
          <div className="test-group">
            <div className="common-h2">
              <h2 className="heart-rate-h2">What's Included</h2>
              <h3 className="heart-rate-description">Simple steps that put you in control</h3>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="card-work">
                  <div className="card-icon-work">
                    <img src={Tube} />
                  </div>
                  <h4>Request your test kit</h4>
                  <p>
                    Ordering your lab test is more accessible than ever.
                    You can use our user-friendly website to order your lab test at the comfort of your home,
                    and the test kit will be delivered to your home, including pre-paid expedited shipping to send your sample to our lab.
                  </p>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="card-work">
                  <div className="card-icon-work">
                    <img src={bloodTest} />
                  </div>
                  <h4>Collect your sample</h4>
                  <p>
                    Easy to read and follow instructions to collect the sample in the comfort of your home.
                  </p>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="card-work">
                  <div className="card-icon-work">
                    <img src={Browser} />
                  </div>
                  <h4>Receive results in days</h4>
                  <p>
                    We will test your sample under our high complexity CAP and CLIA-certified lab.
                    Our medical laboratory director will review the test report and make it available
                    on our secured online portal that you can share with your doctor.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="included-test">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="demo-kit-desc">
                {/* <h4>Lorem Ipsum</h4> */}
                <h2>Why Accugenedx?</h2>
                <p>
                  ● In-house laboratory and testing facility<br></br>● CAP and
                  CLIA Certified<br></br>● Streamlined and easy-to-use test kits
                  <br></br>● Over 30 yeas of direct diagnostic testing
                  experience<br></br>
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="demo-kit-img d-flex justify-content-end">
                <img src={testImg} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="products-main">
        <div className="container">
          <div className="test-btn-h2">
            <h2>Popular Tests</h2>
            <button
              className="white-btn-border"
              onClick={() => {
                props.history.push("/categories");
              }}
            >
              View All
            </button>
          </div>
        </div>
      </section>

      <section className="products-upper">
        <div className="container">
          <div className="products-group">
            <div className="row">
              {commonData.loading === false ? (
                productList?.length > 0 ? (
                  productList?.map((data, index) => {
                    if (index <= 3) {
                      return (
                        <div className="col-md-4" key={index}>
                          <div className="products-card">
                            <div
                              className="price-tag"
                              onClick={() => addToCart(data, 1, isSubscription)}
                            >
                              <p>
                                <i class="fas fa-cart-plus"></i>
                              </p>
                            </div>
                            <div className="products-img">
                              <img src={data?.image_name} />
                            </div>
                            <div className="products-card-text">
                              <h4>{data.name}</h4>
                              <p>
                                {data?.description?.length > textLimit
                                  ? `${data.description.slice(
                                      0,
                                      textLimit
                                    )}....see more`
                                  : data.description.slice(0, textLimit)}
                              </p>
                              {/* <p>{data.description?.slice(0, this.textLimit)}</p> */}
                              <p className="price-test">${data.price}</p>
                              <div className="text-center button-fixed">
                                <button
                                  onClick={() => onclickAction(data)}
                                  className="red-btn-border"
                                >
                                  Learn More
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <p
                    style={{
                      fontWeight: 600,
                      textAlign: "center",
                      width: "100%",
                      margin: "40px",
                    }}
                  >
                    No Items Added..!!
                  </p>
                )
              ) : (
                <p
                  style={{
                    fontWeight: 500,
                    textAlign: "center",
                    width: "100%",
                    margin: "40px",
                  }}
                >
                  LOADING...
                </p>
              )}
              {/* <div className="col-md-4">
        <div className="products-card">
          <div className="price-tag">
            <p><i class="fas fa-cart-plus"></i></p>
          </div>
          <div className="products-img">
            <img src={Test} />
          </div>
          <div className="products-card-text">
            <h4>Food Sensitivity Test</h4>
            <p>Test your sensitivity to 96 commonly found foods.</p>
            <p className="price-test">$00.00</p>
            <div className="text-center">
            <button className="red-btn-border">Learn More</button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-4">
      <div className="products-card">
          <div className="price-tag">
            <p><i class="fas fa-cart-plus"></i></p>
          </div>
          <div className="products-img">
            <img src={Test} />
          </div>
          <div className="products-card-text">
            <h4>Food Sensitivity Test</h4>
            <p>Test your sensitivity to 96 commonly found foods.</p>
            <p className="price-test">$00.00</p>
            <div className="text-center">
            <button className="red-btn-border">Learn More</button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-4">
      <div className="products-card">
          <div className="price-tag">
            <p><i class="fas fa-cart-plus"></i></p>
          </div>
          <div className="products-img">
            <img src={Test} />
          </div>
          <div className="products-card-text">
            <h4>Food Sensitivity Test</h4>
            <p>Test your sensitivity to 96 commonly found foods.</p>
            <p className="price-test">$00.00</p>
            <div className="text-center">
            <button className="red-btn-border">Learn More</button>
            </div>
          </div>
        </div>
      </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
