import React, { Component } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import Header from "../Components/Header/header";
import Footer from "../Components/Footer/footer";
import Breadcrumbs from "../Components/Breadcrumbs/breadcrumbs";
import Home from "../Containers/Home/home";
import RegisterKit from "../Containers/RegisterKit/registerKit";
import Categories from "../Containers/Categories/categories";
import ProductDetail from "../Containers/ProductDetail/product-detail";
import Settings from "../Containers/Settings/settings";
import Orders from "../Containers/Orders/orders";
import OrderDetails from "../Containers/OrderDetails/OrderDetails";
import Signin from "../Containers/Signin/signin";
import Shipping from "../Containers/Shipping/shipping";
import Subscription from "../Containers/Subscription/subscription";
import Employees from "../Containers/Employees/employees";
import AddEmployee from "../Containers/Employees/addEmployee";
import EmployeeDetail from "../Containers/Employees/employeeDetail";
import EmployeeHistory from "../Containers/Employees/employeeHistory";
import Cart from "../Containers/Cart/cart";
import Reports from "../Containers/Reports/reports";
import Profile from "../Containers/Profile/profile";
import Verify from "../Containers/Verify/verify";
import PrivateRoute from "./privateRoutes";
import Contact from "../Containers/Contact/contact";
import Help from "../Containers/Help/help";
import Payments from "../Containers/Payments/payments";
import ForgotPassword from "../Containers/ForgotPassword/ForgotPassword";
import ResetPassword from "../Containers/ResetPassword/resetPassword";
import OrderConfirmation from "../Containers/OrderConfirmation/OrderConfirmation";
import TrackOrder from "../Containers/TrackOrder/TrackOrder";
import About_Us from "../Components/AboutUs/AboutUs";
import NotFound404 from "../Containers/PageNotFound/pageNotFound";
import {NotificationContainer} from 'react-notifications';
import TesterDetail from "../Containers/RegisterKit/testerDetail";
import RegisterKitConfirmation from "../Containers/RegisterKit/registerKitConfirmation";
import CovidTestGetStarted from "../Containers/Covid19/covidTestGetStarted";
import CovidAssessment from "../Containers/Covid19/covidAssessment";
import CovidBooking from "../Containers/Covid19/covidBooking";
import CovidMyBooking from "../Containers/Covid19/covidMyBooking";
import CovidScheduler from "../Containers/Covid19/covidScheduler";
import CovidVisitInfo from "../Containers/Covid19/covidVisitInfo";
import CovidConfirmation from "../Containers/Covid19/covidConfirmation";

class PublicRoutes extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <div>
        <NotificationContainer/>
        <div className="PrivateArea__content">
          {
            !props.location.pathname.includes('covid-19-testing') &&
                <>
                  <Header />
                  <Breadcrumbs />
                </>
          }
          <div className="main_sec">
            <Switch>
              <PrivateRoute
                path="/subscription"
                component={Subscription}
                exact
              />
              <Route path="/cart" component={Cart} exact />
              <PrivateRoute path="/payments" component={Payments} exact />
              <PrivateRoute path="/tester-info" component={TesterDetail} exact />
              <PrivateRoute path="/kit-confirmation" component={RegisterKitConfirmation} exact />
              <Route path="/support" component={Contact} exact />
              <Route path="/support/help" component={Help} exact />
              <PrivateRoute path="/verify" component={Verify} exact />
              <PrivateRoute path="/profile" component={Profile} exact />
              <PrivateRoute path="/reports" component={Reports} exact />
              <PrivateRoute path="/shipping" component={Shipping} exact />
              <PrivateRoute path="/signin" component={Signin} exact />
              <PrivateRoute path="/orders" component={Orders} exact />
              <PrivateRoute
                path="/order-details/:orderId"
                component={OrderDetails}
              />
              <PrivateRoute path="/settings" component={Settings} exact />
              <Route path="/categories/:id" component={ProductDetail} exact />
              <Route path="/categories" component={Categories} exact />
              <PrivateRoute
                path="/register-kit"
                component={RegisterKit}
                exact
              />
              <PrivateRoute path="/forgot-password" component={ForgotPassword} exact />
              <PrivateRoute path="/reset-password" component={ResetPassword} exact />
              <PrivateRoute
                path="/orderConfirmation/:orderId"
                component={OrderConfirmation}
                exact
              />
              <PrivateRoute
                path="/trackOrders/:orderId"
                component={TrackOrder}
                exact
              />
              <PrivateRoute
                  path="/employees"
                  component={Employees}
                  exact
              />
              <PrivateRoute
                  path="/add-employee"
                  component={AddEmployee}
                  exact
              />
              <PrivateRoute
                 path="/employees/:empId"
                 component={EmployeeDetail}
              />
              <PrivateRoute
                 path="/employees-history"
                 component={EmployeeHistory}
              />
              <Route path="/" component={Home} exact />
              <Route path="/page/:pageSlug" component={About_Us} exact />
              <Route path="/support/help/category/:categoryName" component={Help} exact />
              <Redirect  to="/orders" from="/order-details" exact></Redirect>
              <Redirect  to="/orders" from="/trackOrders" exact></Redirect>
              <Redirect to="/" from="/home"></Redirect>
              <Route path="/covid-19-testing" component={CovidTestGetStarted} exact />
              <Route path="/covid-19-testing/assessment" component={CovidAssessment} exact />
              <Route path="/covid-19-testing/check-booking" component={CovidBooking} exact />
              <Route path="/covid-19-testing/my-bookings" component={CovidMyBooking} exact />
              <Route path="/covid-19-testing/covid-scheduler" component={CovidScheduler} exact />
              <Route path="/covid-19-testing/covid-scheduler/covid-visit-info" component={CovidVisitInfo} exact />
              <Route path="/covid-19-testing/covid-scheduler/covid-confirmation" component={CovidConfirmation} exact />
              <Route path="*" component={NotFound404} exact />
              order-
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default PublicRoutes;
