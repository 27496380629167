import React, {useCallback, useEffect, useState} from "react";
import {employeeAction} from "../../Redux/Actions/index";
import { useDispatch, useSelector } from "react-redux";
import "../Employees/employees.scss";
import {NotificationManager} from "react-notifications";
import { debounce } from 'lodash';
import moment from "moment";

const EmployeesTestHistory = (props) => {
    let dispatch = useDispatch();
    const authData = useSelector((state) => state.authReducer.authData);

    const [isLoadingEmployeesHistory, setIsLoadingEmployeesHistory] = useState(false);
    const [testHistoryList, setTestHistoryList] = useState([]);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        (async () => {
            if(authData.isOrganisationUser === 0) {
                return;
            }
            await fetchEmployeesTestHistory();
        })();
    }, []);

    useEffect(() => {
        if(authData.isOrganisationUser === 0) {
            NotificationManager.error('You do not have permission to access this page');
            props.history.push('/');
            return;
        }
    }, [authData.isOrganisationUser]);

    const fetchEmployeesTestHistory = async (searchValue) => {
        setIsLoadingEmployeesHistory(true);
        const employeeTestHistoryList = await dispatch(employeeAction.getEmployeesTestHistoryList(searchValue));
        setIsLoadingEmployeesHistory(false);
        if(employeeTestHistoryList.isSuccess) {
            setTestHistoryList(employeeTestHistoryList.data);
        }
    };

    const onSearchEmployeesTestHistory = (e) => {
        const value = e.target.value;
        setSearchText(value);
        if(value.length > 2) {
            debounceSearchCall(value? value.trim().toLowerCase(): '');
        }
        if(!value || value.length === 0) {
            fetchEmployeesTestHistory();
        }
    };

    const debounceSearchCall = useCallback(debounce(fetchEmployeesTestHistory, 1000), []);

    return (
        <div className="employees-main">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="employee-table-head-right">
                            <div className="employee-table-search mr-2">
                                <input className="form-control"
                                       type="text" placeholder="search"
                                       value={searchText}
                                       onChange={(e) => onSearchEmployeesTestHistory(e)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Product</th>
                            <th>User Name</th>
                            <th>User Email</th>
                            <th>Date</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            isLoadingEmployeesHistory?
                                (<tr>
                                    <td className="text-center" colSpan={6}>
                                    <span
                                        className={"spinner-border"}
                                    ></span>
                                    </td>
                                </tr>) :
                                (
                                    testHistoryList.length === 0?
                                        (
                                            <tr><td className="text-center" colSpan={6}>
                                                There are no employees test history for your organization
                                            </td></tr>
                                        ):
                                        testHistoryList.map((testHistory) => {
                                            return (
                                                <tr key={testHistory.id}>
                                                    <td className={'d-flex flex-column'}>
                                                        <span>{testHistory.productName}</span>
                                                        { testHistory.orderNumber && <span style={{'font-size': '12px', 'color': '#444'}}>({testHistory.orderNumber})</span>}
                                                    </td>
                                                    <td>{testHistory.full_name}</td>
                                                    <td>{testHistory.email}</td>
                                                    <td>{testHistory.test_date && moment(testHistory.test_date).format('l')}</td>
                                                    <td>{ testHistory.reportFile && <a style={{textDecoration:"none"}} className="red-btn-border" href={testHistory.reportFile} target='_blank'>view</a>}</td>
                                                </tr>
                                            )
                                        })
                                )
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default EmployeesTestHistory;
