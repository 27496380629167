import React, { useEffect, useState } from "react";
import {cartAction, productActions} from "../../Redux/Actions/index";
import { useSelector, useDispatch } from "react-redux";
import OrderSummary from "../../Modules/orderSummaryCard";

import "../Cart/cart.scss";
import { SHIPPING_PAGE } from '../../constant'
import $ from "jquery";
// import Test from "../../Images/test.png";
// import Coupon from "../../Images/discount.png";

const Cart = (props) => {
  const dispatch = useDispatch();
  const cartDetails = useSelector((state) => state.cartReducer.cartItems);
  const [itemToRemove, setItemToRemove] = useState({});
  const [removeItemModel, setRemoveItmeModel] = useState(false);
  const [subTotals, setSubTotal] = useState(0.00);
  const commonData = useSelector((state) => state.commonReducer);

  useEffect(() => {
    (async () => {
      await dispatch(cartAction.getCartItems());
    })()
  }, []);


  const onProceed = async () => {
    if (localStorage.getItem("token") ) {
        let cartItems = [], selectedQuantity = [];
        if (localStorage.getItem("cartItems")) {
          cartItems = JSON.parse(localStorage.getItem("cartItems"));
        }
        if (cartItems && cartItems.length > 0) {
          selectedQuantity = cartItems.map(elem => {
            return {
              id: elem.id,
            };
          });

          const checkQuantityPromise = selectedQuantity.map(async (obj) => {
            return await dispatch(productActions.checkValidQuantity(obj.id, 0));
          });
          const res = await Promise.all(checkQuantityPromise);
          const allPassed = res.every(({data}) => data.success);
          if(allPassed) {
            props.history.push("/shipping");
          }
          dispatch(productActions.resetCheckQuantity());
        }
    } else {
      props.history.push("/signin");
    }
  };

  const handleQuantityChange = async (item, incmType) => {
    // await dispatch(cartAction.changeQuantity(item, 1, incmType));
    console.log("ITEM", item, incmType);
    if (incmType === "plus") {
      if (item.selectedQuantity < item.quantity) {
        await dispatch(cartAction.changeQuantity(item, 1, incmType));
        await dispatch(cartAction.getCartItems());

        console.log("cartDetails", cartDetails)

      }
    } else {
      if (item.selectedQuantity > 1) {
        // console.log("minus");
        // await dispatch(cartAction.changeItemQuantity(item, item.selectedQuantity - 1))
        await dispatch(cartAction.changeQuantity(item, 1, incmType));
        await dispatch(cartAction.getCartItems());
        // await dispatch(cartAction.calculateSubTotal(cartDetails));
        // this.props.changeItemQuantity(item, item.selectedQuantity - 1);
      }
    }
    // await dispatch(cartAction.calculateSubTotal(cartDetails));
  };

  return (
    <div className="cart-main">
      <div className="container">
        <h2>Your Cart</h2>
        <div className="row">
          <div className="col-md-8 border-thick">
            {cartDetails?.length > 0 ? (
              cartDetails?.map((data) => {
                return (
                  <div className="cart-shop-all">
                    <div className="remove-cart">
                      <div className="text-center">

                        <div className="cart-img">
                          <img src={data?.image_name} />
                        </div>
                        <p
                          className="trash-p"
                          onClick={() => {
                            setItemToRemove(data);
                            setRemoveItmeModel(true);
                          }}
                        >
                          <i class="fas fa-trash"></i>
                        </p>
                        <p style={{ color: "green", marginTop: 5 }}>{data.subscription && data.subscriptionDuration}</p>
                      </div>
                      <div className="home-cart-desc">
                        <div className="desc-kit-dollar">
                          <h3>{data?.name}</h3>
                          <p>${data?.price.toFixed(2)}</p>
                        </div>
                        <span className={"ship"}>Ship Tomorrow</span>
                        <div className="quantity-btn">
                          <p>Quantity</p>
                          <div className="quantity-btn-sub">
                            <button
                              onClick={() =>
                                handleQuantityChange(data, "minus")
                              }
                            >
                              <span>
                                <i class="fas fa-minus"></i>
                              </span>
                            </button>
                            <input
                              type="number"
                              placeholder={data?.selectedQuantity}
                              disabled
                              readOnly
                            />
                            <button
                              onClick={() => handleQuantityChange(data, "plus")}
                            >
                              <span>
                                <i class="fas fa-plus"></i>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
                <p>No item added yet</p>
              )}

            {removeItemModel === true ? (
              <div
                className="modal"
                id="removeItem"
                style={{ display: "block" }}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">
                        {" "}
                        Are you sure to remove{" "}
                        {itemToRemove ? (
                          <b>{itemToRemove.name}</b>
                        ) : (
                            "item"
                          )}{" "}
                        from the cart?
                      </h4>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        onClick={(e) => setRemoveItmeModel(false)}
                      >
                        <span>
                          <i class="far fa-times-circle"></i>
                        </span>
                      </button>
                    </div>

                    <div className="modal-body" style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}>
                      <button
                        className="red-btn"
                        onClick={(e) => {
                          dispatch(cartAction.removeItemFromCart(itemToRemove));
                          setRemoveItmeModel(false);
                        }}
                      >
                        Yes
                      </button>
                      <button
                        className="red-btn"
                        onClick={(e) => setRemoveItmeModel(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-md-4">
            <OrderSummary />
            <button
              className="red-btn mt-2 w-100"
              disabled={cartDetails?.length > 0 ? false : true}
              onClick={() => {
                onProceed();
              }}
            >
              Proceed
              <span className={commonData.loading === true ? "spinner-border" : ""}></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
