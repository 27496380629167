function isANumber(str){
  return !/\D/.test(str);
}

export const validateLoginForm = (formData) => {
  let isFormValid = true;
  let error = {
    email: [],
    password: [],
  };
  if (formData.email.trim().length === 0) {
    isFormValid = false;
    error.email = ["Email cannot be empty!"];
  }
  if (
    formData.email.trim().length > 0 &&
    /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
      formData.email
    ) === false
  ) {
    isFormValid = false;
    error.email = ["Email must be a valid email address"];
  }
  if (formData.password.trim().length === 0) {
    isFormValid = false;
    error.password = ["Password cannot be empty!"];
  }
  if (
    formData.password.trim().length > 0 &&
    (formData.password.trim().length < 6 ||
      formData.password.trim().length > 15)
  ) {
    isFormValid = false;
    error.password = [
      "Incorrect password.",
    ];
  }
  // setLoginError(error);
  // return isFormValid;

  return {
    isFormValid,
    error
  }
};

const validatePasswordStrength = (password) => {
  if(
      !password ||
      password.length > 0 &&
      (password.length < 8 || password.length > 15)
  ) {
    return 'Length must be between 8 to 15 characters'
  }
  if(!password.match(/[A-Z]/)) {
    return 'At least one uppercase character required';
  }
  if(!password.match(/[a-z]/)) {
    return 'At least one lowercase character required';
  }
  if(!password.match(/[0-9]/)) {
    return 'At least one digit required';
  }
  if(!password.match(/[#?!@$%^&*-]/)) {
    return 'At least one special character required';
  }

  return null;
};

export const validateSignUpForm = (formData) => {
  let isFormValid = true;
  let error = {
    fullName: [],
    email: [],
    password: [],
    confirmPassword: [],
  };
  if (formData.email.trim().length === 0) {
    isFormValid = false;
    error.email = ["Email cannot be empty!"];
  }
  if (
    formData.email.trim().length > 0 &&
    /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
      formData.email
    ) === false
  ) {
    isFormValid = false;
    error.email = ["Email must be a valid email address"];
  }
  if (formData.password.trim().length === 0) {
    isFormValid = false;
    error.password = ["Password cannot be empty!"];
  }
  const passwordErr = validatePasswordStrength(formData.password?.trim());
  if (passwordErr) {
    isFormValid = false;
    error.password = [passwordErr];
  }
  if (formData.confirmPassword.trim().length === 0) {
    isFormValid = false;
    error.confirmPassword = ["Confirm Password cannot be empty!"];
  }

  const confPasswordErr = validatePasswordStrength(formData.confirmPassword?.trim());
  if (confPasswordErr) {
    isFormValid = false;
    error.confirmPassword = [confPasswordErr];
  }

  if (
    formData.confirmPassword.trim().length > 0 &&
    formData.confirmPassword.trim().length > 0 &&
    formData.password.trim() !== formData.confirmPassword.trim()
  ) {
    isFormValid = false;
    error.confirmPassword = ["Passwords Don’t Match!"];
  }
  if (formData.fullName.trim().length === 0) {
    isFormValid = false;
    error.fullName = ["Name is required"];
  }
  if (formData.fullName.trim().length > 0 && formData.fullName.trim().length > 50) {
    isFormValid = false;
    error.fullName = ["Name length cannot be gretar than 50"];
  }

  return {
    isFormValid,
    error
  }
};


export const validateEditProfileForm = (state) => {
  console.log("state in validateEditProfileForm",state)
  let isFormValid = true;
  let error = {
    full_name: [],
    email: [],
    phonenumber: []
  };
  if (state?.full_name.trim().length === 0) {
    isFormValid = false;
    error.full_name = ["Name cannot be empty!"];
  }
  if (state?.email.trim().length === 0) {
    isFormValid = false;
    error.email = ["Email cannot be empty!"];
  }
  if (state?.email.trim().length > 0 && /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(state?.email) === false) {
    isFormValid = false;
    error.email = ["Email must be a valid email address"];
  }

  if (state?.phonenumber === null || state?.phonenumber?.trim().length === 0) {
    isFormValid = false
    error.phonenumber = ["Contact Number is required"]
  }

  // setError(error);
  return {
    isFormValid,
    error
  }
};



export const validateAddressEdit = (state) => {
  let isFormValid = true;
  let error = {
    email:[],
    address: [],
    city: [],
    country: [],
    state: [],
    zipCode: [],
    full_name:[]
  };

  if (state?.full_name === null || state?.full_name === undefined || state?.full_name?.trim().length === 0) {
    isFormValid = false;
    error.full_name = ["Full Name cannot be empty!"];
  }
  if (state?.email === null || state?.email === undefined || state?.email?.trim().length === 0) {
    isFormValid = false;
    error.email = ["Email cannot be empty!"];
  }
  if (state?.address === null || state?.address === undefined || state?.address?.trim().length === 0) {
    isFormValid = false;
    error.address = ["Address cannot be empty!"];
  }
  if (state?.city === null || state?.city === undefined || state?.city?.trim().length === 0) {
    isFormValid = false;
    error.city = ["City cannot be empty!"];
  }
  if (state?.state === null || state?.state === undefined || state?.state?.trim().length === 0) {
    isFormValid = false;
    error.state = ["State cannot be empty!"];
  }
  if (state?.country === null || state?.country === undefined || state?.country?.trim().length === 0) {
    isFormValid = false;
    error.country = ["Country cannot be empty!"];
  }
  if (state?.zipCode === null || state?.zipCode === undefined || state?.zipCode?.trim().length === 0) {
    isFormValid = false;
    error.zipCode = ["Zip code cannot be empty!"];
  }

  if (isANumber(state?.zipCode) === false) {
    isFormValid = false;
    error.zipCode = ["Please provide a valid zip code!"];
  }
  if (state?.zipCode?.length < 5) {
    isFormValid = false;
    error.zipCode = ["Zip code must be 5 digits!"];
  }


  // setError(error);
  return {
    isFormValid,
    error
  }
};
export const validateEmailEdit = (state) => {
  let isFormValid = true;
  let error = {
    email:[]
  };


  if (state?.email === null || state?.email === undefined || state?.email?.trim().length === 0) {
    isFormValid = false;
    error.email = ["Email cannot be empty!"];
  }
  if (
    state.email.trim().length > 0 &&
    /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
      state.email
    ) === false
  ) {
    isFormValid = false;
    error.email = ["Email must be a valid email address"];
  }
  // setError(error);
  return {
    isFormValid,
    error
  }
};

export const validateChangePasswordForm = (formData) => {
  let isFormValid = true;
  let error = {
    oldPassword: [],
    newPassword: [],
    confirmNewPassword: [],
  };
  if (formData.oldPassword.trim().length === 0) {
    isFormValid = false;
    error.oldPassword = ["Old Password cannot be empty!"];
  }

  if (formData.newPassword === "" || formData.newPassword.trim().length === 0) {
    isFormValid = false;
    error.newPassword = ["New Password cannot be empty!"];
  }

  const newPasswordErr = validatePasswordStrength(formData.newPassword?.trim());
  if (newPasswordErr) {
    isFormValid = false;
    error.newPassword = [newPasswordErr];
  }

  if (formData.confirmNewPassword.trim().length === 0) {
    isFormValid = false;
    error.confirmNewPassword = ["Confirm Password cannot be empty!"];
  }

  const confirmNewPasswordErr = validatePasswordStrength(formData.confirmNewPassword?.trim());
  if (confirmNewPasswordErr) {
    isFormValid = false;
    error.confirmNewPassword = [confirmNewPasswordErr];
  }

  if (
    formData.confirmNewPassword.trim().length > 0 &&
    formData.confirmNewPassword.trim().length > 0 &&
    formData.newPassword.trim() !== formData.confirmNewPassword.trim()
  ) {
    isFormValid = false;
    error.confirmNewPassword = ["Passwords Don’t Match!"];
  }
  // setErrorData(error);
  return {
    isFormValid,
    error
  }
};

export const validateResetPasswordForm = (formData) => {
  console.log('FORM', formData)
  let isFormValid = true;
  let error = {
    newPassword: [],
    confirmNewPassword: [],
  };

  if (formData.newPassword === "" || formData.newPassword.trim().length === 0) {
    isFormValid = false;
    error.newPassword = ["New Password cannot be empty!"];
  }

  const passwordErr = validatePasswordStrength(formData.newPassword?.trim());
  if (passwordErr) {
    isFormValid = false;
    error.newPassword = [passwordErr];
  }

  if (formData.confirmNewPassword === ""  || formData.confirmNewPassword.trim().length === 0) {
    isFormValid = false;
    error.confirmNewPassword = ["Confirm Password cannot be empty!"];
  }

  const confirmNewPasswordErr = validatePasswordStrength(formData.confirmNewPassword?.trim());
  if (confirmNewPasswordErr) {
    isFormValid = false;
    error.confirmNewPassword = [confirmNewPasswordErr];
  }

  if (
    formData.confirmNewPassword.trim().length > 0 &&
    formData.confirmNewPassword.trim().length > 0 &&
    formData.newPassword.trim() !== formData.confirmNewPassword.trim()
  ) {
    isFormValid = false;
    error.confirmNewPassword = ["Passwords Don’t Match!"];
  }
  console.log(error, "err");
  // setErrorData(error);
  return {
    isFormValid,
    error
  }
};


export const validateEmail = (formData) => {
  console.log("CALLED",formData)
  let isFormValid = true;
  let error = {
    email: [],
  };
  if (formData.email.trim().length === 0) {
    isFormValid = false;
    error.email = ["This field cannot be empty!"];
  }
  if (
    formData.email.trim().length > 0 &&
    /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
      formData.email
    ) === false
  ) {
    isFormValid = false;
    error.email = ["Email must be a valid email address"];
  }

  console.log(error, "LOGIN FROM ERR");
  // setLoginError(error);
  // return isFormValid;

  return {
    isFormValid,
    error
  }
};

