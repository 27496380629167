import Api from "../../Api";
import { handleError } from "./commonAction";

export const getFaqCategoryList = () => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_FAQ_CATEGORY_LIST" });
        const res = await Api.get(`/api/v1/faq-category/list`);

        await dispatch({
            type: "FAQ_CATEGORY_LIST",
            payload: { data: res?.data?.data }
        });

        dispatch({ type: "LOADED_FAQ_CATEGORY_LIST" });
    } catch (error) {
        dispatch({ type: "LOADING_FAQ_CATEGORY_LIST" });
        dispatch({ type: "LOADING_FAILURE" });
        dispatch(handleError(error));
    }
};

export const getFaqByCategory = (category) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_FAQ_BY_CATEGORY_LIST" });
        const res = await Api.get(`/api/v1/faq/list?category=${category}`);

        await dispatch({
            type: "FAQ_BY_CATEGORY_LIST",
            payload: { data: res?.data?.data }
        });

        dispatch({ type: "LOADED_FAQ_BY_CATEGORY_LIST" });
    } catch (error) {
        dispatch({ type: "LOADED_FAQ_BY_CATEGORY_LIST" });
        dispatch({ type: "LOADING_FAILURE" });
        dispatch(handleError(error));
    }
};

export const searchFaq = (searchText) => async (dispatch) => {
    try {
        dispatch({ type: "SEARCH_FAQ" });
        const res = await Api.get(`/api/v1/faq/list?searchText=${searchText}`);

        await dispatch({
            type: "SEARCHED_FAQ",
            payload: { data: res?.data?.data }
        });

        dispatch({ type: "SEARCH_FAQ_COMPLETE" });
    } catch (error) {
        dispatch({ type: "SEARCH_FAQ_COMPLETE" });
        dispatch({ type: "LOADING_FAILURE" });
        dispatch(handleError(error));
    }
};
