import Api from "../../Api";
import { handleError } from "./commonAction";


export const getPageContent = (pageSlug) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await Api.get(`/api/v1/page/${pageSlug}`);
      // console.log("RE,",res)
      // localStorage.setItem("userData",JSON.stringify(res.data.data))
      dispatch({ type: "LOADING_COMPLETED" });
      return res;
        
      
      return res.data;
    } catch (error) {
        console.log('error while getting page content', error);
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
      
    }
  };