import React, {useEffect, useState, useCallback} from 'react'
import { useDispatch, useSelector } from "react-redux";
import "../Help/help.scss";
import {Link} from "react-router-dom";
import {helpAction} from "../../Redux/Actions";
import { debounce } from 'lodash';
import queryString from "query-string";

const Help = (props) => {

    const dispatch = useDispatch();
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [FAQs, setFAQs] = useState([]);
    const [selectedFAQId, setSelectedFAQId] = useState('');
    const [searchText, setSearchText] = useState('');
    const [searchedFAQs, setSearchedFAQs] = useState([]);
    const {
        categoryList,
        FAQList,
        loadingFAQs,
        loadingCategory,
        searchedFAQList,
    } = useSelector((state) => state.helpReducer);

    useEffect(() => {
        if(categoryList.length === 0) {
          dispatch(helpAction.getFaqCategoryList());
          return;
        }
        setCategories(categoryList);
    }, []);

    useEffect(() => {
        if(categoryList.length !== 0) {
            setCategories(categoryList);
        }
    }, [categoryList]);

    useEffect(() => {
        const selectedCategoryMenu = props.match.params.categoryName;
        if(selectedCategoryMenu) {
            const category = selectedCategoryMenu.replace(/-/g, ' ');
            setSelectedCategory(category);
            setSelectedFAQId('');
            setSearchedFAQs([]);
            setSearchText('');
            dispatch(helpAction.getFaqByCategory(category));
        }
    }, [props.match.params.categoryName]);

    useEffect(() => {
        const faqId = props.location.search? queryString.parse(props.location.search).faqId: null;
        setSelectedFAQId(faqId? +faqId: null);
    }, [props.location.search]);

    useEffect(() => {
        setFAQs(FAQList);
    }, [FAQList]);

    useEffect(() => {
        setSearchedFAQs(searchedFAQList);
    }, [searchedFAQList]);

    const renderCategories = () => {
      return categories.map((category, index) => {
          const linkPath = category.name.trim().split(/\s+/g).join('-');
          return (
              <li key={`${category.id}-${index}`}>
                  <Link
                      to={`/support/help/category/${linkPath}`}
                      className={selectedCategory === category.name? 'active': ''}
                  >
                      {category.name}
                      {
                          selectedCategory === category.name &&
                          <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
                      }
                  </Link>
              </li>
          )
      });
    };

    const handleFAQClick = (faqId) => {
        setSelectedFAQId(faqId);
    };

    const renderFAQs = () => {
      return FAQs.map((faq, index) => {
          return (
              <li key={`${faq.id}-${index}`} className={'faq'}>
                  <span className={'heading'}>
                    <i className="fa fa-file"></i> <a href='#' onClick={() => handleFAQClick(faq.id)}>
                          {faq.heading}
                      </a>
                  </span>
                  {
                      selectedFAQId === faq.id &&
                      <div className={'body'}>
                          <div dangerouslySetInnerHTML={{ __html: faq.content ? faq.content : `No Info` }}></div>
                      </div>
                  }
              </li>
          )
      });
    };

    const onSearchFaq = (e) => {
        const value = e.target.value;
        setSearchText(value);
        if(value.length > 3) {
            debounceSearchCall(value? value.trim().toLowerCase(): '');
        } else {
            setSearchedFAQs([]);
        }
    };

    const fetchFAQs = (value) => {
        dispatch(helpAction.searchFaq(value))
    };

    const debounceSearchCall = useCallback(debounce(fetchFAQs, 1000), []);

    const handleFAQClickOnSearch = (faq) => {
        const category = JSON.parse(faq.category);
        const linkPath = category[0].trim().split(/\s+/g).join('-');
        props.history.push(`/support/help/category/${linkPath}?faqId=${faq.id}`);
    };

    const renderSearchResult = () => {
        if(searchText && searchedFAQs.length === 0) {
            return (
                <li className={'noResult'}>
                    No results found
                </li>
            )
        }
        return (
            searchedFAQs.map((faq, index) => {
                return (
                    <li key={`${faq.id}-${index}`}>
                        <a href='#' onClick={() => handleFAQClickOnSearch(faq)}>{faq.heading}</a>
                    </li>
                )
            })
        )
    };

    return (
        <div className="help-main">
            <div className="container">
                <div class="row mt-3">
                    <div className="col-md-4 left-container">
                        <div className="search-main form-group">
                            <input
                                type="text"
                                className="form-control search-query"
                                placeholder="Search"
                                onChange={(e) => onSearchFaq(e)}
                                value={searchText}
                            ></input>
                            <button>
                                <span>
                                  <i className="fas fa-search"></i>
                                </span>
                            </button>
                            {
                                setSearchedFAQs.length !== 0 &&
                                <div className="search-result">
                                    <ul className="result">
                                        {renderSearchResult()}
                                    </ul>
                                </div>
                            }

                        </div>

                        <h3 class="category-head">Categories</h3>

                        {
                            loadingCategory ?
                                <span className={"spinner-border"}></span>:
                                (
                                    <ul className={"category-list"}>
                                        {renderCategories()}
                                    </ul>
                                )
                        }
                    </div>
                    <div className="col-md-8 right-container">
                        {
                            loadingFAQs ?
                                <span className={"spinner-border"}></span>:
                                (
                                    <div className={'faq-container'}>
                                        <h1>{selectedCategory}</h1>
                                        <ul className={"faq-list"}>
                                            {renderFAQs()}
                                        </ul>
                                    </div>
                                )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Help;
