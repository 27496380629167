import React, { useState, useEffect } from "react";
import {
    CardElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import {cartAction, userActions} from "../../Redux/Actions";
import { PAYMENT_PAGE } from '../../constant'
import { NotificationManager } from "react-notifications";

function PaymentMethod(props) {
    const [error, setError] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [paymentMethodLoading, setPaymentMethodLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();
    let dispatch = useDispatch();
    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    const handlePaymentMethodSubmit = async ev => {
        setPaymentMethodLoading(true);
        ev.preventDefault();
        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });
        // const payload = await stripe.createToken(cardElement);
        if (error) {
            setError(error.message);
            setPaymentMethodLoading(false);
            return;
        }
        setError(false);
        const res = await dispatch(userActions.addPaymentMethod(paymentMethod));
        if(!res.isSuccess) {
            NotificationManager.error(res.errorMessage);
        } else {
            NotificationManager.success(res.message);
        }
        props.setUserPaymentMethod(res.data);
        setPaymentMethodLoading(false);
    };

    const handlePaymentMethodChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };

    return (
        <div id="payment-form" >
            <form onSubmit={handlePaymentMethodSubmit}>
                <CardElement id="card-element" options={cardStyle} onChange={handlePaymentMethodChange} />
                <button
                    disabled={paymentMethodLoading || disabled}
                    id="submit"
                    type={"submit"}
                    className="red-btn mt-5 w-80">
                    <span id="button-text">
                      Add
                        <span className={paymentMethodLoading && "spinner-border"}></span>
                    </span>
                </button>
            </form>
            {error && (
                <div className="card-error" role="alert">
                    {error}
                </div>
            )}
        </div>
    );
}

export default React.memo(withRouter(PaymentMethod))
