export const initialState = {
    orderList: [],
    orderData:{},
    orderDetails:{},
    getAllTracks: [],
    orderConfirmationDetails:[],
    checkOrdersData: [],
    testReportData : [],
    message:null
};

export const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ORDER_LIST":
            return {
                ...state,
                orderList: action.payload?.data
            }
            case "ORDER_DATA":
            return {
                ...state,
                orderData: JSON.parse(action.payload?.data.address),
                checkOrdersData : action.payload?.data
            }
            
            case "ORDER_DETAILS":
                return {
                    ...state,
                    orderDetails: action.payload?.data
                }
              case "GET_ORDER_TRACKING_DETAILS":
                  return {
                      ...state,
                      getAllTracks: action.payload?.data
                  }  
                  case 'GET_ORDER_CONFIRMATION_DETAILS':
                      return {
                          ...state,
                          orderConfirmationDetails: action.payload.data
                      }
                      case 'GET_TEST_REPORTS':
                          return {
                              ...state,
                              testReportData: action.payload.data,
                              message:action.payload.data?.message
                          }
                          case 'CLEAN_MESSAGE':
                            return {
                                ...state,
                              //   testReportData: action.payload.data,
                                message:""
                            }
                          
        default:
            return state
    }
}