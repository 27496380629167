import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { loginUser } from "../Redux/Actions/authActions";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { validateLoginForm } from "../Validation";

const Login = (props) => {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.authReducer.authData);
  const commonData = useSelector((state) => state.commonReducer);
  const [state, setState] = useState({});
  const [loginState, setLoginState] = useState({
    email: "",
    password: "",
  });
  const [loginError, setLoginError] = useState({
    email: [],
    password: [],
  });

  useEffect(() => {
    return () => {
      dispatch({ type: "CLEAN_ERROR" });
      dispatch({ type: "CLEAR_LOGIN" });
    };
  }, []);

  function handleChange(event) {
    const { name, value } = event.target;
    setLoginState((prevState) => ({ ...prevState, [name]: value }));
  }

  const onSubmitLogin = async (e) => {
    e.preventDefault();
    let divRef = null;
    dispatch({ type: "CLEAN_ERROR" });

    console.log("authData", props);
    const results = await validateLoginForm(loginState);
    console.log("results", results);
    if (results?.isFormValid === false) {
      setLoginError(results.error);
    }

    if (props.myDivToFocus && props.myDivToFocus.current) {
      divRef = props.myDivToFocus.current;
    }
    if (results?.isFormValid) {
      setLoginError({
        email: [],
        password: [],
      });
      const data = await props.loginUser(loginState);
      console.log("DATA", data);
      // await dispatch(await authActions.loginUser(loginState));

      if (data && data.user) {
        if (props?.setLogin) {
          props.setLogin(false);
        }
        dispatch({ type: "CLEAR_LOGIN" });
        props.history.push("/profile");
      } else if (props && props.myRef) {
        props.scrollToRef(props.myRef);
        window.scrollTo(0, 300);
      }
    } else {
      if (props && props.myRef) {
        props.scrollToRef(props.myRef);
        window.scrollTo(0, 300);
      }
    }
  };
  return (
    <form className="signup-form">
      <div class="form-group">
        <input
          type="email"
          class="form-control"
          placeholder="Email"
          name="email"
          onChange={(e) => handleChange(e)}
        />
        {loginError?.email[0] ? (
          <p className="error-validation">{loginError?.email[0]}</p>
        ) : null}
      </div>
      <div class="form-group">
        <input
          type="password"
          class="form-control"
          placeholder="Password"
          name="password"
          onChange={(e) => handleChange(e)}
        />
        {loginError?.password[0] ? (
          <p className="error-validation">{loginError?.password[0]}</p>
        ) : null}
      </div>
      <p>
        <Link
          to="/forgot-password"
          onClick={() => {
            if (props?.setLogin) {
              props.setLogin(false);
            }
          }}
        >
          Forgot Password?
        </Link>
      </p>
      <button className="red-btn" onClick={onSubmitLogin}>
        Login
        <span
          className={commonData.loading === true ? "spinner-border" : ""}
        ></span>
      </button>
    </form>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ loginUser }, dispatch);
};

export default withRouter(connect(null, mapDispatchToProps)(Login));
// export default withRouter(Login);
