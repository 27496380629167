import React,{ useEffect } from 'react';
import { Route } from "react-router";
import { useDispatch } from "react-redux";
import {authActions} from "./Redux/Actions/index";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';

import PublicRoutes from './Routers/publicRoutes';
import './Styles/styles.scss';
import './Styles/all.min.css';
import './Styles/media.scss';

import 'popper.js';

require('bootstrap');

function App() {
  let dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      if (localStorage.getItem("token")  && localStorage.getItem("userData")){
      await dispatch( await authActions.matchToken());
      dispatch({ type: "CLEAR_LOGIN" });}
     })();
  }, []);


  return (
    <div>
      <Route path="/" component={PublicRoutes} />
      <NotificationContainer/>
    </div>
  );
}

export default App;
