import React,{ useState, useEffect } from "react";
import { orderAction } from "../../Redux/Actions/index";
import { useDispatch, useSelector } from "react-redux";
import "../Orders/orders.scss";
import Test from "../../Images/test.png";
import Stripe from "../../Images/stripe-icon.png";
import { Modal, Button } from "react-bootstrap";
import { propTypes } from "react-bootstrap/esm/Image";
import {Rating} from "react-simple-star-rating";

const Orders = (props) => {
  let dispatch = useDispatch();
  const orderList = useSelector((state) => state.orderReducer.orderList);
  const [modal, setModal] = useState(false)
  useEffect(() => {
    (async () => {
      await dispatch(orderAction.getOrderList());
    })();
  }, []);

  const viewOrderDetails = () => setModal(true)
  const hideOrderDetails = () => setModal(false)
  const renderModal = (address) => {
    return (
      <Modal
        className="modal admin-modal edit-user-modal"
        id="myModal"
        show={modal}
        onHide={hideOrderDetails}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#250C6D" }}>
            Want to delete?
                  </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#9285B6" }}>
          <div
            className="button-modal-div"
          >
            {JSON.parse(address).fullName}
            {JSON.parse(address).phonenumber}
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <div className="orders-main">
      <div className="container">
        <h2>My Orders</h2>

        {/* <div className="details-order-invoice">
          <div className="invoice-details">
            <h4>Order Details</h4>
            <p>Order# 407-0627660-2184331</p>
            <p>Ordered by 20 July 2020</p>
            <p>
              <b>Arriving 25 July 2020 by 9 PM</b>
            </p>
          </div>
          <div className="invoice-text">
            <p>Invoice</p>
          </div>
        </div> */}

        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Total</th>
                <th>Status</th>
                <th>Order Detail</th>
              </tr>
            </thead>
            <tbody>
              {orderList?.map((data, i) => {
                return <tr >
                  <td>{data?.orderNumber}</td>
                  <td>${data?.total}</td>
                  <td>{data?.status}</td>
                  <td>
                    <button className="red-btn-border" onClick={()=>{props.history.push(`/order-details/${data.id}`)}}>view</button> &nbsp;
                    {
                      data?.status === 'Shipped' && (!data.rating ?
                          <span className={"feedback-icon"} title={'Give Feedback'} onClick={()=>{props.history.push(`/order-details/${data.id}#feedback`)}}><i className={"fa fa-comments"}></i></span>:
                          <span className={"rating"}
                                onClick={()=>{props.history.push(`/order-details/${data.id}#feedback`)}}>
                           <Rating
                               ratingValue={data.rating}
                               allowHalfIcon={true}
                               readonly={true}
                               showTooltip={false}
                               size="15"
                               iconsCount={1}
                           />
                          </span>
                      )
                    }
                  </td>
                </tr>;
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Orders;
