import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OrderSummary from "../../Modules/orderSummaryCard";
import "../Payments/payments.scss";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Visa from "../../Images/visa.png";
import AmericanExpress from "../../Images/american_express.png";
import Master from "../../Images/master.png";

import CheckoutForm from './CheckoutForm';
import {cartAction, orderAction, userActions} from "../../Redux/Actions";
import {
  validateAddressEdit,
  validateEmailEdit
} from "../../Validation";
import {PAYMENT_PAGE, SHIPPING_PAGE} from '../../constant'
import {NotificationManager} from "react-notifications";

const promise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);
const Payments = (props) => {
  const dispatch = useDispatch();
  const [editModel, setEditModel] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [actualEmail, setActualEmail] = useState('');
  const [actualAddress, setActualAddress] = useState({});
  const [processCheckOut, setProcessCheckOut] = useState(false);
  const [error, setError] = useState({
    address: [],
    city: [],
    country: [],
    state: [],
    zipCode: [],
    email: []
  });
  const [state, setState] = useState({
    fullName: "",
    email: "",
    password: "",
    phoneNumber: "",
    address: "",
    city: "",
    country: "",
    state: "",
    zipCode: "",
    country_id: null,
    state_id: null,
    city_id: null
  });
  const [preferredPaymentOption, setPreferredPaymentOption] = useState('self');

  const orderData = useSelector(state => state.orderReducer.orderData)
  const errorRes = useSelector((state) => state.commonReducer);
  const { getCountriesList, getStateListByCountries, getCitiesByState } = useSelector((state) => state.userReducer);
  const authData = useSelector((state) => state.authReducer.authData);
  const cartDetails = useSelector((state) => state.cartReducer.cartItems);

  useEffect(() => {
    (
      async () => {

        if (props.history.location?.state?.prevPage === SHIPPING_PAGE) {
          const orderId = await localStorage.getItem("orderId");
          await dispatch(await orderAction.getOrderData(orderId));
        } else {
          props.history.push('/cart')
        }
      })();
  }, []);

  useEffect(() => {
    if (orderData.address !== state.address) {
      setState(orderData);
    }
  }, [orderData]);

  useEffect(() => {
    if(getCitiesByState && getCitiesByState.length !== 0) {
      if(orderData.city) {
        const selectedCity = getCitiesByState?.find((city) => city.city === orderData.city) || {};
        setState({...state, city_id: selectedCity.id});
      }
    }
  }, [getCitiesByState])

  useEffect(() => {
    if(getStateListByCountries && getStateListByCountries.length !== 0) {
      if(orderData.state) {
        const selectedState = getStateListByCountries?.find((state) => state.state_name === orderData.state);
        if(selectedState) {
          dispatch(userActions.getCities(selectedState.id))
          setState({...state, state_id: selectedState.id});
        }
      }
    }
  }, [getStateListByCountries])

  useEffect(() => {
    if(!getCountriesList || getCountriesList.length === 0) {
      dispatch(userActions.getCountries());
    } else {
        if (orderData?.country) {
          const selectedCountry = getCountriesList?.find((country) => country.name === orderData.country);
          if(selectedCountry) {
            dispatch(userActions.getStates(selectedCountry.id))
            setState({...state, country_id: selectedCountry.id});
          }
        }
      }
  }, [getCountriesList, orderData])

  function handleChange(event) {
    const { name, value } = event.target;
    if(name === 'zipCode') {
      const regEx = /^\b\d{0,5}\b$/;
      if (value === '' || regEx.test(value)) {
        setState((prevState) => ({ ...prevState, [name]: value }));
      }
      return;
    }
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  async function onSaveAddress(event) {
    event.preventDefault();

    state.full_name = "null";

    const results = await validateAddressEdit(state);

    let err = results.error
    if (results.isFormValid === false) {
      setError(err)
    }
    if (results?.isFormValid) {
      setEditModel(false);
      setEditEmail(false)
      err = {
        address: [],
        city: [],
        country: [],
        state: [],
        zipCode: [],
        email: []
      }
      setError(err)
      // state["fullName"] = state.full_name;
      // state["phoneNumber"] = state.phonenumber;
      // validate the shipping address
      const validateAddress = await dispatch(userActions.validateAddress(state));
      if(!validateAddress.isSuccess) {
        NotificationManager.error(validateAddress.errorMessage);
        return;
      }

      delete state.full_name
      const orderId = await localStorage.getItem("orderId");
      await dispatch(orderAction.updateOrderData(orderId, state));
      await dispatch(await orderAction.getOrderData(orderId));
      // await dispatch(authActions.matchToken());
      // const id = await JSON.parse(localStorage.getItem("userData")).id;
      // await dispatch(await userActions.userDataById(id));
    }
  }
  async function onSaveEmail(event) {
    event.preventDefault();

    state.full_name = "null";

    const results = await validateEmailEdit(state);

    let err = results.error;
    if (results.isFormValid === false) {
      setError((prev) => {
        return {
          ...prev,
          ...err
        }
      });
    }
    if (results?.isFormValid) {
      setEditModel(false);
      setEditEmail(false);
      err = {
        address: [],
        city: [],
        country: [],
        state: [],
        zipCode: [],
        email: []
      };
      setError(err)
      delete state.full_name;
      const orderId = await localStorage.getItem("orderId");
      await dispatch(orderAction.updateOrderData(orderId, state));
      await dispatch(await orderAction.getOrderData(orderId));
    }
  }

  const countryChange = async (e) => {
    e.preventDefault()
    let selectedCountry = getCountriesList.find((country) => country.id === (parseInt(e.target.value)))
    state[`country`] = selectedCountry.name;
    state[`country_id`] = selectedCountry.id;
    setState({ ...state });
    await dispatch(userActions.getStates(selectedCountry.id))
  }

  const stateChange = async (e) => {
    e.preventDefault()
    let selectedState = getStateListByCountries.find((state) => state.id === (parseInt(e.target.value)))
    state[`state`] = selectedState.state_name
    state[`state_id`] = parseInt(selectedState.id)
    setState({ ...state, city: '', city_id: '' });
    await dispatch(userActions.getCities(parseInt(selectedState.id)))
  }

  const cityChange = (e) => {
    let selectedCity = getCitiesByState.find((city) => city.id === (parseInt(e.target.value)))
    state[`city`] = selectedCity.city
    state[`city_id`] = selectedCity.id
    setState({ ...state });
  }

  const prepareEditEmail = () => {
    setState({
      ...state,
      email: state.email || orderData.email,
      address: state.address || orderData.address,
      city: state.city || orderData.city,
      country: state.country || orderData.country,
      state: state.state || orderData.state,
      zipCode: state.zipCode || orderData.zipCode,
      country_id: state.country_id || orderData.country_id,
      state_id: state.state_id || orderData.state_id,
      city_id: state.city_id || orderData.city_id
    });
    setError({
      address: [],
      city: [],
      country: [],
      state: [],
      zipCode: [],
      email: []
    });
    setActualEmail(state.email);
    setEditEmail(true);
  };

  const cancelEditEmail = () => {
    setState({...state, email: actualEmail});
    setEditEmail(false);
  };

  const prepareEditAddress = () => {
    setError({
      address: [],
      city: [],
      country: [],
      state: [],
      zipCode: [],
      email: []
    });
    setState({
      ...state,
      email: state.email || orderData.email,
      address: state.address || orderData.address,
      city: state.city || orderData.city,
      country: state.country || orderData.country,
      state: state.state || orderData.state,
      zipCode: state.zipCode || orderData.zipCode,
      country_id: state.country_id || orderData.country_id,
      state_id: state.state_id || orderData.state_id,
      city_id: state.city_id || orderData.city_id
    })
    setActualAddress({...state});
    setEditModel(true);
  };

  const cancelEditAddress = () => {
    setState({...actualAddress});
    setEditModel(false);
  };

  const handlePaymentOption = (e) => {
    setPreferredPaymentOption(e.target.value);
  };

  const proceedCheckOut = async () => {
    setProcessCheckOut(true);
    const res = await dispatch(cartAction.checkOut(cartDetails, {}, null, orderData, preferredPaymentOption));

    if (res && res.data) {
      if(!res.data.isError) {
        await localStorage.setItem("cartItems", '[]');
        await dispatch(cartAction.getCartItems());
        props.history.push(`/orderConfirmation/${localStorage.getItem('orderId')}`, {
          prevPage: PAYMENT_PAGE
        })
      } else {
        NotificationManager.error(res.data.data);
      }

    }
    setProcessCheckOut(false);
  };

  return (
    <div className="payments-main">
      <div className="container">
        <h2>Payment</h2>
        <p style={{ color: "red" }}>{errorRes?.errorMsg ? errorRes.errorMsg : null}</p>
        <div className="signin-process">
          <ul>
            <li>
              <div className="check-round">
                <i className={"fas fa-check"} />
              </div>
              <p>Sign In</p>
            </li>
            <li>
              <div className="check-round">
                <i className={"fas fa-check"} />
              </div>
              <p>Shipping</p>
            </li>
            <li>
              <div className="check-round active"></div>
              <p className={"active"}>Billing</p>
            </li>
            <li>
              <div className="check-round"></div>
              <p>Confirmation</p>
            </li>
          </ul>
        </div>

        <div className="row">
          <div className="col-lg-8">
            <div className="box-contact-change">
              <div className="contact-orlando">
                <div className={"row-detail"}>
                  <span>Contact:</span>
                  <span>{orderData?.email}</span>
                </div>
                <div className={"d-flex div-btn"}>
                  <button className={"btn-sm red-btn-border"} onClick={prepareEditEmail}>Change</button>
                </div>
              </div>
              <div className="contact-orlando">
                <div className={"row-detail"}>
                  <span>Ship To:</span>
                  <span>{orderData?.address}, {orderData?.city}, {orderData?.state}, {orderData?.country} - {orderData?.zipCode}</span>
                </div>
                <div className={"d-flex div-btn"}>
                  <button className={"btn-sm red-btn-border"} onClick={prepareEditAddress}>Change</button>
                </div>
              </div>
            </div>

            <div className="payment-method">
              <h4>Payment</h4>
              {
                authData.userType !== 'Employee User'?
                    (<>
                      <div className={"pt-2 pb-2"}>
                        <img src={Visa} alt="visa"/>
                        <img src={Master} alt="master_card"/>
                        <img src={AmericanExpress} alt="american_express"/>
                      </div>
                      <p>All transactions are secure and encrypted.</p>
                      <div className="App">
                        <Elements stripe={promise}>
                          <CheckoutForm />
                        </Elements>
                      </div>
                    </>): (
                        <div className={"employee-payment-container"}>
                          <div className="col-md-12 pl-0">
                            <div className="form-group">
                              <div className="col-md-12 p-0 d-flex flex-column"
                                   onChange={(e) => handlePaymentOption(e)}
                              >
                                <label className="radio-inline mr-3" htmlFor="radios-0">
                                  <input
                                      type="radio"
                                      name="payment-option" id="radios-0" value="self" className={'mr-2'}
                                      checked={preferredPaymentOption === 'self'}
                                  />
                                  Use My Card
                                </label>
                                {
                                  preferredPaymentOption === 'self' &&
                                  <div className={"mb-3"}>
                                    <div className={"pt-2 pb-2"}>
                                      <img src={Visa} alt="visa"/>
                                      <img src={Master} alt="master_card"/>
                                      <img src={AmericanExpress} alt="american_express"/>
                                    </div>
                                    <p>All transactions are secure and encrypted.</p>
                                    <div className="App">
                                      <Elements stripe={promise}>
                                        <CheckoutForm preferredPaymentOption={preferredPaymentOption}/>
                                      </Elements>
                                    </div>
                                  </div>
                                }
                                <label className="radio-inline" htmlFor="radios-1">
                                  <input
                                      type="radio"
                                      name="payment-option" id="radios-1" value="organization" className={'mr-2'}
                                      checked={preferredPaymentOption === 'organization'}
                                  />
                                  Use Company Card
                                </label>
                                {
                                  preferredPaymentOption === 'organization' &&
                                  <>
                                    <div className={"mb-0"}>
                                      <p><i className={'fa fa-exclamation-triangle'}></i>
                                        The payment made for this order will be deducted from the card which is added by your company or organization.
                                        Please proceed with this option when you are sure that you are authorized to make payment from the company or organization card.
                                      </p>
                                    </div>
                                    <button
                                        id="submit"
                                        className="red-btn mt-0"
                                        style={{width: '32%', padding: '10px 12px'}}
                                        disabled={processCheckOut}
                                        onClick={proceedCheckOut}
                                    >
                                        <span id="button-text">{processCheckOut ? (
                                            "Processing..."
                                        ) : (
                                            "Proceed To Checkout"
                                        )}</span>
                                    </button>
                                  </>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                    )
              }

            </div>
          </div>

          <div className="col-lg-4">
            <OrderSummary></OrderSummary>
          </div>
        </div>
      </div>
      {editModel === true ? (
        <div
          className="modal"
          id="edit-inner"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Ship To</h4>
                <button
                  type="button"
                  class="close"
                  onClick={cancelEditAddress}
                >
                  {/*  data-dismiss="modal" */}
                  <span>
                    <i class="far fa-times-circle"></i>
                  </span>
                </button>
              </div>

              <div className="modal-body">
                <form className="change-form">
                  <div class="form-group">
                    <label for="address">Address </label>
                    <textarea
                      name="address"
                      value={state.address}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      type="text"
                      class="form-control"
                    ></textarea>
                    {error?.address[0] ? (
                      <p className="error-validation">{error?.address[0]}</p>
                    ) : null}
                  </div>

                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="email">Country</label>
                      <select
                          className="form-control"
                          value={state?.country_id}
                          onChange={(e) => countryChange(e)}>
                        <option value="" selected disabled>
                          Select Country
                        </option>
                        {
                          getCountriesList?.map((country, index) =>
                            <option key={index} value={country.id}>
                              {country.name}
                            </option>
                          )}
                      </select>
                      {error?.country[0] ? (
                          <p className="error-validation">{error?.country[0]}</p>
                      ) : null}
                    </div>

                    <div className="form-group col-md-6">
                      <label htmlFor="email">State</label>
                      <select
                          className="form-control"
                          value={state?.state_id}
                          onChange={(e) => stateChange(e)}>
                        <option value="" selected disabled>
                          Select State
                        </option>
                        {
                          getStateListByCountries?.map((state, index) =>
                              <option key={index} value={state.id}>
                                {state.state_name}
                              </option>
                          )}
                      </select>
                      {error?.state[0] ? (
                          <p className="error-validation">{error?.state[0]}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="email">City</label>
                      <select
                          value={state?.city_id}
                          className="form-control"
                          onChange={(e) => cityChange(e)}>
                        <option
                            value=""
                            selected
                            disabled>
                          Select City
                        </option>
                        {
                          getCitiesByState?.map((city, index) =>
                              <option key={index} value={city.id}>
                                {city.city}
                              </option>
                          )}
                      </select>
                      {error?.city[0] ? (
                          <p className="error-validation">{error?.city[0]}</p>
                      ) : null}
                    </div>
                    <div class="form-group col-md-6">
                      <label for="email">Zip Code</label>
                      <input
                          type="text"
                          value={state.zipCode}
                          class="form-control"
                          name="zipCode"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                      />
                      {error?.zipCode[0] ? (
                          <p className="error-validation zip-code">{error?.zipCode[0]}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="text-right">
                    <button
                      onClick={(e) => onSaveAddress(e)}
                      className="red-btn"
                    >
                      Save Changes
                    </button>
                    {/* data-dismiss="modal" aria-label="Close"  data-target="#edit-inner" */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {editEmail === true ? (
        <div
          className="modal"
          id="edit-inner"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Email</h4>
                <button
                  type="button"
                  class="close"
                  onClick={cancelEditEmail}
                >
                  {/*  data-dismiss="modal" */}
                  <span>
                    <i class="far fa-times-circle"></i>
                  </span>
                </button>
              </div>

              <div className="modal-body">
                <form className="change-form">
                  <div class="form-group">
                    <label for="email">Email Address </label>
                    <input
                      name="email"
                      value={state?.email}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      type="text"
                      class="form-control"
                    />
                    {error?.email[0] ? (
                      <p className="error-validation">{error?.email[0]}</p>
                    ) : null}
                  </div>

                  <div className="text-right">
                    <button
                      onClick={(e) => onSaveEmail(e)}
                      className="red-btn"
                    >
                      Save Changes
                    </button>
                    {/* data-dismiss="modal" aria-label="Close"  data-target="#edit-inner" */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default React.memo(Payments);
