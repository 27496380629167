import React, { Component } from "react";
import "../Settings/settings.scss";

class Settings extends Component {
  state = {};
  render() {
    return (
      <div className="settings-main">
        <div className="container">
          <h2>Account Settings</h2>
          <div className="hi-user">
            <h3>Hi! User</h3>
            <p>
              <span>
                <i class="far fa-edit"></i>
              </span>
              Edit
            </p>
          </div>

          <form>
            <div class="form-group">
              <label>Full Name</label>
              <input
                type="text"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label>Email Address</label>
              <input
                type="email"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label>Password</label>
              <input
                type="password"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label>Phone Number</label>
              <input
                type="number"
                class="form-control"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Settings;
