import React, { useEffect, useState } from "react";
import { productActions, cartAction } from "../../Redux/Actions/index";
import { useSelector, useDispatch } from "react-redux";

import { SUBSCRIPTION_PAYMENT, ONE_TIME_PAYMENT, subscriptionDuration } from '../../constant';
import "../ProductDetail/product-detail.scss";
import Test from "../../Images/test.png";
import Tv from "../../Images/tv-video.png";
import White from "../../Images/white.png";
import Bottle from "../../Images/bottle.png";
import Time from "../../Images/time.png";
import Strips from "../../Images/strips.png";
import Blood from "../../Images/blood.png";
import Hand from "../../Images/hand.png";
import Kit from "../../Images/kit-demo.jpg";
import Ques from "../../Images/icon-ques.png";
import Famous from "../../Images/famousVideo.mp4";
import $ from 'jquery'; // <-to import jquery

const ProductDetail = (props) => {
  let textLimit = 200;
  const dispatch = useDispatch();

  const { productLists: productList, checkQuantitySuccess } = useSelector((state) => state.productReducer);
  const authData = useSelector((state) => state.authReducer.authData);
  const commonData = useSelector((state) => state.commonReducer);
  const productDetails = useSelector((state) => state.productReducer.productDetails);
  const [quantity, setQuantity] = useState(1);
  const [isPayment, setPaymentMethod] = useState(ONE_TIME_PAYMENT);
  const [isSubscription, setSubscription] = useState(false)
  const [subcriptionDuration, setSubcriptionDuration] = useState(null)
  const [newItem, setNewItem] = useState({});

  useEffect(() => {
    (async () => {
      if (props?.match) {
        await dispatch(productActions.getProductDetail(props.match.params.id))

      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await dispatch(productActions.getCategory());
      if (props?.location?.state?.detail === false) {
        const state = { ...props.history.location };
        delete state.detail;
        props.history.replace({ ...props.history.location, state });
        console.log("props*", props);
      } else {
        await dispatch(productActions.getProductByCategory("all-tasks"));
      }
    })();
  }, [])

  const handleSelectPaymentMethod = async (e) => {
    const { value } = e.target
    if (value !== SUBSCRIPTION_PAYMENT) {
      setSubcriptionDuration(null)
      setSubscription(false)
      productDetails[`price`] = productDetails[`basePrice`]
    }
    setPaymentMethod(value)
  }

  const onclickAction = async (item) => {
    props.history.push(`/categories/${item.slug}`);
    await dispatch(productActions.getProductDetail(item.slug));
    dispatch({ type: "CLEAR_SEARCH_LIST" });
  };

  useEffect(() => {
    if (productDetails) {
      productDetails['basePrice'] = productDetails['price']
    }
  }, [productDetails])

  const handleQuantityChange = async (item, incmType) => {
    if (incmType === "plus") {
      if (quantity < item.quantity) {
        setQuantity(quantity + 1)
      }
    } else {
      if (quantity > 1)
        setQuantity(quantity - 1)
    }
  };
  const addToCart = async (item, quantity) => {
    setNewItem(Object.assign({}, item));
    await dispatch(productActions.checkValidQuantity(item.id, quantity));
  };

  useEffect(() => {
    if(checkQuantitySuccess) {
      dispatch(cartAction.addToCart(newItem, quantity, isSubscription, subcriptionDuration, priceId));
      $('#cart-inner').modal('show');
      dispatch(productActions.resetCheckQuantity());
    }
  }, [checkQuantitySuccess]);
  const [value, setValue] = useState(null)
  const [priceId, setPriceId] = useState(null)
  const handleSelect = async (e, priceId) => {
    const { name, value } = e.target;
    setPriceId(priceId)
    setSubcriptionDuration(name)
    setValue(value)
    if (isPayment === SUBSCRIPTION_PAYMENT) {
      productDetails['price'] = parseFloat((productDetails['basePrice'] * (1 - (e.target.value) / 100)).toFixed(2))
    }
  }

  const [tabChange, setTabChange] = useState('symptoms')
  const handlChangeTab = (e, key) => {
    setTabChange(key)
  };

  const renderSteps = (collection_details) => {
    if(!collection_details) {
      return (<>Sorry, the instruction is not available</>);
    }

    let arr = collection_details.replace(/(([0-9]\)))/g, '<br>$1');
    arr = arr.toString().replace(/(([a-zA-Z]\)))/g, '<br>$1');
    return arr.split('<br>').map((item, idx) => <span key={idx}>{item}<br/></span>)
  };

  const renderGetTested = (data) => {
    if(!data) {
      return (<>Sorry, no information available</>);
    }

    const arr = data.replace(/([0-9]\))/g, '<br>$1');
    return arr.split('<br>').map((item, idx) => item && <span key={idx}>{item}<br/></span>)
  };

  return (
    <div className="product-detail-main">
      <section className="product-detail-section">
        <div className="container">
            {
              authData.userType !== 'Employee User' && productDetails.couponCode &&
                <div className="coupon-code">
                  <span>{productDetails.couponDescription}</span>
                </div>
            }
          <div className="row">
            <div className="col-md-6 border-thick">
              <div className="testkit-main">
                <div className="testkit-img">
                  <img src={productDetails.image_name} alt="product-image" />
                </div>
                <p className={"text-justify"}>
                  {productDetails.description}
                </p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="testkit-main-desc">
                <div className="testkit-btn-group">
                  <h2>{productDetails.name}</h2>
                  <h2>${productDetails.price}</h2>
                </div>

                <div className="testkit-method">
                  <h3>Product Information</h3>
                  <h4>Measures</h4>
                  <p>
                    {productDetails?.measures ? productDetails.measures : null}
                  </p>
                  <h4>Collection Method</h4>
                  <p>{productDetails?.collection_method ? productDetails.collection_method : null}</p>
                  <div className="testkit-radio">
                    <label class="custom_radio">
                      One Time Purchase
                        <input type="radio" name={ONE_TIME_PAYMENT} onChange={(e) => {
                        handleSelectPaymentMethod(e);
                        setValue(eval(null));
                        setSubscription(false);
                      }} {...setPaymentMethod} value={ONE_TIME_PAYMENT} checked={isPayment === ONE_TIME_PAYMENT ? true : false} />
                      <span class="checkmark"></span>
                    </label>

                    {
                      authData.userType === 'Normal User' &&
                      <label className="custom_radio">
                        Subscribe and Save
                        <input type="radio" onChange={(e) => {
                          handleSelectPaymentMethod(e);
                          setSubscription(true)
                        }} {...setPaymentMethod} name={SUBSCRIPTION_PAYMENT} value={SUBSCRIPTION_PAYMENT}
                               checked={isPayment === SUBSCRIPTION_PAYMENT ? true : false}   {...setPaymentMethod} />
                        <span className="checkmark"></span>
                      </label>
                    }

                  </div>


                  {
                    isPayment === SUBSCRIPTION_PAYMENT ? <div className="subscribe-month">
                      {
                        productDetails?.price_id?.map((i, j) => {
                          return (
                            <label className="custom_radio" key={j}>
                              {i.subscriptionType}
                              <p className={"offer"}>{i.offer}</p>
                              <input type="radio" name={i?.subscriptionType} value={i.value} checked={i.value === value} onChange={(e) => handleSelect(e, i.price_id)} />
                              <span class="checkmark"></span>
                            </label>
                          )
                        })
                      }

                      {/* <label class="custom_radio">
                      Every 6 Months
                        <p>Save 20%</p>
                      <input type="radio" name={SIX_MONTHS_SUB} value={20} checked={20 === value} onChange={(e) => handleSelect(e)} />
                      <span class="checkmark"></span>
                    </label>
                    <label class="custom_radio">
                      Every 12 Months
                        <p>Save 15%</p>
                      <input type="radio" name={TWELVE_MONTHS_SUB} value={15} checked={15 === value} onChange={(e) => handleSelect(e)} />
                      <span class="checkmark"></span>
                    </label> */}
                    </div> : null}

                  <div className="quantity-btn">
                    <label>Quantity</label>
                    <div className="button-quantity">
                      <div className="quantity-btn-sub">
                        <button onClick={() => handleQuantityChange(productDetails, "minus")}>
                          <span>
                            <i class="fas fa-minus"></i>
                          </span>
                        </button>
                        <input type="number" placeholder={quantity} disabled readOnly/>
                        <button onClick={() => handleQuantityChange(productDetails, "plus")}>
                          <span>
                            <i class="fas fa-plus"></i>
                          </span>
                        </button>
                      </div>
                      <button className="red-btn-border" onClick={() => addToCart(productDetails, quantity, isSubscription)} >
                        <span>
                          <i class="fas fa-cart-plus"></i> &nbsp;
                        </span>
                          Add to cart
                          <span className={commonData.loading === true ? "spinner-border" : ""}></span>
                        </button>

                      {/* <button onClick={() => this.addToCart(this.props.product, this.state.quantity)} type="button">{this.state.isAddedToCart ? <i class="far fa-check-circle"></i> : null} <span>Add To Cart</span></button>                         */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="product-tabination-lorem">
        <div className="container">
          <div className="tabination-ul-ip">
            <ul>
              <li className={tabChange === 'symptoms' ? "active" : null} onClick={(e) => handlChangeTab(e, "symptoms")}> Symptoms</li>
              <li className={tabChange === 'get_tested' ? "active" : null} onClick={(e) => handlChangeTab(e, "get_tested")}>Why Get Tested?</li>
              <li className={tabChange === 'collection_method' ? "active" : null} onClick={(e) => handlChangeTab(e, "collection_method")}>Collection Method</li>
            </ul>

            <div className="product-desc-tab">
              {
                tabChange === 'get_tested' ? (
                    renderGetTested(productDetails?.get_tested_details)
                ) : (
                    <ul>
                      {
                        tabChange === 'symptoms' && productDetails?.symptoms?.map((symp, index) => {
                          return (
                              <li key={index}>
                                {symp.label}
                              </li>
                          )
                        })
                      }
                      {
                        tabChange === 'collection_method' && productDetails?.collection_method
                      }
                    </ul>
                )
              }
              </div>
          </div>
        </div>
      </section>

      <section className="video-how-take">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="video-how-desc">
                <div className="video-how-img">
                  {
                    productDetails.videoURL?
                    <div>
                      <iframe className="responsive-iframe"
                              allowFullScreen="allowfullscreen"
                              mozallowfullscreen="mozallowfullscreen"
                              msallowfullscreen="msallowfullscreen"
                              oallowfullscreen="oallowfullscreen"
                              webkitallowfullscreen="webkitallowfullscreen"
                              src={productDetails.videoURL} />
                    </div>
                       :
                        <div>
                          <img src={Tv} alt="img" />
                        </div>
                  }
                </div>
                <div className={productDetails.videoURL && 'pt-5'}>
                  <h4>How to Collect your sample</h4>
                </div>
                <p className={"text-left"} style={{width:"100%"}}>
                  {
                    renderSteps(productDetails?.collection_details)
                  }
                </p>
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className="how-test-video">
                <video controls loop>
                  <source src={Famous} type="video/mp4" />
                </video>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section className="works-main">
        <div className="container">
          <div className="common-h2">
            <h2 className="heart-rate-h2">How it Works</h2>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="works-desc">
                <div className="works-img">
                  <img src={Bottle} alt="img" />
                </div>
                {/* <h3>Test Kit</h3> */}
                <p>Once you complete checkout, the test kit is sent to your home discreetly</p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="works-desc">
                <div className="works-img">
                  <img src={Hand} alt="img" />
                </div>
                {/* <h3>Collect Blood Sample</h3> */}
                <p>Following package instructions on how to collect a sample</p>
              </div>
            </div>

            {/* <div className="col-lg-4">
              <div className="works-desc">
                <div className="works-img">
                  <img src={Blood} alt="img" />
                </div>
                <h3>Transffer</h3>
                <p>Lorem ipsum dolor sit amet, consetetur</p>
              </div>
            </div> */}

            <div className="col-lg-6">
              <div className="works-desc">
                <div className="works-img">
                  <img src={White} alt="img" />
                </div>
                {/* <h3>Place 2-3 Drops of buffer</h3> */}
                <p>Receive your results</p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="works-desc">
                <div className="works-img">
                  <img src={Time} alt="img" />
                </div>
                {/* <h3>Start</h3> */}
                <p>If needed, we offer medical services to help with your next steps</p>
              </div>
            </div>

            {/* <div className="col-lg-4">
              <div className="works-desc">
                <div className="works-img">
                  <img src={Strips} alt="img" />
                </div>
                <h3>Read Result</h3>
                <p>Lorem ipsum dolor sit amet, consetetur</p>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section className="products-main">
        <div className="container">
          <div className="test-btn-h2">
            <h2>Popular Tests</h2>
            <button
              className="white-btn-border"
              onClick={() => {
                props.history.push("/categories");
              }}
            >
              View All
            </button>
          </div>
        </div>
      </section>

      <section className="products-upper">
        <div className="container">
          <div className="products-group">
            <div className="row">
              {commonData.loading === false ? (
                productList?.length > 0 ? (
                  productList?.map((data, index) => {
                    if (index <= 3) {
                      return (
                        <div className="col-md-4" key={index}>
                          <div className="products-card">
                            <div
                              className="price-tag"
                              onClick={() => addToCart(data, 1, isSubscription)}
                            >
                              <p>
                                <i class="fas fa-cart-plus"></i>
                              </p>
                            </div>
                            <div className="products-img">
                              <img src={data?.image_name} />
                            </div>
                            <div className="products-card-text">
                              <h4>{data.name}</h4>
                              <p>
                                {data?.description?.length > textLimit
                                  ? `${data.description.slice(
                                      0,
                                      textLimit
                                    )}....see more`
                                  : data.description.slice(0, textLimit)}
                              </p>
                              {/* <p>{data.description?.slice(0, this.textLimit)}</p> */}
                              <p className="price-test">${data.price}</p>
                              <div className="text-center button-fixed">
                                <button
                                  onClick={() => onclickAction(data)}
                                  className="red-btn-border"
                                >
                                  Learn More
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <p
                    style={{
                      fontWeight: 600,
                      textAlign: "center",
                      width: "100%",
                      margin: "40px",
                    }}
                  >
                    No Items Added..!!
                  </p>
                )
              ) : (
                <p
                  style={{
                    fontWeight: 500,
                    textAlign: "center",
                    width: "100%",
                    margin: "40px",
                  }}
                >
                  LOADING...
                </p>
              )}
              {/* <div className="col-md-4">
        <div className="products-card">
          <div className="price-tag">
            <p><i class="fas fa-cart-plus"></i></p>
          </div>
          <div className="products-img">
            <img src={Test} />
          </div>
          <div className="products-card-text">
            <h4>Food Sensitivity Test</h4>
            <p>Test your sensitivity to 96 commonly found foods.</p>
            <p className="price-test">$00.00</p>
            <div className="text-center">
            <button className="red-btn-border">Learn More</button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-4">
      <div className="products-card">
          <div className="price-tag">
            <p><i class="fas fa-cart-plus"></i></p>
          </div>
          <div className="products-img">
            <img src={Test} />
          </div>
          <div className="products-card-text">
            <h4>Food Sensitivity Test</h4>
            <p>Test your sensitivity to 96 commonly found foods.</p>
            <p className="price-test">$00.00</p>
            <div className="text-center">
            <button className="red-btn-border">Learn More</button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-4">
      <div className="products-card">
          <div className="price-tag">
            <p><i class="fas fa-cart-plus"></i></p>
          </div>
          <div className="products-img">
            <img src={Test} />
          </div>
          <div className="products-card-text">
            <h4>Food Sensitivity Test</h4>
            <p>Test your sensitivity to 96 commonly found foods.</p>
            <p className="price-test">$00.00</p>
            <div className="text-center">
            <button className="red-btn-border">Learn More</button>
            </div>
          </div>
        </div>
      </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ProductDetail;
