import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {NotificationManager} from 'react-notifications';
import moment from 'moment';

import "./covid.scss";
import { covidAction } from "../../Redux/Actions";

const CovidConfirmation = (props) => {
    const dispatch = useDispatch();
    const covidReducer = useSelector((state) => state.covidReducer);
    const testType = covidReducer.searchLocationDetail.testType;

    useEffect(() => {
        (async () => {
            if(!covidReducer.searchLocation) {
                props.history.push('/covid-19-testing');
                return
            }
        })();
    }, []);


    useEffect(() => {
        if(covidReducer.error) {
            NotificationManager.error('Something went wrong. Please try again.');
            dispatch(covidAction.resetError());
        }
    }, [covidReducer.error]);

    const onHomePage = () => {
        return props.history.push('/covid-19-testing');
    };


    const appointmentDateTime = () => {
        const appointmentDate = covidReducer.appointmentDetail?.date;
        if(!appointmentDate) {
            return '';
        }
        return moment(appointmentDate).format('dddd MMMM DD YYYY');
    };

    const getAppointmentStartTime = () => {
        const startTime = covidReducer.appointmentDetail?.start;
        if(!startTime) {
            return '';
        }
        return startTime;
    };

    const getAppointmentLocationName = () => {
        const name = covidReducer.searchLocationDetail?.name;
        if(!name) {
            return '';
        }
        return name;
    };

    const getAppointmentLocationAddress = () => {
        const address = `${covidReducer.searchLocationDetail?.street}, ${covidReducer.searchLocationDetail?.city}, 
        ${covidReducer.searchLocationDetail?.state} - ${covidReducer.searchLocationDetail?.zipCode}`;
        if(!address) {
            return '';
        }
        return address;
    };

    return (
        <div id="confirmation-container">
            <div id="confirmation-row" className="row justify-content-center">
                <div className={"confirmation-heading"}>
                    <div className={"alert alert-success"}>
                        <p><i className={"fa fa-check"}></i> &nbsp; Your appointment has been scheduled successfully. We have also send the confirmation email
                            to your email address.
                        </p>
                    </div>
                </div>
            </div>
            <div className={"section"}>
                <div className={'location-container'}>
                    <div className={"alert alert-info mt-2"}>
                        <span className={"fa fa-circle"}> &nbsp; Test type: <b>COVID-19 {testType} Test</b></span>
                    </div>
                    <div className={"additional-info"}>
                        <p><i className="fa fa-plane" aria-hidden="true"></i> &nbsp;Accepted for most travel</p>
                        <p><i className="fa fa-car" aria-hidden="true"></i> &nbsp;Drive-thru test (vehicle recommended)</p>
                        <p><i className="fa fa-file" aria-hidden="true"></i> &nbsp; Results within 1-2 days</p>
                    </div>

                    <hr/>

                    <div className={"visit-information"}>
                        <h4>Visit Information</h4>
                        <div className={'d-flex flex-column'}>
                            <span>{appointmentDateTime()} at {getAppointmentStartTime()}</span>
                            <span>{getAppointmentLocationName()}</span>
                            <span>{getAppointmentLocationAddress()}</span>
                        </div>
                    </div>

                    <div className={"confirmation-code"}>
                        <h4>Your confirmation code</h4>
                        <p className={"info-text"}>You need this confirmation code for your appointment verification.</p>
                        <div className={"code"}>
                            <span>{covidReducer.confirmationCode}</span>
                        </div>
                    </div>

                    <div className={"alert alert-secondary mt-4"}>
                        <span className={"fa fa-info-circle"}> &nbsp;
                            Test cancellation is non-refundable.
                        </span>
                    </div>
                </div>
            </div>

            <div className="form-group mt-4">
                <button type="button" className="btn red-btn-border btn-md"
                        onClick={onHomePage}
                > Schedule another appointment
                </button>
            </div>
        </div>
    )
};

export default CovidConfirmation;
