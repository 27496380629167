import {NotificationManager} from 'react-notifications';

export const initialState = {
  productLists: [],
  searchList: [],
  productDetails: [],
  categoryId: "all-tasks",
  timeStamp: null,
  subscriptionList:[],
  category: [],
  checkQuantityMessage: null,
  checkQuantitySuccess: false
};

export const productReducer = (state = initialState, action) => {

  switch (action.type) {
    case "PRODUCTS_BY_CATEGORIES":
      // console.log("NXRPTOPS",action.)
      return {
        ...state,
        productLists: action.payload.data,
        // productLists: [...action.payload.data],
        timeStamp: action.timeStamp
      };

    case "PRODUCT_DETAIL":
      return {
        ...state,
        productDetails: action.payload.data,
      };
      case "CATEGORIES":
      return {
        ...state,
        category: action.payload.data,
      };
    case "SEARCH_LIST":
      return {
        ...state,
        searchList: action.payload.data
      };
    case "CLEAR_SEARCH_LIST":
      return {
        ...state,
        searchList: [],
      };
    case "CATEGORY_ID":
      return {
        ...state,
        categoryId: action.payload,
      };
    case "CLEAR_PRODUCT_LIST":

      return {
        ...state,
        productLists: [],
      };

      case 'SUBSCRIPTION_LIST':
        return {
          ...state,
          subscriptionList: action.payload.data
        }
      case 'CHECK_VALID_QUANTITY_COMPLETE':
        const success = action.payload.data.success;
        const message = action.payload.data.data;

        if(!success) {
          NotificationManager.error(message);
        }
        return {
          ...state,
          checkQuantityMessage: message,
          checkQuantitySuccess: success
        };
      case 'RESET_CHECK_QUANTITY':
        return {
          ...state,
          checkQuantityMessage: null,
          checkQuantitySuccess: false
        };

    default:
      return state;
  }
};
