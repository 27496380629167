export const initialState = {
  userData: {},
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_DATA_BY_ID":
      return {
        ...state,
        userData: action.payload.data
      };
    case "USER_UPDATE_PROFILE":
      return {
        ...state,
        profileUpdatedData: action.payload.data
      };

    case "GET_COUNTRIES_LIST":
      return {
        ...state,
        getCountriesList: action.payload.data || []
      }

    case "GET_STATE_LIST_BY_COUNTRY":
      return {
        ...state,
        getStateListByCountries: action.payload.data || []
      }

    case "GET_CITIES_BY_STATE":
      return {
        ...state,
        getCitiesByState: action.payload.data || []
      }

    case "RESET_PROFILE_MESSAGE":
      return {
        ...state,
        profileUpdatedData: {
          ...state.profileUpdatedData,
          message: null
        }
      }


    default:
      return state;
  }
};
