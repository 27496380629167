import React, { useState, useEffect } from "react";
import { orderAction } from "../../Redux/Actions/index";
import { useDispatch, useSelector } from "react-redux";

import "../Orders/orders.scss";
// import Test from "../../Images/test.png";
import Stripe from "../../Images/stripe-icon.png";

const TrackOrders = (props) => {
  let dispatch = useDispatch();
  // const orderList = useSelector((state) => state.orderReducer.orderList);
  const orderDetails = useSelector((state) => state.orderReducer.orderDetails);
  const orderTrackStatusRecord = useSelector((state) => state.orderReducer.getAllTracks)?.map((i) => i.elements[0].text)
  const [address, setAddress] = useState({});

  // useEffect(() => {
  //   (async () => {
  //     await dispatch(orderAction.getTrackingData(props?.match?.params?.orderId))
  //   })();
  // }, []);

  useEffect(() => {
    let addressNew = orderDetails?.address;
    if (addressNew) {
      setAddress(JSON.parse(addressNew));
    }
  }, [orderDetails?.address]);

  let stage4 = orderTrackStatusRecord?.find((a) =>
    a.includes("delivered")
  );
  let stage3 = orderTrackStatusRecord?.find(
    (a) => a.includes("In Transit") || a.includes("Out for Delivery")
  );
  let stage2 = orderTrackStatusRecord?.find((a) =>
    a.includes("Accepted")
  );
  return (
    <div className="orders-main">
      <div className="container">
        <h2>Track Order</h2>
        <div className="details-order-invoice">
          <div class="track-order-box">
            <ul>
              <li class="active">
                <div class="track-order-icon">
                  <i class="fas fa-shopping-basket"></i>
                </div>
                <p>order received</p>
              </li>
              <li className={stage2 ? 'active' : null}>
                <div class="track-order-icon">
                  <i class="fas fa-box"></i>
                </div>
                <p>pick up</p>
              </li>
              <li className={stage3 ? 'active' : null}>
                <div class="track-order-icon">
                  <i class="fas fa-truck"></i>
                </div>
                <p>in transit</p>
              </li>

              <li className={stage4 ? 'active' : null}>
                <div class="track-order-icon">
                  <i class="fas fa-check-circle"></i>
                </div>
                <p>delivered</p>
              </li>
            </ul>
          </div>
          <div className="invoice-details">
            <h4>Order Details</h4>
            <p>Order Number: {orderDetails?.orderNumber}</p>
            <p>Ordered by 20 July 2020</p>
            <p>
              <b>Arriving 25 July 2020 by 9 PM</b>
            </p>
          </div>

        </div>

        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Order status</th>

              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ul className="track-order-loc">
                    {
                      orderTrackStatusRecord?.map((i) => {
                        return (

                          <li>{i}</li>
                        )
                      })
                    }
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TrackOrders;
