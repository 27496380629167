import React, { useState, useCallback, useEffect } from "react";
import {productActions, cartAction, authActions} from "../../Redux/Actions/index";
import { Link } from 'react-router-dom';
import SignupForm from "../../Modules/signup";
import LoginForm from "../../Modules/login";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from 'react-router';

import Logo from "../../Images/logo.png";
import "../Header/header.scss";
import {NotificationManager} from "react-notifications";
import {resetError} from "../../Redux/Actions/commonAction";
var clearTimeoutHolder = null;

const Header = (props) => {

  const authData = useSelector((state) => state.authReducer.authData);
  const searchList = useSelector((state) => state.productReducer.searchList);
  const errorRes = useSelector((state) => state.commonReducer);
  const authMessage = useSelector((state) => state.authReducer.message);
  const cartDetails = useSelector((state) => state.cartReducer.cartItems);
  const cartReducer = useSelector((state) => state.cartReducer);
  const [searchText, setSearchText] = useState("");
  const [isSignUp, setSignUp] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const [loader, showLoader] = useState(false);
  const [subTotals, setSubTotal] = useState(0.00);
  const dispatch = useDispatch();


  useEffect(() => {
    (async () => {

      await dispatch(cartAction.getCartItems());

    })();
  }, []);


  useEffect(() => {
    if(cartDetails?.length !== 0 || cartDetails !== null ){
      dispatch(cartAction.calculateSubTotal(cartDetails));
    }
  }, [cartDetails]);

  useEffect(() => {
    dispatch(authActions.resetSuccess());
  }, [authMessage]);

  useEffect(() => {
    dispatch(resetError());
  }, [errorRes?.errorMsg]);

  const namLetters = () => {
    var name = authData.full_name;
    var initials = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || "") + (initials.pop() || "")
    ).toUpperCase();
    return initials;
  };

  const handleInputChange = async (e) => {
    e.preventDefault();
    let searchKeyword = e.target.value;
    setSearchText(searchKeyword);

    showLoader(true);
    if (searchKeyword.length > 0) {
      dispatch(productActions.selectCategoryPage("all-tasks"));
      if (clearTimeoutHolder !== null) {
        clearTimeout(clearTimeoutHolder)
      }
      clearTimeoutHolder = setTimeout(async () => {
        await dispatch(productActions.searchProduct(searchKeyword));
      }, 1000)
    } else {
      showLoader(false);
      setSearchText("");
      clearTimeoutHolder = setTimeout(async () => {
        dispatch({ type: "CLEAR_SEARCH_LIST" });
        await dispatch(productActions.getProductByCategory("all-tasks"))
      }, 1100)
    }
  };

  const onclickSearchButton = async (e) => {
    e.preventDefault();
    await dispatch({ type: "CLEAR_SEARCH_LIST" });
    props.history.push({
      pathname: '/categories',
      state: { detail: false }
    })
  };

  const onclickAction = async (item) => {
    setSearchText("");
    await dispatch(productActions.getProductDetail(item.slug));
    dispatch({ type: "CLEAR_SEARCH_LIST" });
  };

  return (
    <div className="header-main">
      <div className="header-sub-main">
        <div className="container-fluid">
          <div className="header-sub-desc">
            <div className="p-group">
              <p>Welcome to Accugenedx.</p>
              <p>
                We provide <b>at-home</b> tests with accurate results.
              </p>
            </div>
            <div className="search-main form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search.."
                value={searchText}
                onChange={(e) => handleInputChange(e)}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    dispatch({ type: "CLEAR_SEARCH_LIST" });
                    props.history.push({
                      pathname: '/categories',
                      state: { detail: false }
                    })
                  }
                }}
              ></input>
              <button onClick={(e) => onclickSearchButton(e)}>
                <span>
                  <i className="fas fa-search"></i>
                </span>
              </button>

              {searchList.length > 0 ? (
                searchList.map((item) => {
                  return (<div onClick={() => onclickAction(item)} className="search-filter-item" key={item.id}>
                    <Link to={`/categories/${item.slug}`}>
                      <div className="search-filter-item-detail">
                        <h3>{item.name}</h3>
                        <p>{item.description.slice(0, 60)} </p>
                      </div>
                    </Link>
                  </div>
                  )
                })
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <header className="header">
        <div className="container-fluid pl-1">
          <nav className="navbar navbar-expand-md p-0">
            <div className="main-logo">
              <Link className="navbar-brand" to="/">
                <img src={Logo} />
              </Link>
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
              id="hamburger"
            >
              <span className="navbar-toggler-icon"><i className="fas fa-bars"></i></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/support/help">
                    Help <i className={"fas fa-question-circle"} style={{'color': '#800020'}}></i>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/categories">
                    Shop Tests
                  </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link" to="/register-kit">
                    Register Kit
                    </Link>
                </li>
                {authData && authData.id ? (
                  <li className="nav-item">
                    <div className="dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <div className="login-user">
                          <span>{namLetters()}</span>
                        </div>
                      </a>
                      <div className="dropdown-menu">
                        <Link to="/profile" className="dropdown-item" >
                          <span>
                            <i className="fas fa-cog"></i>
                          </span>
                          My Profile
                        </Link>
                        <Link className="dropdown-item" to="/orders">
                          <span>
                            <i className="fas fa-cart-plus"></i>
                          </span>
                          My Orders
                        </Link>
                        {
                          authData.userType === 'Normal User' &&
                          <Link className="dropdown-item" to="/subscription">
                            <span>
                              <i className="far fa-envelope"></i>
                            </span>
                            Subscriptions
                          </Link>
                        }
                        {
                          authData.isOrganisationUser === 1 &&
                          (
                              <>
                              <Link className="dropdown-item" to="/employees">
                                <span>
                                  <i className="fa fa-users"></i>
                                </span>
                                My Employees
                              </Link>
                              <Link className="dropdown-item" to="/employees-history">
                                <span>
                                  <i className="fa fa-history"></i>
                                </span>
                                History
                              </Link>
                              </>
                          )
                        }

                        <Link className="dropdown-item" to='/reports'>
                          <span>
                            <i className="fas fa-tasks"></i>
                          </span>
                          Test Reports
                        </Link>
                        <Link className="dropdown-item" to='/support'>
                          <span>
                            <i className="fas fa-headset"></i>
                          </span>
                         Support
                        </Link>
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            dispatch({ type: "LOGOUT_USER" });
                            props.history.push("/signin");
                          }}
                        >
                          <span>
                            <i className="fas fa-sign-out-alt"></i>
                          </span>
                          Logout
                        </a>
                      </div>
                    </div>
                    <p className="user-name-unique">{authData?.full_name}</p>
                  </li>
                ) : null}

                {/* please don't remove comment it's very imp */}
                {authData && authData.id ? null : (
                  <li className="nav-item">
                    <div className="logo-plus-link"
                      data-toggle="modal"
                      onClick={() => { setLogin(true) }}
                    // data-target="#signin-inner"
                    >

                      <p className="logo-p-change">
                        <span>
                          <i className="fas fa-sign-out-alt"></i>
                        </span>
                      </p>
                      <a className="nav-link" href="#">
                        <b>Login</b>
                      </a>
                    </div>
                  </li>
                )}

                <li className="nav-item">
                  <div className="logo-plus-link"
                    data-toggle="modal"
                    data-target="#cart-inner"
                  >
                    <p className="logo-p-change">
                      <span>
                        <i className="fas fa-cart-plus"></i>
                      </span>
                    </p>
                    <a className="nav-link" href="#" onClick={() => dispatch(cartAction.getCartItems())}>
                      <b>Shopping Cart</b>
                      <p className={"card-details"}>{cartDetails?.length > 0 ? cartDetails?.length : 0} Item - ${cartReducer?.total ? cartReducer?.total : 0}</p>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>

      {isLogin === true ? <div className="modal" id="signin-inner" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Sign In</h4>
              {
                authMessage ?
                    NotificationManager.success(authMessage) : errorRes?.errorMsg ?
                    NotificationManager.error(errorRes?.errorMsg)
                    : null
              }
              <button type="button" className="close" onClick={() => setLogin(false)} >
                <span>
                  <i className="far fa-times-circle"></i>
                </span>
              </button>
            </div>

            <div className="modal-body">
              <LoginForm setLogin={setLogin} />
              <div className="login-desc-modal">
                <span>
                  <i className="fas fa-user"></i>
                </span>
                <h3>No Account yet?</h3>
                <p>
                  Registering for this site allows you to access your order
                  status and history. Just fill in the fields below, and we’ll
                  get a new account set up for you in no time. We will only
                  ask you for information necessary to make the purchase
                  process faster and easier.
                  </p>
                <a className={"create-account"} data-toggle="modal" onClick={() => { setLogin(false); setSignUp(true);dispatch({type: 'CLEAN_ERROR'});
                dispatch({ type: "CLEAR_LOGIN" }); }}>
                  Create an Account
                  </a>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
        : null}
      {isSignUp === true ? <div className="modal" id="signup-inner" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">New Registration</h4>
              {
                authMessage ?
                    NotificationManager.success(authMessage) :
                    errorRes?.errorMsg ?
                        NotificationManager.error(errorRes?.errorMsg)
                    : null
              }
              <button type="button" className="close" onClick={() => setSignUp(false)}>
                <span>
                  <i className="far fa-times-circle"></i>
                </span>
              </button>
            </div>

            <div className="modal-body">
              <SignupForm setSignUp={setSignUp} setLogin={setLogin} />
              <div className="login-desc-modal">
                <span>
                  <i className="fas fa-user"></i>
                </span>
                <h3>Already have an account?</h3>
                <p>
                  You can skip the registration process and login to your account.
                  </p>
                <a className={"login"} data-toggle="modal" onClick={() => { setSignUp(false); setLogin(true);dispatch({type: 'CLEAN_ERROR'});
                dispatch({ type: "CLEAR_LOGIN" }); }}>
                  Login
                  </a>
              </div>
            </div>
          </div>
        </div>
      </div> : null}
    </div>
  );
};

export default withRouter(Header);
