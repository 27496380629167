import React, {useEffect, useState} from "react";
import {employeeAction, userActions} from "../../Redux/Actions/index";
import { useDispatch, useSelector } from "react-redux";
import "../Employees/employees.scss";
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';
import {NotificationManager} from "react-notifications";

const AddEmployee = (props) => {
    const initEmployee = {
        fullName: '',
        email: '',
        phone: '',
        address: '',
        state_id: 0,
        city_id: 0,
        country_id: 0,
        zipCode: '',
        isActive: '',
        isSaving: false
    };
    let dispatch = useDispatch();
    const authData = useSelector((state) => state.authReducer.authData);
    const [employee, setEmployee] = useState(initEmployee);
    const [error, setError] = useState({
        fullName: [],
        email: []
    });
    const [phoneInput, setPhoneInput] = useState('');

    useEffect(() => {
        (async () => {
            if(authData.isOrganisationUser === 0) {
                return;
            }
            setEmployee(prev => ({...prev, isSaving: true}));
            await dispatch(userActions.getCountries());
            let employeeObj = {};
            renderPhoneInput(employeeObj.phone || null);
            setEmployee(prev => ({...prev, ...employeeObj, isSaving: false}));
        })();
    }, []);

    useEffect(() => {
        if(authData.isOrganisationUser === 0) {
            NotificationManager.error('You do not have permission to access this page');
            props.history.push('/');
            return;
        }
    }, [authData.isOrganisationUser]);

    const { getCountriesList, getStateListByCountries, getCitiesByState } = useSelector((state) => state.userReducer);

    function handlePhoneNumberChange(value) {
        setEmployee((prevState) => ({ ...prevState, phone : value }));
    }

    const renderPhoneInput = (userPhone) => {
        const phnInput = <PhoneInput
            defaultCountry="US"
            countries={["US"]}
            addInternationalOption={false}
            class="form-control"
            placeholder="(xxx) xxx-xxxx"
            value={userPhone? formatPhoneNumber(userPhone): employee.phone}
            limitMaxLength={true}
            onChange={handlePhoneNumberChange}/>;

        setPhoneInput(phnInput);
    };

    function handleChange(event) {
        const { name, value } = event.target;
        if(name === 'zipCode') {
            const regEx = /^\b\d{0,5}\b$/;
            if (value === '' || regEx.test(value)) {
                setEmployee((prevState) => ({ ...prevState, [name]: value }));
            }
            return;
        } else {
            setEmployee((prevState) => ({ ...prevState, [name]: value }));
        }
    }

    const countryChange = async (e) => {
        e.preventDefault()
        let selectedCountry = getCountriesList.find((state) => state.id === (parseInt(e.target.value)))
        setEmployee((prevState) => ({
            ...prevState,
            country: selectedCountry.name,
            country_id: selectedCountry.id,
            state: '',
            state_id: 0
        }));
        await dispatch(userActions.getStates(selectedCountry.id))
    };

    const stateChange = async (e) => {
        e.preventDefault()
        let selectedState = getStateListByCountries.find((state) => state.id === (parseInt(e.target.value)))
        setEmployee((prevState) => ({
            ...prevState,
            state: selectedState.state_name,
            state_id: parseInt(selectedState.id),
            city: '',
            city_id: 0
        }));
        await dispatch(userActions.getCities(parseInt(selectedState.id)))
    };

    const cityChange = (e) => {
        let selectedCity = getCitiesByState.find((city) => city.id === (parseInt(e.target.value)))
        setEmployee((prevState) => ({
            ...prevState,
            city: selectedCity.city,
            city_id: selectedCity.id,
        }));
    };

    const validateEmployee = (state) => {
        let isFormValid = true;
        let error = {
            fullName:[],
            email: [],
        };

        if (!employee.fullName || employee.fullName?.trim().length === 0) {
            isFormValid = false;
            error.fullName = ["Full Name cannot be empty!"];
        }
        if (!employee?.email || employee?.email?.trim().length === 0) {
            isFormValid = false;
            error.email = ["Email cannot be empty!"];
        }
        if (employee.email.trim().length > 0 &&
            /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
                employee.email
            ) === false
        ) {
            isFormValid = false;
            error.email = ["Email must be a valid email address"];
        }
        return {
            isFormValid,
            error
        }
    };

    async function onSaveEmployee(event) {
        event.preventDefault();

        const results = await validateEmployee();
        let err = results.error;
        if (results.isFormValid === false) {
            setError(err)
        }
        if (results?.isFormValid) {
            err = {
                fullName: [],
                email: [],
            };
            setError(err);
            setEmployee(prev => ({...prev, isSaving: true}));
            const payload = {
                fullName: employee.fullName,
                email: employee.email,
                phone: employee.phone,
                address: employee.address,
                country: employee.country,
                state: employee.state,
                city: employee.city,
                zipCode: employee.zipCode,
                country_id: employee.country_id,
                state_id: employee.state_id,
                city_id: employee.city_id,
            };
            // create
            let changeStatus = await dispatch(employeeAction.createEmployee(payload));
            if(!changeStatus.isSuccess) {
                NotificationManager.error(changeStatus.errorMessage);
                setEmployee(prev => ({
                    ...prev, isSaving: false
                }));
                return;
            }
            NotificationManager.success(changeStatus.message);
            setEmployee(prev => ({
                ...prev, isSaving: false, ...initEmployee
            }));


        }
    }

    return (
        <div className="add-employees-main">
            <div className="container">
                <div className={"col-md-10"}>
                    <form>
                        <div className="form-group">
                            <label>Full Name</label>
                            <input
                                type="text"
                                className="form-control"
                                name="fullName"
                                value={employee.fullName}
                                onChange={(e) => handleChange(e)}
                            />
                            {error?.fullName[0] ? (
                                <p className="error-validation">{error?.fullName[0]}</p>
                            ) : null}
                        </div>

                        <div className="form-group">
                            <label>Email Address</label>
                            <input
                                type="email"
                                className="form-control"
                                name="email"
                                value={employee.email}
                                onChange={(e) => handleChange(e)}
                            />
                            {error?.email[0] ? (
                                <p className="error-validation">{error?.email[0]}</p>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <label>Phone Number</label>
                            {phoneInput}
                        </div>

                        <div className="form-group">
                            <label>Address</label>
                            <input
                                type="text"
                                className="form-control"
                                name="address"
                                value={employee.address}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                       <div className={"d-flex flex-row justify-content-between"}>
                           <div className="form-group">
                               <label>Country</label>
                               <select
                                   className="form-control"
                                   onChange={(e) => countryChange(e)}
                                   defaultValue={""}
                                   value={employee.country_id}
                               >
                                   <option value="0" disabled>
                                       Select Country
                                   </option>
                                   {
                                       getCountriesList?.map((country, index) =>
                                           <option key={index}
                                                   value={country.id} >
                                               {country.name}
                                           </option>
                                       )}
                               </select>
                           </div>
                           <div className="form-group">
                               <label>State</label>
                               <select
                                   className="form-control"
                                   value={employee.state_id}
                                   onChange={(e) => stateChange(e)}
                                   defaultValue={""}
                               >
                                   <option value="0" disabled>
                                       Select State
                                   </option>
                                   {
                                       getStateListByCountries?.map((state, index) =>
                                           <option key={index}
                                                   value={state.id}>
                                               {state.state_name}
                                           </option>
                                       )}
                               </select>
                           </div>
                           <div className="form-group">
                               <label>City</label>
                               <select
                                   className="form-control"
                                   value={employee.city_id}
                                   onChange={(e) => cityChange(e)}
                                   defaultValue={""}
                               >
                                   <option value="0" disabled>
                                       Select City
                                   </option>
                                   {
                                       getCitiesByState?.map((city, index) =>
                                           <option key={index}
                                                   value={city.id}>
                                               {city.city}
                                           </option>
                                       )}
                               </select>
                           </div>
                           <div className="form-group">
                               <label>Zip Code</label>
                               <input
                                   type="text"
                                   className="form-control"
                                   value={employee.zipCode}
                                   name="zipCode"
                                   onChange={(e) => {
                                       handleChange(e);
                                   }}
                               />
                           </div>
                       </div>
                        <div className={'d-flex flex-row justify-content-between mb-3'}>
                            <button
                                className="red-btn-border"
                                onClick={() =>  props.history.push('/employees')}
                            >
                                <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
                            </button>
                            <button
                                onClick={(e) => onSaveEmployee(e)}
                                className="red-btn"
                            >
                                Add
                                <span
                                    className={employee.isSaving && "spinner-border"}
                                ></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddEmployee;
