import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions, authActions, cartAction } from "../../Redux/Actions/index";
import OrderSummary from "../../Modules/orderSummaryCard";
import { useHistory } from 'react-router-dom'
import CryptoJS from "react-native-crypto-js";
import Coupon from "../../Images/discount.png";
import "../Shipping/shipping.scss";
import {
  validateEditProfileForm,
  validateAddressEdit,
  validateChangePasswordForm,
} from "../../Validation";
import { SHIPPING_PAGE, PAYMENT_PAGE } from '../../constant'
import {NotificationManager} from "react-notifications";

const Shipping = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const commonData = useSelector((state) => state.commonReducer);
  const userAddressData = useSelector((state) => state.userReducer.userData);
  const cartDetails = useSelector((state) => state.cartReducer.cartItems);
  const { getCountriesList, getStateListByCountries, getCitiesByState } = useSelector((state) => state.userReducer);
  const [userData, setUserData] = useState({});
  const [save, onSave] = useState(true);

  const [error, setError] = useState({
    email: [],
    address: [],
    city: [],
    country: [],
    state: [],
    zipCode: [],
    full_name: [],
  });


  useEffect(() => {
    (async () => {
      if (localStorage.getItem('cartItems') && JSON.parse(localStorage.getItem('cartItems'))?.length) {
        const id = await JSON.parse(localStorage.getItem("userData")).id;
        await dispatch(cartAction.getCartItems());
        await dispatch(await userActions.userDataById(id));
        await dispatch(userActions.getCountries())
        if (getCountriesList) {
          if(userAddressData.country_id) {
            await dispatch(userActions.getStates(userAddressData.country_id))
          }
          if(userAddressData.state_id) {
            await dispatch(userActions.getCities(userAddressData.state_id))
          }

        }
        let rememberme = await localStorage.getItem("rememberme");
        if (rememberme) {
          onSave(true)
          rememberme = CryptoJS.AES.decrypt(rememberme, 'remembermesecret$#');
          rememberme = rememberme.toString(CryptoJS.enc.Utf8);
          rememberme = JSON.parse(rememberme);
          // this.state.formValues = rememberme;
          setUserData(rememberme);
        } else {
          onSave(false)
        }
      } else {
        props.history.push("/cart");
      }

    })();
  }, []);

  useEffect(() => {
    if (userAddressData.id !== userData.id) {
      setUserData(userAddressData);
    }
  }, [userAddressData]);


  useEffect(() => {
    (async () => {
      if (getCountriesList && userAddressData.country_id) {
        await dispatch(userActions.getStates(userAddressData.country_id))
      }
    })()
  }, [getCountriesList]);

  useEffect(() => {
    (async () => {
      if (getStateListByCountries) {
        await dispatch(userActions.getCities(userAddressData.state_id))
      }
    })()
  }, [getStateListByCountries]);

  function handleChange(event) {
    const { name, value } = event.target;
    if(name === 'zipCode') {
      const regEx = /^\b\d{0,5}\b$/;
      if (value === '' || regEx.test(value)) {
        setUserData((prevState) => ({ ...prevState, [name]: value }));
      }
      return;
    }
    setUserData((prevState) => ({ ...prevState, [name]: value }));
  }

  async function onEditAddress(event) {
    event.preventDefault();
    const results = await validateAddressEdit(userData);
    if (results.isFormValid === false) {
      setError(results.error);
    }
    if (results?.isFormValid) {
      setError({
        email: [],
        full_name: [],
        address: [],
        city: [],
        country: [],
        state: [],
        zipCode: [],
      });

      if (save) {
        const rememberme = CryptoJS.AES.encrypt(JSON.stringify(userData), 'remembermesecret$#').toString();
        localStorage.setItem("rememberme", rememberme);
      } else {
        localStorage.removeItem("rememberme");
      }
      // validate the shipping address
      const validateAddress = await dispatch(userActions.validateAddress(userData));
      if(!validateAddress.isSuccess) {
        NotificationManager.error(validateAddress.errorMessage);
        return;
      }
      const res = await dispatch(await cartAction.orderItems(userData, cartDetails))
      if (!res.isError) {
        localStorage.setItem('orderId', res.orderId);
        history.push(`/payments`, {
          prevPage: SHIPPING_PAGE
        });
      }
    }
  }

  const countryChange = async (e) => {
    e.preventDefault()
    let selectedCountry = getCountriesList.find((state) => state.id === (parseInt(e.target.value)))
    userData[`country`] = selectedCountry.name
    userData[`country_id`] = selectedCountry.id
    setUserData({ ...userData })
    await dispatch(userActions.getStates(selectedCountry.id))
  }

  const stateChange = async (e) => {
    e.preventDefault()
    let selectedState = getStateListByCountries.find((state) => state.id === (parseInt(e.target.value)))
    userData[`state`] = selectedState.state_name
    userData[`state_id`] = parseInt(selectedState.id)
    setUserData({ ...userData, 'city': '', 'city_id': '' });
    await dispatch(userActions.getCities(parseInt(selectedState.id)))
  }
  const cityChange = (e) => {
    let selectedCity = getCitiesByState.find((city) => city.id === (parseInt(e.target.value)))
    userData[`city`] = selectedCity.city
    userData[`city_id`] = selectedCity.id
    setUserData(prevMovies => ({ ...prevMovies, ...userData }))
    // setAddressState(addressState)
  }
  return (
    <div className="shipping-main">
      {/* <!--Sigin process--> */}
      <div className="sigin-desc">
        <div className="container">
          <h2>Shipping</h2>
          <div className="signin-process">
            <ul>
              <li>
                <div className="check-round">
                  <i className={"fas fa-check"} />
                </div>
                <p>Sign In</p>
              </li>
              <li>
                <div className="check-round  active"></div>
                <p className="active">Shipping</p>
              </li>
              <li>
                <div className="check-round"></div>
                <p>Billing</p>
              </li>
              <li>
                <div className="check-round"></div>
                <p>Confirmation</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!--end Sigin process--> */}

      {/* <!--form--> */}
      <div className="shipping-form">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <form>
                <h4>Contact Information</h4>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    placeholder="Email address"
                    value={userData?.email}
                    onChange={(e) => handleChange(e)}
                  />
                  {error?.email[0] ? (
                    <p className="error-validation">{error?.email[0]}</p>
                  ) : null}

                </div>
                <h4>Shipping Address</h4>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Full Name"
                    name='full_name'
                    value={userData?.full_name}
                    onChange={(e) => handleChange(e)}
                  />
                  {error?.full_name[0] ? (
                    <p className="error-validation">{error?.full_name[0]}</p>
                  ) : null}
                </div>
                <div className="form-group">
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Address"
                    name={'address'}
                    value={userData?.address}
                    onChange={(e) => handleChange(e)}
                  ></textarea>
                  {error?.address[0] ? (
                    <p className="error-validation">{error?.address[0]}</p>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Apartment, Suite, etc. (Optional)"
                    onChange={(e) => handleChange(e)}
                  />

                </div>
                <div className="form-group">
                  <label for="email">Country</label>
                  <select
                    className="form-control"
                    value={userData?.country_id}
                    onChange={(e) => countryChange(e)}>
                    <option value="0" selected disabled>
                      Select Country
                            </option>
                    {
                      getCountriesList?.map((country, index) =>
                        <option key={index}
                          value={country.id} >
                          {country.name}
                        </option>
                      )}
                  </select>
                  {/* <input
                    type="text"
                    className="form-control"
                    placeholder="Country"
                    name={'country'}
                    value={userData?.country}
                    onChange={(e) => handleChange(e)}
                  /> */}
                  {error?.country[0] ? (
                    <p className="error-validation">{error?.country[0]}</p>
                  ) : null}
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="email">State</label>
                      <select
                        className="form-control"
                        value={userData?.state_id}
                        onChange={(e) => stateChange(e)}>
                        <option value="" selected disabled>
                          Select State
                            </option>
                        {
                          getStateListByCountries?.map((state, index) =>
                            <option key={index}
                              value={state.id}>
                              {state.state_name}
                            </option>
                          )}
                      </select>

                      {error?.state[0] ? (
                        <p className="error-validation">{error?.state[0]}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="email">City</label>
                      <select
                        value={userData?.city_id}
                        class="form-control"
                        onChange={(e) => cityChange(e)}>
                        <option
                          value=""
                          selected
                          disabled>
                          Select City
                          </option>
                        {
                          getCitiesByState?.map((city, index) =>
                            <option key={index}
                              value={city.id}>
                              {city.city}
                            </option>
                          )}
                      </select>
                      {error?.city[0] ? (
                        <p className="error-validation">{error?.city[0]}</p>
                      ) : null}

                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="form-group">
                      <label>Zip Code</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="PINCODE"
                        name='zipCode'
                        value={userData?.zipCode}
                        onChange={(e) => handleChange(e)}
                      />
                      {error?.zipCode[0] ? (
                        <p className="error-validation">{error?.zipCode[0]}</p>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div class="form-check">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" checked={save} onChange={(e) => { onSave(!save) }} />
                    <span>Save this information for next time</span>
                  </label>
                </div>
              </form>
              <div className="cart-return-btn">
                <a onClick={() => props.history.push("/cart")}>
                  <span>
                    <i class="fas fa-chevron-left"></i>
                  </span>{" "}
                  Return to Cart
                </a>
                {/* <button className="red-btn">Continue to Shopping</button> */}
              </div>
            </div>

            <div className="col-lg-4">
              <OrderSummary></OrderSummary>
              {/* <div className="order-main-sum">
                <h3>Order Summary</h3>
                <div>
                  <p>Subtotal</p>
                  <p>${subTotals}</p>
                </div>
                <div>
                  <p>Delivery</p>
                  <p>$00.00</p>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <button className="coupon-btn w-100">
                    <div className="coupon-img">
                      <img src={Coupon} />
                    </div>
                    <span>Coupon Code</span>
                  </button>
                </div>
                <div className="total-div">
                  <p>Total</p>
                  <p>$00.00</p>
                </div>
              </div> */}
              {/* <button className="red-btn mt-2 w-100"> */}
              {/* </div> */}
              <button className="red-btn mt-2 w-100"
                disabled={commonData.loading}
                onClick={(e) => onEditAddress(e)}>
                {/* Proceed to Checkout */}
                Save & Continue <span className={commonData.loading === true ? "spinner-border" : ""}></span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <!--end form--> */}
    </div>
  );
};

export default memo(Shipping);
