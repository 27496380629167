export const initialState = {
  isRegistered: "",
  isEmailVerified: "",
  isLoggedIn: "",
  message: "",
  authData: {},
  success: null
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SIGNUP":
      return {
        ...state,
        isRegistered: action.payload.data.isRegistered,
        message: action.payload.data.message
      };
    case "EMAILVERIFICATION":
      return {
        ...state,
        isEmailVerified: action.payload.data.message,
        isRegistered: "",
      };
    case "VERIFY_EMAIL":
      return {
        ...state,
        message: action.payload.data.message
      };
    case "LOGIN":
      localStorage.setItem("userData", JSON.stringify(action.payload.data.user))
      localStorage.setItem("token", action.payload.data.user.token);
      return {
        ...state,
        // message: action.payload.data.message,
        authData: action.payload.data.user
      };
    case "CHANGE_PASSWORD":
      return {
        message: action.payload.data.message,
        success: action.payload.data.success
      };
    case "FORGET_PASSWORD":
      return {
        message: action.payload.data.message,
        success: action.payload.data.success
      }
    case "CLEAR_LOGIN":
      return {
        ...state,
        message: "",
      };
    case 'LOGOUT_USER':
      let cartItemsRestore = localStorage.getItem("cartItems")
    localStorage.clear();
    // localStorage.setItem("cartItems",cartItemsRestore)
      return {
        ...state,
        isRegistered: "",
        isEmailVerified: "",
        isLoggedIn: "",
        message: "",
        authData: {},
        cartItems: []
      }
    case 'RESET_SUCCESS':
      return {
        ...state,
        success: null,
        message: ""
      }
    default:
      return state;
  }
};
