import Api from "../../Api";
import { handleError } from "./commonAction";

export const getEmployeeList = (searchValue) => async (dispatch) => {
    try {
        let URL = `/api/v1/org-employees`;
        if(searchValue) {
            URL += `?search=${searchValue}`;
        }
        const res = await Api.get(URL);
        return res.data;
    } catch (error) {
        dispatch({ type: "LOADING_FAILURE" });
        dispatch(handleError(error));
    }
};

export const createEmployee = (payload) => async (dispatch) => {
    try {
        const res = await Api.post(`/api/v1/org-employees`, payload);
        return res.data;
    } catch (error) {
        dispatch({ type: "LOADING_FAILURE" });
        dispatch(handleError(error));
    }
};

export const updateEmployee = (selectedEmpId, payload) => async (dispatch) => {
    try {
        const res = await Api.put(`/api/v1/org-employees/${selectedEmpId}`, payload);
        return res.data;
    } catch (error) {
        dispatch({ type: "LOADING_FAILURE" });
        dispatch(handleError(error));
    }
};

export const getSingleEmployee = (empId) => async (dispatch) => {
    try {
        const res = await Api.get(`/api/v1/org-employees/${empId}`);
        return res.data;
    } catch (error) {
        dispatch({ type: "LOADING_FAILURE" });
        dispatch(handleError(error));
    }
};

export const getEmployeesOrderHistoryList = (searchValue) => async (dispatch) => {
    try {
        let URL = `/api/v1/org-employees/history/order`;
        if(searchValue) {
            URL += `?search=${searchValue}`;
        }
        const res = await Api.get(URL);
        return res.data;
    } catch (error) {
        dispatch({ type: "LOADING_FAILURE" });
        dispatch(handleError(error));
    }
};

export const getEmployeesPaymentHistoryList = (searchValue) => async (dispatch) => {
    try {
        let URL = `/api/v1/org-employees/history/payment`;
        if(searchValue) {
            URL += `?search=${searchValue}`;
        }
        const res = await Api.get(URL);
        return res.data;
    } catch (error) {
        dispatch({ type: "LOADING_FAILURE" });
        dispatch(handleError(error));
    }
};

export const getEmployeesTestHistoryList = (searchValue) => async (dispatch) => {
    try {
        let URL = `/api/v1/org-employees/history/tests`;
        if(searchValue) {
            URL += `?search=${searchValue}`;
        }
        const res = await Api.get(URL);
        return res.data;
    } catch (error) {
        dispatch({ type: "LOADING_FAILURE" });
        dispatch(handleError(error));
    }
};

export const getEmployeesPaymentDetail = (chargeId) => async (dispatch) => {
    try {
        let URL = `/api/v1/org-employees/history/payment/${chargeId}`;
        const res = await Api.get(URL);
        return res.data;
    } catch (error) {
        dispatch({ type: "LOADING_FAILURE" });
        dispatch(handleError(error));
    }
};
