import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {NotificationManager} from 'react-notifications';
import _ from 'lodash';
import moment from 'moment';
import momentTz from 'moment-timezone';

import "./covid.scss";
import { covidAction } from "../../Redux/Actions";

const timezone = {
    'PST': 'America/Los_Angeles',
    'EST': 'America/New_York',
    'MT': 'America/Denver'
};

const CovidScheduler = (props) => {
    const dispatch = useDispatch();
    const covidReducer = useSelector((state) => state.covidReducer);

    const [error, setError] = useState({
        locationError: ''
    });
    const [search, setSearch] = useState({
        location: ''
    });
    const [onLoading, setOnLoading] = useState(false);
    const [testLocations, setTestLocations] = useState([]);
    const [possibleAppointmentDates, setPossibleAppointmentDates] = useState([]);
    const [selectedAppointment, setSelectedAppointment] = useState(0);
    const [selectedAppointmentTime, setSelectedAppointmentTime] = useState(0);
    const [locationAvailability, setLocationAvailability] = useState({});
    const [onFetchingAppointments, setOnFetchingAppointments] = useState({});
    const [processReservation, setProcessReservation] = useState(false);
    const [fetchAppointmentObj, setFetchAppointmentObj] = useState({});
    const [testType, setTestType] = useState('RT-PCR');
    const [testTypeChanged, setTestTypeChanged] = useState(false);

    useEffect(() => {
        (async () => {
            if(!covidReducer.searchLocation) {
                props.history.push('/covid-19-testing');
                return
            }
            calculateAppointmentDates();
            await getAllLocations();
        })();
    }, []);

    useEffect(() => {
        if(covidReducer.error) {
            NotificationManager.error('Something went wrong. Please try again.');
            dispatch(covidAction.resetError());
        }
    }, [covidReducer.error]);

    useEffect(() => {
        setSearch(prev => ({...prev, location: covidReducer.searchLocation}));
    }, [covidReducer.searchLocation]);

    const getAllLocations = async () => {
        setOnLoading(true);
        const allTestLocations = await dispatch(covidAction.getTestLocation(testType));
        setOnLoading(false);
        if(!allTestLocations.isSuccess) {
            NotificationManager.error('Something went wrong. Please try again later');
            return;
        }
        setTestLocations(allTestLocations.data);
        const obj = {};
        allTestLocations.data.map((location) => obj[location.id] = true);
        setFetchAppointmentObj(obj);
    };

    const calculateAppointmentDates = () => {
        const dates = [];
        for(var i=0; i<14; i++) {
            let date = (moment().add(i, 'day').format("ddd MM DD YYYY")).split(' ');
            dates.push({
                day: date[0],
                month: date[1],
                date: date[2],
                year: date[3],
            })
        }
        setPossibleAppointmentDates(dates);
    };

    const onSelectAppointmentDate = (index) => {
        setSelectedAppointment(index);
        const keys = Object.keys(fetchAppointmentObj);
        const obj = {};
        keys.map(key => obj[key] = true);
        setFetchAppointmentObj(obj);
    };

    const onSelectAppointmentTime = async (appointment) => {
        const selectedLocation = testLocations.find((location) => location.id === appointment.locationId);
        const data = {
            ...selectedLocation,
            testType
        };
        delete data.description;
        dispatch(covidAction.setLocationDetail(data));
        dispatch(covidAction.setAppointmentDetail(appointment));
        setSelectedAppointmentTime(appointment.id);
        setProcessReservation(true);
        const reserveResponse = await dispatch(covidAction.reserveAppointment(appointment.id));
        setProcessReservation(false);
        if(!reserveResponse.isSuccess) {
            NotificationManager.error(reserveResponse.errorMessage);
            return;
        }
        props.history.push('/covid-19-testing/covid-scheduler/covid-visit-info');
    };

    const renderAppointmentDates = () => {
       return (
           possibleAppointmentDates.map((possibleAppointmentDate, index) => {
                return (
                    <div
                        className={`appointment-date ${index === selectedAppointment? 'active': ''}`}
                        onClick={() => onSelectAppointmentDate(index)} key={index}
                    >
                        <span>{possibleAppointmentDate.day}</span>
                        <span><b>{possibleAppointmentDate.month}/{possibleAppointmentDate.date}</b></span>
                    </div>
                );
           })
       )
    };

    const renderAppointmentTimes = (testLocation) => {
        const locationId = testLocation.id;
        let currentTime;
        const selectedAppointmentObj = possibleAppointmentDates[selectedAppointment];
        const selectedDate = `${selectedAppointmentObj.year}-${selectedAppointmentObj.month}-${selectedAppointmentObj.date}`;
        if(testLocation.timezone && timezone[testLocation.timezone]) {
            currentTime = momentTz().tz(timezone[testLocation.timezone]).add(30, 'minutes').format("YYYY-MM-DD hh:mm A");
        }
        let allPastTime = true;
        const content = (
           (locationAvailability[locationId] || []).map((availabilityTime) => {
               const startTime = moment(`${selectedDate} ${availabilityTime.start}`, "YYYY-MM-DD hh:mm A");
               const endTime = moment(currentTime);
               const isPastTime = startTime.isBefore(endTime);
               if(isPastTime) {
                   return;
               }
               allPastTime = false;
               return (
                    <div
                        className={`appointment-time ${availabilityTime.id === selectedAppointmentTime? 'active': ''}`}
                        onClick={() => onSelectAppointmentTime(availabilityTime)} key={availabilityTime.id}
                    >
                        <span>{availabilityTime.start}</span>
                    </div>
                );
           })
       );
        if(allPastTime) {
            return (<p><b>There are no available times at this location</b></p>);
        }
        return content;
    };

    const fetchAppointment = async (locationId) => {
        const selectedAppointmentObj = possibleAppointmentDates[selectedAppointment];
        const selectedDate = `${selectedAppointmentObj.year}-${selectedAppointmentObj.month}-${selectedAppointmentObj.date}`;
        setOnFetchingAppointments(prev => ({...prev, [locationId]: true}));
        const possibleAppointments = await dispatch(covidAction.getAvailableAppointments(selectedDate, locationId));
        setOnFetchingAppointments(prev => ({...prev, [locationId]: false}));
        if(!possibleAppointments.isSuccess) {
            NotificationManager.error('Something went wrong while fetching possible availability dates');
            return;
        }
        setFetchAppointmentObj(prev => (
            {
                ...prev,
                [locationId]: false
            }
        ));
        setLocationAvailability(prev => (
            {
                ...prev,
                [locationId]: possibleAppointments.data
            }
        ));
    };

    const renderTestLocations = () => {
        return (
            testLocations.map((testLocation) => {
                const selectedAppointmentObj = possibleAppointmentDates[selectedAppointment];
                return (
                      <div className={'location-container'} key={testLocation.id}>
                        <div className={"location-header"}>
                          <h4>{testLocation.name}</h4>
                          <p>{testLocation.street}, {testLocation.city}, {testLocation.state} - {testLocation.zipCode}</p>
                          {testLocation.timezone && <p style={{'font-size': '12px'}}><b>Timezone:</b> {timezone[testLocation.timezone]} ({testLocation.timezone})</p>}
                        </div>
                        <div className={"alert alert-info mt-2"}>
                          <span className={"fa fa-circle"}> &nbsp; Test type: <b>COVID-19 {testType} Test</b></span>
                        </div>
                        <div className={"additional-info"}>
                            <p><i className="fa fa-plane" aria-hidden="true"></i> &nbsp;Accepted for most travel</p>
                            <p><i className="fa fa-car" aria-hidden="true"></i> &nbsp;Drive-thru test (vehicle recommended)</p>
                            <p><i className="fa fa-file" aria-hidden="true"></i> &nbsp; Results within 1-2 days</p>
                        </div>
                        <div className={"description"}>
                            <p>{testLocation.description}</p>
                        </div>
                        <div className={"slots"}>
                            {
                                fetchAppointmentObj[testLocation.id] &&
                                <button
                                    className="btn red-btn-border mt-2"
                                    type="button"
                                    onClick={() => fetchAppointment(testLocation.id)}
                                >Check Availability
                                    <span className={onFetchingAppointments[testLocation.id] ? "spinner-border" : ""}></span>
                                </button>
                            }

                              {
                                  !fetchAppointmentObj[testLocation.id] &&
                                  (_.isEmpty(locationAvailability[testLocation.id]) ?
                                      <p><b>There are no available times at this location for {selectedAppointmentObj.day}, {selectedAppointmentObj.month}/{selectedAppointmentObj.date}.
                                              Try searching for availability on another date.</b></p>
                                      :<div className={"appointment-times"}>
                                          <div className={"d-flex flex-row"}>
                                              {
                                                  processReservation ?
                                                  <span className={"spinner-border"}></span>:
                                                  renderAppointmentTimes(testLocation)
                                              }
                                          </div>
                                      </div>
                                  )
                              }
                        </div>

                      </div>
                );
            })
        )
    };

    const onSearch = (e) => {
        const {name, value} = e.target;
        setSearch((prev) => {
            return {
                ...prev,
                [name]: value
            }
        });
        setError(prev => ({...prev, locationError: ''}));
    };

    const onSubmit = (e) => {
        e.preventDefault();
    };

    const onProcessSearch = async () => {
        if(!search.location || !search.location.trim()) {
            setError(prev => {
                return {
                    ...prev,
                    locationError: 'This field is required'
                }
            });
            return;
        }
        await dispatch(covidAction.setLocation(search.location));
        await getAllLocations();
        setTestTypeChanged(false);
    };

    const handleTestTypeChange = (e) => {
        setTestTypeChanged(true);
        setTestType(e.target.value);
    };

    return (
        <div id="scheduler-container">
            {
                onLoading &&
                <div className={"loader-container"}>
                    <span className={"spinner-border"}></span><span> &nbsp;Loading...</span>
                </div>
            }
            <div id="scheduler-row" className="row justify-content-center">
                <div className={"scheduler-heading"}>
                    <h3>COVID-19 {testType} Test</h3>
                    <p>Results in 1-2 days. The most accurate test. Drive-thru test. Accepted for most travel.</p>
                </div>
                <div className={"scheduler-switch"}>
                    <div className="form-group" data-hasError={!!error.future_travel_recreation}>
                        <label>
                            <b>Select test type: </b>
                        </label>
                        <div className="col-md-12 p-0 pt-2 d-flex flex-column" onChange={(e) => handleTestTypeChange(e)}>
                            <label className="radio-inline mr-3">
                                <input
                                    type="radio"
                                    name="test_type" value="RT-PCR" className={'mr-2'}
                                    checked={testType === 'RT-PCR'}
                                />
                                Covid RT-PCR Test
                            </label>
                            <label className="radio-inline">
                                <input
                                    type="radio"
                                    name="test_type" value="Antigen" className={'mr-2'}
                                    checked={testType === 'Antigen'}
                                />
                                Covid Antigen Test
                            </label>
                        </div>
                    </div>
                </div>
                <div className={"scheduler-info"}>
                    <div className={"alert alert-secondary w-50"}>
                        <span className={"fa fa-info-circle"}> &nbsp;</span>
                        <h5 className={'d-inline'}>
                            <b>For faster registration, have the following items with you.</b>
                        </h5>
                        {
                            testType === 'RT-PCR' &&
                                <ul>
                                    <li>Insured: Insurance card</li>
                                    <li>Uninsured: SSN, driver’s license, or state ID</li>
                                    <li>Vaccinated: COVID-19 vaccination card</li>
                                    <li>Online Payment: Credit card details</li>
                                </ul>
                        }
                        {
                            testType === 'Antigen' &&
                                <ul>
                                    <li>Online Payment: Credit card details to pay <b>$70</b></li>
                                </ul>
                        }
                    </div>

                    <div className={"filter w-25"}>
                        <form className="form" onSubmit={onSubmit}>
                            <label htmlFor="location" className="text-info d-block">Where do you live?</label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    name="location"
                                    className="form-control"
                                    value={search.location}
                                    onChange={(e) => onSearch(e)}
                                />
                                <div className="input-group-append">
                                    <button
                                        className="btn btn-outline-secondary"
                                        type="submit"
                                        onClick={onProcessSearch}
                                    ><i className={"fa fa-search"}></i>
                                    </button>
                                </div>
                            </div>
                            {
                                error.locationError?
                                    <p className={"error-text"}>{error.locationError}</p>:
                                    <p className={"help-text"}>Zip Code, or city and state</p>
                            }
                        </form>
                    </div>
                    {
                        !testTypeChanged &&
                        <div className={"appointment-dates"}>
                            <p>Select Appointment Date: </p>
                            <div className={"d-flex flex-row"}>
                                {renderAppointmentDates()}
                            </div>
                        </div>
                    }
                </div>
                {
                    !testTypeChanged ?
                     <>
                         {_.isEmpty(testLocations) ?
                             <div className={"alert alert-secondary mt-3"}>
                                 <span className={"fa fa-exclamation-triangle"}> &nbsp;</span>
                                 <h6 className={'d-inline'}>
                                     <b>We're sorry. We are unable to find any testing locations. Please try a different
                                         search location.</b>
                                 </h6>
                             </div> :
                             (
                                 <div className="col-md-12 scheduler-box">
                                     <div className={'mb-3'}>
                                         <h5><b>{testLocations.length} test location(s) found:</b></h5>
                                     </div>
                                     {renderTestLocations()}
                                 </div>
                             )
                         }
                     </>: <></>
                }
            </div>
        </div>
    )
};

export default CovidScheduler;
