import React,{ useState,useEffect } from "react";
import { validateSignUpForm } from "../Validation";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../Redux/Actions/index";
import "../Styles/styles.scss";

const SignUp = (props) => {
  const dispatch = useDispatch();

  const commonData = useSelector((state) => state.commonReducer);
  const [state, setState] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errorData, setErrorData] = useState({
    fullName: [],
    email: [],
    password: [],
    confirmPassword: [],
  });

  useEffect(() => {
    return () => {
      dispatch({ type: "CLEAN_ERROR" });
      dispatch({ type: "CLEAR_LOGIN" });
    };
  }, []);

  function handleChange(event) {
    const { name, value } = event.target;
    if(name === 'isOrganisationUser') {
      const checked = event.target.checked;
      setState((prevState) => ({ ...prevState, [name]: checked }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
  }



  const onSubmitSignUp = async (e) => {
    e.preventDefault();
    let divRef = null;
    dispatch({ type: "CLEAN_ERROR" });
    dispatch({ type: "CLEAR_LOGIN" });
    const results = await validateSignUpForm(state)
    if (results.isFormValid === false) {
      setErrorData(results.error)
    }
    if (results?.isFormValid) {
      setErrorData({
        fullName: [],
        email: [],
        password: [],
        confirmPassword: [],
      })
     const response = await dispatch(await authActions.signUpUser(state));
      if(response && response.isRegistered){
        if(props?.setSignUp && props.setLogin){
          props.setSignUp(false)
          props.setLogin(true)
        }else {
          window.scrollTo(0, 300);
        }
        setState({
          fullName: "",
          email: "",
          password: "",
          confirmPassword: "",
        });
      }
    }
  };

  return (
    <div>
      <form className="signup-form">
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Full Name"
            value={state.fullName}
            name="fullName"
            onChange={(e) => handleChange(e)}
          />
          {errorData?.fullName[0] ? (
            <p className="error-validation">{errorData?.fullName[0]}</p>
          ) : null}
        </div>
        {/*         <div class="form-group">
          <input type="text" class="form-control" placeholder="Last Name" />
        </div> */}
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            placeholder="Email"
            value={state.email}
            name="email"
            onChange={(e) => handleChange(e)}
          />
          {errorData?.email[0] ? (
            <p className="error-validation">{errorData?.email[0]}</p>
          ) : null}
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            placeholder="Password"
            value={state.password}
            name="password"
            onChange={(e) => handleChange(e)}
          />
          {errorData?.password[0] ? (
            <p className="error-validation">{errorData?.password[0]}</p>
          ) : null}
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            placeholder="Confirm Password"
            name="confirmPassword"
            value={state.confirmPassword}
            onChange={(e) => handleChange(e)}
          />
          {errorData?.confirmPassword[0] ? (
            <p className="error-validation">{errorData?.confirmPassword[0]}</p>
          ) : null}
        </div>

        <div className="form-check mb-2 cb-org-user">
          <label className="form-check-label">
            <input
                type="checkbox"
                className="form-check-input"
                name="isOrganisationUser"
                onChange={(e) => handleChange(e)}
            />
            <span>Sign up as Organization user</span>
          </label>
        </div>

        <p>
          Your personal data will be used to support your experience throughout
          this website, to manage access to your account, and for other purposes
          described in our privacy policy.
        </p>
        <button className="red-btn" onClick={(e) => onSubmitSignUp(e)}>
          Register
          <span
            className={commonData.loading === true ? "spinner-border" : ""}
          ></span>
        </button>
      </form>
    </div>
  );
};

export default SignUp;
