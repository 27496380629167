import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { authActions } from "../../Redux/Actions/index";
import { validateResetPasswordForm } from "../../Validation";
import {NotificationManager} from "react-notifications";

const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    resetKey: "",
    confirmNewPassword: "",
    newPassword: "",
  });
  const [error, setError] = useState({
    confirmNewPassword: [],
    newPassword: [],
  });

  const authData = useSelector((state) => state.authReducer);
  const commonData = useSelector((state) => state.commonReducer);

  useEffect(() => {
    const resetKey = queryString.parse(props.location.search).accessKey;
    setState((prevState) => ({ ...prevState, resetKey: resetKey }));
  }, []);

  useEffect(() => {
    return () => {
      dispatch({ type: "CLEAN_ERROR" });
      dispatch({ type: "CLEAR_LOGIN" });
    };
  }, []);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("STATE IN RESET",state)
    const result = await validateResetPasswordForm(state);
    if (result?.isFormValid === true) {
      setError({ confirmNewPassword: [], newPassword: [], oldPassword: [] });

      await dispatch(authActions.resetPassword(state));
    } else {
      setError(result?.error);
    }
  };

  useEffect(() => {
    dispatch(authActions.resetSuccess());
  }, [authData?.success]);

  return (
    <div className="signup-form my-5">
      <div className="container" style={{ position: "relative" }}>
        {authData?.success === true ? (
          NotificationManager.success(authData?.message)
        ) : authData?.success === false ? (
            NotificationManager.error(authData?.message)
        ) : null}
        <form className="forgot-form">
          <div className="form-group">
            <label>New Password</label>
            <input
              type="password"
              name="newPassword"
              className="form-control"
              value={state?.newPassword}
              onChange={(e) => handleChange(e)}
            />
            {error?.newPassword[0] ? (
              <p className="error-validation">{error?.newPassword[0]}</p>
            ) : null}
          </div>
          <div className="form-group">
            <label>Confirm New Password</label>
            <input
              type="password"
              name="confirmNewPassword"
              className="form-control"
              value={state?.confirmNewPassword}
              onChange={(e) => handleChange(e)}
            />
            {error?.confirmNewPassword[0] ? (
              <p className="error-validation">{error?.confirmNewPassword[0]}</p>
            ) : null}
          </div>
          <div className="text-center">
            <button className="red-btn" onClick={(e) => onSubmit(e)}>
              Reset Password{" "}
              <span
                className={commonData.loading === true ? "spinner-border" : ""}
              ></span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
