export const initialState = {
    categoryList: [],
    FAQList: [],
    loadingFAQs: false,
    loadingCategory: false,
    searchedFAQList: [],
    loadingSearchFAQs: false,
};

export const helpReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOADING_FAQ_CATEGORY_LIST":
            return {
                ...state,
                loadingCategory: true
            };

        case "FAQ_CATEGORY_LIST":
            return {
                ...state,
                categoryList: action.payload?.data
            };

        case "LOADED_FAQ_CATEGORY_LIST":
            return {
                ...state,
                loadingCategory: false
            };

        case "LOADING_FAQ_BY_CATEGORY_LIST":
            return {
                ...state,
                loadingFAQs: true
            };

        case "FAQ_BY_CATEGORY_LIST":
            return {
                ...state,
                FAQList: action.payload?.data
            };

        case "LOADED_FAQ_BY_CATEGORY_LIST":
            return {
                ...state,
                loadingFAQs: false
            };

        case "SEARCH_FAQ":
            return {
                ...state,
                loadingSearchFAQs: true
            };

        case "SEARCHED_FAQ":
            return {
                ...state,
                searchedFAQList: action.payload?.data
            };

        case "SEARCH_FAQ_COMPLETE":
            return {
                ...state,
                loadingSearchFAQs: false
            };

        default:
            return state
    }
};
