import React,{ useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link} from "react-router-dom";
import queryString from "query-string";
import { authActions } from "../../Redux/Actions/index";
import "../Verify/verify.scss";
import "../../Styles/styles.scss";
import {NotificationManager} from "react-notifications";
import {resetError} from "../../Redux/Actions/commonAction";

const Verify = (props) => {
    const dispatch = useDispatch();
    // const [state, setState] = useState({ email: "", code: "" });
    const verifyData = useSelector((state) => state.authReducer);
    // const verifyData = useSelector((state) => console.log("STATEsss",state));
    const commonData = useSelector((state) => state.commonReducer);

    useEffect(() => {
        (async () => {
            if (props?.location?.search) {
                const values = queryString.parse(props.location.search);
                dispatch({ type: 'CLEAN_ERROR' })
                dispatch({ type: "CLEAR_LOGIN" });
              await dispatch(await authActions.verifyEmail({email:values.email,code: values.code}))
            }
           })();

    }, [])

    useEffect(() => {
        return ()=>{
            dispatch({ type: 'CLEAN_ERROR' })
            dispatch({ type: "CLEAR_LOGIN" });
        }
    }, [])


    // useEffect(() => {
    //     console.log("verifyData in update =>", verifyData)
    //     if (verifyData.message) {
    //         props.history.push("/signin")
    //     }
    // }, [verifyData])

    // const handleChange = (event) => {
    //     const { name, value } = event.target;
    //     setState((prevState) => ({ ...prevState, [name]: value }));
    // }
    // const onSubmit = async (e) => {
    //     e.preventDefault()
    //     dispatch({ type: 'CLEAN_ERROR' })
    //     dispatch({ type: "CLEAR_LOGIN" });
    //     console.log(state)
    //     await dispatch(await authActions.verifyEmail(state))
    //     console.log("verifyData =>", verifyData)

    // }

    useEffect(() => {
        dispatch(authActions.resetSuccess());
    }, [verifyData.message]);

    useEffect(() => {
        dispatch(resetError());
    }, [commonData.errorMsg]);

    return (
        <div className="verify">
            {/* {verifyData.message ? <p className="success-msg"><span><i class="far fa-check-circle"></i></span> {verifyData.message}</p> : commonData?.errorMsg ? <p className="error-msg"><span><i class="fas fa-exclamation-circle"></i></span>{commonData.errorMsg}</p> : null} */}
            <div className="container">
                <form>
                    {
                        verifyData.message ?
                            NotificationManager.success(verifyData.message) :
                            commonData.errorMsg ? NotificationManager.success(commonData.errorMsg.toString()): null
                    }
                {/* {commonData.errorMsg} */}
                    {/* <h2>{verifyData.message }</h2> */}

                    {/* <p>Enter the verification code we sent to <span>{state?.email}</span>. If you don't see it, check spam folder.</p> */}
                    {/* <input type="text" name="code" value={state.code} placeholder="Enter code" ></input> */}
                    {/* <div className="verify-btn"> */}
                        {/* <a href="#">Back</a> */}
                    <a href={"/signin"} className={"red-btn-border"}> Back </a>

                        {/* <button className="red-btn" onClick={onSubmit}>Verify */}
                     {/* <span className={commonData.loading === true ? "spinner-border" : ""}></span></button> */}
                    {/* </div> */}
                </form>
                {/*<div className="verify-ul">*/}
                {/*    <ul>*/}
                {/*        <li>Help</li>*/}
                {/*        <li>Privacy</li>*/}
                {/*        <li>Terms</li>*/}
                {/*    </ul>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default (Verify);
