import awsconfig from "../Config/aws-config";
const AWS = require("aws-sdk");

export const uploadImage = async (fileData, bucketName, filePath) => {
    return new Promise((resolve, reject) => {
     AWS.config.update(awsconfig);
      const bucket = new AWS.S3({ params: { Bucket: bucketName } });
      let r = Math.random().toString(36).substring(7);
      const base64Data = new Buffer.from(fileData.dataUrl.replace(/^data:image\/\w+;base64,/, ""), 'base64');
      const params = {
        Key: `${filePath}/${r}`, // file will be saved as testBucket/contacts.csv
        Body: base64Data,
        ContentEncoding: 'base64',
        ContentType: fileData.format,
        ACL: "public-read"
      };
      bucket.upload(params, function (s3Err, data) {
        if (s3Err) {
          console.log(s3Err,'s3Err');
          throw s3Err;
        }
        resolve(data.Location);
      });
    });
};

export const removeImage = async (bucketName, filePath) => {
    return new Promise((resolve, reject) => {
      AWS.config.update(awsconfig);
      const s3 = new AWS.S3({ params: { Bucket: bucketName } });
      const params = {
        Key: filePath,
        Bucket: bucketName
      };
      s3.deleteObject(params, function (s3Err) {
        if (s3Err) {
          throw s3Err;
        }
        resolve();
      });
    });
};
