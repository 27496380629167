export const initialState = {
    searchLocation: '',
    searchLocationDetail: {},
    appointmentDetail: {},
    error: '',
    assessmentDetail: {
        future_travel_recreation: null,
        possible_social_distancing: null,
        closed_contact: null,
        risk_resident: null,
        work_in_healthcare: null,
        risk_workplace: null,
        asked_for_test: null,
        symptoms_experienced: null,
        symptoms: {
            is_chills: false,
            is_headache: false,
            is_congestion: false,
            is_muscle_ache: false,
            is_cough: false,
            is_nausea: false,
            is_diarrhea: false,
            is_loss_of_taste: false,
            is_fatigue: false,
            is_difficult_breathing: false,
            is_fever: false,
            is_sore_throat: false,
            symptom_date: null,
        },
        is_acknowledge: false
    },
    visitInfo: {
        date_of_birth: '',
        patient_relationship: '',
        patient_first_name: '',
        patient_last_name: '',
        address_line_1: '',
        address_line_2: '',
        country: '',
        state: '',
        city: '',
        zipCode: '',
        gender: '',
        race: '',
        ethnicity: '',
        patient_email: '',
        patient_mobile: '',
        is_primary_phone: '',
        primary_phoneNumber: '',
        received_vaccine: '',
        first_time_vaccine: '',
        previous_test_positive: '',
        vaccine_dose: '',
        vaccine_manufacturer_1: '',
        vaccine_manufacturer_2: '',
        taken_2_dose: '',
        first_dose_date: '',
        second_dose_date: '',
        medical_condition: '',
        medical_issues: {
            is_asthma: false,
            is_cirrhosis: false,
            is_cancer: false,
            is_diabetes: false,
            is_hard_cough: false,
            is_obesity: false,
            is_hypertension: false,
            is_kidney_failure: false,
            is_heart_problem: false,
        },
        is_pregnant: '',
        payment_select: '',
        heath_insurance: '',
        insurance_company: '',
        insurance_id: '',
        front_insurance_card_img: '',
        back_insurance_card_img: '',
        group_number: '',
        policy_holder: 'self',
        confirm_insurance_id: '',
        heath_info_available: '',
        ssn: '',
        driver_license: '',
        hhs_state: '',
        is_acknowledge: '',
        confirm_no_health_insurance: '',
        payment_charge_id: '',
    },
    confirmationCode: '',
    bookingInfo: {
        userEmail: '',
        bookingData: []
    }
};

export const covidReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_SEARCH_LOCATION":
            return {
                ...state,
                searchLocation: action.payload
            };

        case "SET_SEARCH_LOCATION_DETAIL":
            return {
                ...state,
                searchLocationDetail: action.payload
            };

        case "SET_APPOINTMENT_DETAIL":
            return {
                ...state,
                appointmentDetail: action.payload
            };

        case "SET_ASSESSMENT_DETAIL":
            return {
                ...state,
                assessmentDetail: action.payload
            };

        case "SET_CONFIRMATION_CODE":
            return {
                ...state,
                confirmationCode: action.payload
            };

        case "SET_COVID_VISIT_DETAIL":
            return {
                ...state,
                visitInfo: action.payload
            };

        case "SET_BOOKING_DETAIL":
            return {
                ...state,
                bookingInfo: action.payload
            };

        case "SET_ASSESSMENT_DETAIL_ERROR":
        case "SET_SEARCH_LOCATION_ERROR":
        case "SET_VISIT_INFO_DETAIL_ERROR":
        case "GET_TEST_LOCATION_ERROR":
        case "CREATE_CHARGE_ERROR":
        case "GET_AVAILABLE_APPOINTMENT_ERROR":
        case "FETCH_BOOKING_ERROR":
        case "CANCEL_BOOKING_ERROR":
        case "SET_BOOKING_DETAIL_ERROR":
            return {
                ...state,
                error: action.payload
            };

        case "RESET_ERROR":
            return {
                ...state,
                error: null
            };

        default:
            return state;
    }
};
