import React  from "react";
import "../RegisterKit/register-kit.scss";

const RegisterKitConfirmation = () => {

    return (
        <div className="container register-kit-main width-50">
            <div className={'text-center'}>
                <h2>Register Your Accugenedx Kit</h2>
                <div className="signin-process">
                    <ul>
                        <li>
                            <div className="check-round">
                                <i className={"fas fa-check"} />
                            </div>
                            <p>Kit Information</p>
                        </li>
                        <li>
                            <div className="check-round">
                                <i className={"fas fa-check"} />
                            </div>
                            <p>Tester Info</p>
                        </li>
                        <li>
                            <div className="check-round">
                                <i className={"fas fa-check"} />
                            </div>
                            <p>Review</p>
                        </li>
                        <li>
                            <div className="check-round active">
                                <span className={'step'}>4</span>
                            </div>
                            <p>Done</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="container mt-4">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-10 col-sm-12">
                        <h4 className={'section-heading mb-2 text-center'}>Your Kit has been registered successfully.</h4>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterKitConfirmation;
