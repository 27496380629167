import React, { Component, useEffect } from "react";
import { withRouter } from 'react-router-dom'
import "../Reports/reports.scss";
import { getTestReports } from '../../Redux/Actions/orderAction'
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';

const Reports = () => {
  const dispatch = useDispatch();
  const prepareTestReportData = (testReportData=[]) => {
    return testReportData.sort((a, b) => new Date(b.created_ts) > new Date(a.created_ts)? 1: -1);
  };
  const testReportList = useSelector((state) => prepareTestReportData(state.orderReducer.testReportData));
  useEffect(() => {
    (async () => {
      await dispatch(getTestReports())
    })()
  }, [])



  return (
    <div className="reports-main">
      <div className="container">
        <h2>Test Reports</h2>
        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Order No.</th>
                <th>Kit ID</th>
                <th>Test Name</th>
                <th>Date</th>
                <th>action</th>
              </tr>
            </thead>
            <tbody>

              {
              testReportList.length>0?  testReportList.map((report, index) =>
                  <tr key={report.id}>
                    <td>{report.orderId || 'N/A'}</td>
                    <td>{report.kitId || 'N/A'}</td>
                    <td>{report.test_name}</td>
                    <td>{moment(report.created_ts).format('l')}</td>
                    <td><a style={{textDecoration:"none"}} className="red-btn-border" href={report.report_doc} target='_blank'>view</a></td>
                  </tr>
                ): 'No report available'
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

}

export default withRouter(Reports);
