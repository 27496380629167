import Api from "../../Api";
import { handleError } from "./commonAction";

export const loginUser = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/users/login`, body);
    // console.log("RE,",res)
    // localStorage.setItem("userData",JSON.stringify(res.data.data))
    await dispatch({
      type: "LOGIN",
      payload: { data: res.data },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));

  }
};

export const signUpUser = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/users/signup`, body);

    dispatch({
      type: "SIGNUP",
      payload: { data: res.data }
    });

    dispatch({ type: "LOADING_COMPLETED" });
return res.data;
  } catch (err) {
      dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const matchToken = () => async (dispatch) => {
  try {
    let userData = await JSON.parse(localStorage.getItem("userData"));
    let token =await localStorage.getItem("token");
    const res = await Api.get(`/api/v1/users/matchToken?email=${userData.email}&token=${token}&id=${userData.id}`);

    console.log("REPSMATCH",res);
    await dispatch({
      type: "LOGIN",
      payload: { data: res.data },
    });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const verifyEmail = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.patch(`/api/v1/users/verifyCode`, body);
    console.log("RE,",res.data.data)
    await dispatch({
      type: "VERIFY_EMAIL",
      payload: { data: res.data },
    });
    dispatch({ type: "LOADING_COMPLETED" });

  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));

  }
};

export const changePassword = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/users/change-password`, body);

    console.log("respass",res)
    dispatch({
      type: "CHANGE_PASSWORD",
      payload: { data: res.data }
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
      dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const forgetPassword = (body) => async (dispatch) => {
  try {
    // console.log("BODYYYYY",body)
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/users/forgotPassword`, body);

    console.log("FORGET_PASSWORD",res)
    dispatch({
      type: "FORGET_PASSWORD",
      payload: { data: res.data }
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
      dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};


export const resetPassword = (body) => async (dispatch) => {
  try {
    // console.log("BODYYYYY",body)
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/users/resetPassword`, body);

    console.log("FORGET_PASSWORD",res)
    dispatch({
      type: "FORGET_PASSWORD",
      payload: { data: res.data }
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
      dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const resetSuccess = () => async (dispatch) => {
  dispatch({ type: "RESET_SUCCESS" });
};
