export const initialState = {
    cartItems: [],
    subTotal: 0,
    total: 0,
    validateData:null,
    couponCode:false,
    // orderId: null,
    // orderNumber: null,
    // products: [],
    // isError: null,
    // isPaySuceess: null,
    // paymentMessage: ""
    forceRemoveCouponCode: false
};

export const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CARTITEMS":
            return {
                ...state,
                cartItems: action.payload
            }
            case "TOTAL_CALCULATE":
            return {
                ...state,
                subTotal: action.subTotal ,
                total: action.total
            }
            case "VALIDATE_COUPON_DATA":
            return{
                ...state,
                validateData: action.payload
            }
            case 'SET_COUPON_CODE':
                return {
                    ...state,
                    couponCode: action.payload
                }
            case 'FORCE_REMOVE_COUPON_CODE':
                return {
                    ...state,
                    forceRemoveCouponCode: true
                }
            case 'RESET_FORCE_REMOVE_COUPON_CODE':
                return {
                    ...state,
                    forceRemoveCouponCode: false
                }
        // case "CHECKOUT":
        //     return {
        //         ...state,
        //         orderId: action.payload.data.orderId,
        //         orderNumber: action.payload.data.orderNumber,
        //         products: action.payload.data?.data ? action.payload.data?.data : [],
        //         isPaySuceess: typeof action.payload.data.isPaySuceess === "boolean" ? action.payload.data.isPaySuceess : null,
        //         isError: typeof action.payload.data.isError === "boolean" ? action.payload.data.isError : null,
        //         paymentMessage: action.payload.data?.paymentMessage ? action.payload.data?.paymentMessage: ""
        //     }
        case "ERROR":
            return{
                ...state,
                isError: true
            }
        default:
            return state
    }
}
