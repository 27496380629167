import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {NotificationManager} from 'react-notifications';
import RetrogenLogo from "../../Images/retrogen-logo.png";

import "./covid.scss";
import { covidAction } from "../../Redux/Actions";

const CovidTestGetStarted = (props) => {
    const dispatch = useDispatch();
    const covidReducer = useSelector((state) => state.covidReducer);

    const [search, setSearch] = useState({
        location: ''
    });
    const [onProcessingSearch, setOnProcessingSearch] = useState(false);
    const [error, setError] = useState({
        locationError: ''
    });

    useEffect(() => {
        if(covidReducer.error) {
            NotificationManager.error(covidReducer.error);
            dispatch(covidAction.resetError());
        }
    }, [covidReducer.error]);

    const onSearch = (e) => {
        const {name, value} = e.target;
        setSearch((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    };

    const onSubmit = (e) => {
        e.preventDefault();
    };

    const onProcessSearch = () => {
      if(!search.location || !search.location.trim()) {
        setError(prev => {
            return {
                ...prev,
                locationError: 'This field is required'
            }
        });
        return;
      }
      setOnProcessingSearch(true);
      dispatch(covidAction.setLocation(search.location));
      setOnProcessingSearch(false);
      props.history.push('/covid-19-testing/assessment');
    };

    const onCheckBooking = () => {
        props.history.push('/covid-19-testing/check-booking');
    };

    return (
        <div id="get-started">
            <div className="container">
                <div id="search-row" className="row justify-content-center align-items-center">
                    <div id="search-column" className="col-md-6">
                        <div id="search-box" className="col-md-12">
                            <form id="search-form" className="form" onSubmit={onSubmit}>
                                <div className={'logo'}>
                                    <img src={RetrogenLogo} alt="logo"/>
                                </div>
                                <h3 className="text-center text-info">Get Tested</h3>
                                <p className={"info-text text-center"}>Tests are available to eligible individuals 3 and older in select states. Tests vary by location.</p>
                                <div className="form-group">
                                    <label htmlFor="location" className="text-info">Where do you live?</label><br/>
                                    <input type="text" name="location" id="location" className="form-control"
                                        onChange={(e) => onSearch(e)}
                                    />
                                    {
                                        error.locationError?
                                            <p className={"error-text"}>{error.locationError}</p>:
                                            <p className={"help-text"}>Zip Code, or city and state</p>
                                    }
                                </div>
                                <div className="form-group">
                                    <span className={"fa fa-info-circle"}>&nbsp;</span>
                                    <span className={"info-text"}>
                                        Please provide all valid and authentic information so that we can verify them properly.
                                    </span>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn red-btn btn-md btn-block" value=""
                                        onClick={onProcessSearch}
                                    > Schedule a Test
                                        <span className={onProcessingSearch ? "spinner-border" : ""}></span>
                                    </button>
                                </div>
                                <div className="form-group text-right">
                                    <span className={"status-link"} onClick={onCheckBooking}>
                                        Check my booking(s)
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CovidTestGetStarted;
