import React, { useEffect, useState } from 'react';
import { productActions, cartAction } from "../../Redux/Actions/index";
import { useDispatch, useSelector } from 'react-redux';

import "../Categories/categories.scss";
import $ from "jquery";

const Category = (props) => {
    let textLimit = 200;
    const dispatch = useDispatch();

    const authData = useSelector((state) => state.authReducer.authData);
    const productList = useSelector(state => state.productReducer.productLists);
    const categoryList = useSelector(state => state.productReducer.category);
    const activeCategory = useSelector(state => state.productReducer.categoryId);
    const commonData = useSelector((state) => state.commonReducer);
    const [productArray, setProductArray] = useState([]);
    const [searchProduct, setSearchProduct] = useState('');
    const [newItem, setNewItem] = useState({});
    const [quantity, setQuantity] = useState({});
    const [isSubscription, setIsSubscription] = useState(false);
    const { checkQuantitySuccess } = useSelector((state) => state.productReducer);

    const onclickAction = async (item) => {
        props.history.push(`/categories/${item.slug}`)
        await dispatch(productActions.getProductDetail(item.slug))
        dispatch({ type: "CLEAR_SEARCH_LIST" });
    }

    useEffect(() => {
        (async () => {
            await dispatch(productActions.getCategory())
            if (props?.location?.state?.detail === false) {
                const state = { ...props.history.location };
                delete state.detail;
                props.history.replace({ ...props.history.location, state });
                console.log("props*", props)
            } else {
                await dispatch(productActions.getProductByCategory(activeCategory))

            }
        })();
    }, []);
    useEffect(() => {
        setProductArray(productList);
    }, [productList])

    const addToCart = async (item, quantity, isSubscription) => {
        setNewItem(Object.assign({}, item));
        setQuantity(quantity);
        setIsSubscription(isSubscription);
        await dispatch(productActions.checkValidQuantity(item.id, quantity));
    }

    useEffect(() => {
        if(checkQuantitySuccess) {
            dispatch(cartAction.addToCart(newItem, quantity, isSubscription));
            $('#cart-inner').modal('show');
            dispatch(productActions.resetCheckQuantity());
        }
    }, [checkQuantitySuccess]);

    const onChangeCategory = async (e, category) => {
        e.preventDefault();
        if (category !== activeCategory) {
            setSearchProduct('');
            await dispatch({ type: "CLEAR_PRODUCT_LIST" })
            await dispatch(productActions.selectCategoryPage(category));
            // setActiveCategory(category)
            await dispatch(productActions.getProductByCategory(category));
        }
    };

    const onProductSearch = (value) => {
        const filtered = productList.filter((product) => product.name?.toLowerCase().includes(value));
        setProductArray(filtered);
        setSearchProduct(value);
    };

    return (<div className="categories-main">
        <div className="container">
            <h2>Our Home Health Tests</h2>
            <div className="products-category-search">
                <div className="category-card">
                    <div className="category-tabination">
                        <ul>
                            <li onClick={(e) => { onChangeCategory(e, "all-tasks") }} className={activeCategory === "all-tasks" ? "active" : null}>All Tests</li>
                            {categoryList?.map((data, i) => {
                                return <li key={i} onClick={(e) => { onChangeCategory(e, data.slug) }} className={activeCategory === data.slug ? "active" : null}>{data.name}</li>
                            })}

                            {/* <li onClick={(e) => { onChangeCategory(e, "mens-health") }} className={activeCategory === "mens-health" ? "active" : null}>Men's Health</li>
                            <li onClick={(e) => { onChangeCategory(e, "womens-health") }} className={activeCategory === "womens-health" ? "active" : null}>Women's Health</li>
                            <li onClick={(e) => { onChangeCategory(e, "energy-wealth") }} className={activeCategory === "energy-wealth" ? "active" : null}>Energy + Weight</li>
                            <li onClick={(e) => { onChangeCategory(e, "sexual-health") }} className={activeCategory === "sexual-health" ? "active" : null}>Sexual Health</li> */}
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-md-12 input-group search-category">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search Tests..."
                                onChange={(e) => onProductSearch(e.target.value.toLowerCase())}
                                value={searchProduct}
                            />
                                <div className="input-group-append">
                                   <span className={"input-group-text"}>
                                      <i className="fas fa-search"></i>
                                    </span>
                                </div>
                        </div>
                        {commonData.loading === false ?  productArray?.length > 0 ? productArray?.map((data, index) => {
                            return (<div className="col-md-4" key={index}>
                                <div className="products-card">
                                    <div className="price-tag" onClick={() => addToCart(data, 1, isSubscription)}>
                                        <p>
                                            <i class="fas fa-cart-plus"></i>
                                        </p>
                                    </div>
                                    <div className="products-img">
                                        <img src={ data?.image_name} />
                                    </div>
                                    {
                                        authData.userType !== 'Employee User' && data.couponCode &&
                                        <div className={"coupon-code"}>
                                           Coupon Code: <span>{data.couponCode}</span>
                                        </div>
                                    }
                                    <div className="products-card-text">
                                        <h4>{data.name}</h4>
                                        <p>{data?.description?.length > textLimit ? `${data.description.slice(0, textLimit)}....see more` : data.description.slice(0, textLimit)}</p>
                                        {/* <p>{data.description?.slice(0, this.textLimit)}</p> */}
                                        <p className="price-test">${data.price}</p>
                                        <div className="text-center button-fixed">
                                            <button onClick={() => onclickAction(data)} className="red-btn-border">Learn More</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                        }) : <p style={{fontWeight: 600,textAlign: 'center',    width: '100%',
                        margin: '40px' }}>No Items Added..!!</p>  : <p  style={{fontWeight: 500,textAlign: 'center',    width: '100%',
                        margin: '40px' }} >LOADING...</p> }
                    </div>

                </div>
            </div>
        </div>
    </div>);
}

export default Category;
