import React, { Component } from 'react'
import { connect } from 'react-redux';
import "../AboutUs/aboutUs.scss";
import { bindActionCreators } from 'redux';
import { getPageContent } from '../../Redux/Actions/pageAction';

class About_Us extends Component {

    constructor(props) {
        super(props)

        this.state = {
            content: ""
        }
    }

    async componentDidMount() {
        this.fetchPageContent(this.props.match.params.pageSlug);
    }

    fetchPageContent = async (pageSlug) => {
        const result = await this.props.getPageContent(pageSlug);
        if (result ?.data ?.data) {
            this.setState({
                content: result.data.data.content?.replace(/&nbsp;/g, ' '),
                title: result.data.data.title
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.pageSlug !== this.props.match.params.pageSlug) {
            this.fetchPageContent(nextProps.match.params.pageSlug);
        }
    }




    render() {
        return (

            <div>
                <main className="mainContainer">

                    <section className="home-product-section">
                        <div className="container">


                            <div className="container-fluid">
                            <h1><b>{this.state.title?.toLowerCase()}</b></h1>

                                <div className={"content"}>


                                        <div dangerouslySetInnerHTML={{ __html: this.state.content ? this.state.content : `Coming soon!` }}></div>

                                 </div>
                            </div>

                        </div>
                    </section>

                </main>
            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getPageContent }, dispatch);
}

export default connect(null, mapDispatchToProps)(About_Us);
