import React  from "react";
import { useState, useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { authActions } from "../../Redux/Actions/index";
import { validateEmail } from "../../Validation";
import '../Signin/signin.scss';
import {NotificationManager} from "react-notifications";

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const [state, setState] = useState({email:""})
    const [error, setError] = useState({
        email: [],
      });
    const commonData = useSelector((state) => state.commonReducer);
    const authData = useSelector((state) => state.authReducer);
    const handleChange = async (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        const result = await validateEmail(state);
        if(result?.isFormValid === true){
            setError({email:[]})
            await dispatch(authActions.forgetPassword(state))
        } else {
            setError(result?.error)
        }

    }


    useEffect(() => {
        dispatch(authActions.resetSuccess());
    }, [authData?.success]);

    return (
        <div className="signup-form my-5" style={{position: 'relative'}}>
            <div className="container">
                <form className="forgot-form">
                    {
                        authData?.success === true ?
                            NotificationManager.success(authData?.message) :
                            authData?.success === false ?
                                NotificationManager.error(authData?.message):
                                null
                    }
                    <div className="form-group">
                        <label>Email Address</label>
                        <input type="text" name="email" className="form-control" value={state?.email} onChange={(e) => handleChange(e)} />
                        {error?.email[0] ? <p className="error-validation">{error?.email[0]}</p> : null}
                    </div>
                    <div className="text-center mb-5">
                        <button className="red-btn" onClick={(e) => onSubmit(e)}>Submit
                        <span className={commonData.loading === true ? "spinner-border" : ""}></span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ForgotPassword
