import Api from "../../Api";
import { handleError } from "./commonAction";

export const userDataById = (id) => async (dispatch) => {
  console.log('============userDataById');
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/users/${id}`);

    console.log("USER_DATA_BY_ID", res)

    await dispatch({
      type: "USER_DATA_BY_ID",
      payload: { data: res?.data?.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};


export const updateProfile = (body) => async (dispatch) => {
  try {


    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.patch(`/api/v1/users/${body.id}/updateProfile`, body);

    // console.log("USER_UPDATE_PROFILE",res)
    await dispatch({
      type: "USER_UPDATE_PROFILE",
      payload: { data: res?.data?.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};

export const validateAddress = (body) => async (dispatch) => {
  try {

    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/users/${body.id}/validate-address`, { ...body });
    dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};

export const getCountries = () => async (dispatch) => {
  try {

    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/cart/get-countries-list`);

    await dispatch({
      type: "GET_COUNTRIES_LIST",
      payload: { data: res?.data?.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};


export const getStates = (countryId) => async (dispatch) => {
  if(!countryId) {
    return;
  }
  try {

    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/cart/get-states-list/${countryId}`);
    console.log(`response for`, res)
    await dispatch({
      type: "GET_STATE_LIST_BY_COUNTRY",
      payload: { data: res?.data?.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};




export const getCities = (stateId) => async (dispatch) => {
  try {

    if(!stateId) {
      return;
    }
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/cart/get-cities-list/${stateId}`);

    await dispatch({
      type: "GET_CITIES_BY_STATE",
      payload: { data: res?.data?.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};

export const resetProfileMessage = () => (dispatch) => {
  dispatch({ type: "RESET_PROFILE_MESSAGE" });
};

export const userAgreementById = (id) => async (dispatch) => {
  try {
    const res = await Api.get(`/api/v1/org-user-agreement/${id}`);
    return res.data;
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};

export const addPaymentMethod = (payload) => async (dispatch) => {
  try {
    const userId = JSON.parse(localStorage.getItem("userData")).id;
    const res = await Api.post(`/api/v1/stripe/payment-method/${userId}`, payload);
    return res.data;
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};

export const userPaymentMethod = (userId) => async (dispatch) => {
  try {
    const res = await Api.get(`/api/v1/stripe/payment-method/${userId}`);
    return res.data;
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};

export const removePaymentMethod = (userId) => async (dispatch) => {
  try {
    const res = await Api.delete(`/api/v1/stripe/payment-method/${userId}`);
    return res.data;
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};
