import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { userReducer } from "./userReducer";
import { commonReducer } from "./commonReducer";
import { productReducer } from "./productReducer";
import { cartReducer } from "./cartReducer";
import { orderReducer } from "./orderReducer";
import { helpReducer } from "./helpReducer";
import { kitReducer } from "./kitReducer";
import { covidReducer } from "./covidReducer";

const appReducer = combineReducers({
  authReducer,
  userReducer,
  commonReducer,
  productReducer,
  cartReducer,
  orderReducer,
  helpReducer,
  kitReducer,
  covidReducer,
});

let rootReducer = null;
export default rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};
