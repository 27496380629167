import React, {useCallback, useEffect, useState} from "react";
import {employeeAction} from "../../Redux/Actions/index";
import { useDispatch, useSelector } from "react-redux";
import "../Employees/employees.scss";
import {NotificationManager} from "react-notifications";
import {Button, Modal} from "react-bootstrap";
import moment from 'moment';
import Visa from "../../Images/visa.png";
import Master from "../../Images/master.png";
import AmericanExpress from "../../Images/american_express.png";

const EmployeesPaymentHistory = (props) => {
    let dispatch = useDispatch();
    const authData = useSelector((state) => state.authReducer.authData);

    const [isLoadingEmployeesHistory, setIsLoadingEmployeesHistory] = useState(false);
    const [paymentHistoryList, setPaymentHistoryList] = useState([]);
    const [loadingPaymentDetails, setLoadingPaymentDetails] = useState({});
    const [paymentDetail, setPaymentDetail] = useState({});

    useEffect(() => {
        (async () => {
            if(authData.isOrganisationUser === 0) {
                return;
            }
            await fetchEmployeesPaymentHistory();
        })();
    }, []);

    useEffect(() => {
        if(authData.isOrganisationUser === 0) {
            NotificationManager.error('You do not have permission to access this page');
            props.history.push('/');
            return;
        }
    }, [authData.isOrganisationUser]);

    const fetchEmployeesPaymentHistory = async (searchValue) => {
        setIsLoadingEmployeesHistory(true);
        const employeePaymentHistoryList = await dispatch(employeeAction.getEmployeesPaymentHistoryList(searchValue));
        setIsLoadingEmployeesHistory(false);
        if(employeePaymentHistoryList.isSuccess) {
            setPaymentHistoryList(employeePaymentHistoryList.data);
        } else {
            NotificationManager.error(employeePaymentHistoryList.errorMessage);
            return ;
        }
    };

    const getPaymentDetails = async (chargeId, index) => {
        setLoadingPaymentDetails({[index]: true});
        const paymentDetail = await dispatch(employeeAction.getEmployeesPaymentDetail(chargeId));
        setLoadingPaymentDetails({[index]: false});
        if(paymentDetail.isSuccess) {
            setPaymentDetail(paymentDetail.data);
        } else {
            NotificationManager.error(paymentDetail.errorMessage);
            return ;
        }
    };

    const handleClosePaymentDetails = () => {
        setPaymentDetail({});
    };

    const getTotalPayment = () => {
        let sum = 0;
        paymentHistoryList.map((paymentHistory) => sum+=paymentHistory.amount);
        return sum;
    };

    return (
        <div className="employees-main payment-history">
            <div className="container mt-0">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="total-container">
                            <div className="total float-right mb-1">
                                <b>TOTAL PAYMENT</b>: ${getTotalPayment()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Product</th>
                            <th>User Name</th>
                            <th>User Email</th>
                            <th>Total</th>
                            <th>Status</th>
                            <th>Payment At</th>
                            <th style={{width: '12%'}}>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            isLoadingEmployeesHistory?
                                (<tr>
                                    <td className="text-center" colSpan={6}>
                                    <span
                                        className={"spinner-border"}
                                    ></span>
                                    </td>
                                </tr>) :
                                (
                                    paymentHistoryList.length === 0?
                                        (
                                            <tr><td className="text-center" colSpan={7}>
                                                There is no payment history for your organization
                                            </td></tr>
                                        ):
                                        paymentHistoryList.map((paymentHistory, index) => {
                                            return (
                                                <tr key={paymentHistory.id}>
                                                    <td className={'d-flex flex-column'}>
                                                        <span>{paymentHistory.productName}</span>
                                                        { paymentHistory.orderId && <span style={{'font-size': '12px', 'color': '#444'}}>({paymentHistory.orderId})</span>}
                                                    </td>
                                                    <td>{paymentHistory.fullName}</td>
                                                    <td>{paymentHistory.email}</td>
                                                    <td>${paymentHistory.amount}</td>
                                                    <td><span className={paymentHistory.status === 'succeeded'? 'active': 'inactive'}> {paymentHistory.status}</span></td>
                                                    <td>{paymentHistory.created}</td>
                                                    <td><button className="red-btn-border" onClick={() => getPaymentDetails(paymentHistory.id, index)}>
                                                        Details
                                                        {
                                                            loadingPaymentDetails[index] &&
                                                            <span
                                                                className={"spinner-border small-spin"}
                                                            ></span>
                                                        }
                                                    </button></td>
                                                </tr>
                                            )
                                        })
                                )
                        }
                        </tbody>
                    </table>
                </div>
                <Modal show={Object.keys(paymentDetail).length !== 0} onHide={handleClosePaymentDetails}>
                    <Modal.Header closeButton>
                        <Modal.Title>Payment Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={'payment-detail-container'}>
                            <div>
                                <label className={"title"}>Transaction Id:</label>
                                <label className={"value"}>{paymentDetail.balance_transaction}</label>
                            </div>
                            <div>
                                <label className={"title"}>Order Id:</label>
                                <label className={"value"}>{paymentDetail.metadata?.orderId}</label>
                            </div>
                            <div>
                                <label className={"title"}>Product:</label>
                                <label className={"value"}>{paymentDetail.metadata?.productName}</label>
                            </div>
                            <div>
                                <label className={"title"}>User Name:</label>
                                <label className={"value"}>{paymentDetail.metadata?.fullName}</label>
                            </div>
                            <div>
                                <label className={"title"}>User Email:</label>
                                <label className={"value"}>{paymentDetail.metadata?.email}</label>
                            </div>
                            <div>
                                <label className={"title"}>Amount:</label>
                                <label className={"value"}>{paymentDetail.amount? `$${paymentDetail.amount_captured/100}`: ''}</label>
                            </div>
                            <div>
                                <label className={"title"}>Payment Date:</label>
                                <label className={"value"}>{moment(paymentDetail.created*1000).format('MM/DD/YYYY, hh:mm a')}</label>
                            </div>
                            <div>
                                <label>Payment Mode</label>
                                <div className={'card-detail-container'}>
                                    <div>
                                        { paymentDetail.payment_method_details?.card.brand === 'visa' && <img src={Visa} alt="visa"/>}
                                        { paymentDetail.payment_method_details?.card.brand === 'mastercard' && <img src={Master} alt="master_card"/>}
                                        { paymentDetail.payment_method_details?.card.brand === 'amex' && <img src={AmericanExpress} alt="american_express"/>}
                                    </div>
                                    <div className="card-detail">
                                        <div>**** **** **** {paymentDetail.payment_method_details?.card.last4}</div>
                                        <div className={"ending"}>Ending in <span>{`${paymentDetail.payment_method_details?.card.exp_month}/${paymentDetail.payment_method_details?.card.exp_year}` }</span></div>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClosePaymentDetails}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default EmployeesPaymentHistory;
