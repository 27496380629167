import React from 'react'
import { Link } from 'react-router-dom'
import "./not-found-404.scss";

class NotFound404 extends React.Component {


  render() {
    return (
      <div>
        <main className="mainContainer">
          <div className="container">
            <div className="signup">
              <form>
                <div className="none-page">
                  <h2>404 - Page Not Found</h2>
                  <p>Sorry no page found with url. Please continue shopping by going on <Link to={'/'}>Home</Link>. </p>
                </div>
           
 </form>
            </div>
          </div>
        </main>
      </div>
    )
  }
}

export default NotFound404;
