import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {NotificationManager} from 'react-notifications';
import moment from 'moment';
import PhoneInput, { formatPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input';

import { uploadImage, removeImage } from '../../Utils/fileUpload';
import { covidAction } from "../../Redux/Actions";
import {Button, Modal} from "react-bootstrap";
import Visa from "../../Images/visa.png";
import Master from "../../Images/master.png";
import AmericanExpress from "../../Images/american_express.png";
import {Elements} from "@stripe/react-stripe-js";
import PaymentMethod from "./PaymentMethod";
import {loadStripe} from "@stripe/stripe-js";
const stripeElement = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

const CovidVisitInfo = (props) => {
    const dispatch = useDispatch();
    const covidReducer = useSelector((state) => state.covidReducer);
    const testType = covidReducer.searchLocationDetail.testType;
    const initError = {
        date_of_birth: '',
        patient_relationship: '',
        patient_first_name: '',
        patient_last_name: '',
        address_line_1: '',
        address_line_2: '',
        country: '',
        state: '',
        city: '',
        zipCode: '',
        gender: '',
        race: '',
        ethnicity: '',
        patient_email: '',
        patient_mobile: '',
        is_primary_phone: '',
        primary_phoneNumber: '',
        received_vaccine: '',
        vaccine_dose: '',
        vaccine_manufacturer_1: '',
        vaccine_manufacturer_2: '',
        taken_2_dose: '',
        first_dose_date: '',
        second_dose_date: '',
        first_time_vaccine: '',
        previous_test_positive: '',
        medical_condition: '',
        is_pregnant: '',
        payment_select: '',
        heath_insurance: '',
        insurance_company: '',
        insurance_id: '',
        front_insurance_card_img: '',
        back_insurance_card_img: '',
        group_number: '',
        policy_holder: '',
        confirm_insurance_id: '',
        ssn: '',
        driver_license: '',
        hhs_state: '',
        health_info_available: '',
        is_acknowledge: '',
        confirm_no_health_insurance: '',
        no_medical_condition_selected: '',
    };
    const [showTimeComplete, setShowTimeComplete] = useState(false);
    const [timeLeft, setTimeLeft] = useState(1500);
    const [timer, setTimer] = useState({h: '', m: '', s: ''});
    const [onSavingVisitInfo, setOnSavingVisitInfo] = useState(false);
    const [visitInfo, setVisitInfo] = useState({
        date_of_birth: '',
        patient_relationship: '',
        patient_first_name: '',
        patient_last_name: '',
        address_line_1: '',
        address_line_2: '',
        country: '',
        state: '',
        city: '',
        zipCode: '',
        gender: '',
        race: '',
        ethnicity: '',
        patient_email: '',
        patient_mobile: '',
        is_primary_phone: '',
        primary_phoneNumber: '',
        received_vaccine: '',
        first_time_vaccine: '',
        previous_test_positive: '',
        vaccine_dose: '',
        vaccine_manufacturer_1: '',
        vaccine_manufacturer_2: '',
        taken_2_dose: '',
        first_dose_date: '',
        second_dose_date: '',
        medical_condition: '',
        medical_issues: {
            is_asthma: false,
            is_cirrhosis: false,
            is_cancer: false,
            is_diabetes: false,
            is_hard_cough: false,
            is_obesity: false,
            is_hypertension: false,
            is_kidney_failure: false,
            is_heart_problem: false,
        },
        is_pregnant: '',
        payment_select: testType === 'Antigen'? '1': '',
        heath_insurance: '',
        insurance_company: '',
        insurance_id: '',
        front_insurance_card_img: '',
        back_insurance_card_img: '',
        group_number: '',
        policy_holder: 'self',
        confirm_insurance_id: '',
        health_info_available: '',
        ssn: '',
        driver_license: '',
        hhs_state: '',
        is_acknowledge: '',
        confirm_no_health_insurance: '',
        payment_charge_id: '',
        test_type: testType,
    });
    const [error, setError] = useState(initError);
    const [phoneInput, setPhoneInput] = useState('');
    const [primartPhoneInput, setPrimartPhoneInput] = useState('');
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [frontImageUploading, setFrontImageUploading] = useState(false);
    const [backImageUploading, setBackImageUploading] = useState(false);
    const frontInsurance = React.createRef();
    const backInsurance = React.createRef();

    useEffect(() => {
        (async () => {
            if(!covidReducer.searchLocation || !testType) {
                props.history.push('/covid-19-testing');
                return
            }
        })();
        renderPhoneInput(visitInfo.patient_mobile || null, 'phoneInput');
        renderPhoneInput(visitInfo.primary_phoneNumber || null, 'primaryPhoneInput');
    }, []);

    useEffect(() => {
        // exit early when we reach 0
        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
            secondsToTime(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);

    useEffect(() => {
        if(covidReducer.error) {
            NotificationManager.error('Something went wrong. Please try again.');
            dispatch(covidAction.resetError());
        }
    }, [covidReducer.error]);

    function handlePhoneNumberChange(value, type) {
        let property = ''
        if(type === 'phoneInput') {
            property = 'patient_mobile'
        }
        if(type === 'primaryPhoneInput') {
            property = 'primary_phoneNumber'
        }
        setVisitInfo((prevState) => ({
            ...prevState,
            [property]: value
        }));
        setError((prevState) => ({ ...prevState, [property]: false }));

    }

    const renderPhoneInput = (userPhone, type) => {
        const phnInput = <PhoneInput
            defaultCountry="US"
            countries={["US"]}
            addInternationalOption={false}
            class="form-control"
            placeholder="(xxx) xxx-xxxx"
            value={userPhone? formatPhoneNumber(userPhone): ''}
            limitMaxLength={true}
            onChange={(value) => handlePhoneNumberChange(value, type)}/>;

        if(type === 'phoneInput') {
            setPhoneInput(phnInput);
        }
        if(type === 'primaryPhoneInput') {
            setPrimartPhoneInput(phnInput);
        }
    };

    const secondsToTime = (secs) => {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        setTimer(obj);
        if(minutes === 0 && seconds === 0) {
            setShowTimeComplete(true);
        }
    };

    const onSelectNewTime = () => {
        props.history.push('/covid-19-testing/covid-scheduler');
        return;
    };

    const appointmentDateTime = () => {
        const appointmentDate = covidReducer.appointmentDetail?.date;
        if(!appointmentDate) {
            return '';
        }
        return moment(appointmentDate).format('dddd MMMM DD YYYY');
    };

    const getAppointmentStartTime = () => {
        const startTime = covidReducer.appointmentDetail?.start;
        if(!startTime) {
            return '';
        }
        return startTime;
    };

    const getAppointmentLocationName = () => {
        const name = covidReducer.searchLocationDetail?.name;
        if(!name) {
            return '';
        }
        return name;
    };

    const getAppointmentLocationAddress = () => {
        const address = `${covidReducer.searchLocationDetail?.street}, ${covidReducer.searchLocationDetail?.city}, 
        ${covidReducer.searchLocationDetail?.state} - ${covidReducer.searchLocationDetail?.zipCode}`;
        if(!address) {
            return '';
        }
        return address;
    };

    const handleDOBChange = (event) => {
        const { name, value } = event.target;
        setVisitInfo((prevState) => ({
            ...prevState,
            [name]: value
        }));
        setError((prevState) => ({ ...prevState, date_of_birth: false }));
    };

    const handleDoseDateChange = (event) => {
        const { name, value } = event.target;
        setVisitInfo((prevState) => ({
            ...prevState,
            [name]: value
        }));
        setError((prevState) => ({ ...prevState, [name]: false }));
    };

    function handleRadioChange(event) {
        const { name, value } = event.target;
        setVisitInfo((prevState) => ({ ...prevState, [name]: value }));
        setError((prevState) => ({ ...prevState, [name]: null }));
    }

    function handleTxtChange(event) {
        const { name, value } = event.target;
        if(name === 'zipCode') {
            const regEx = /^\b\d{0,5}\b$/;
            if (value === '' || regEx.test(value)) {
                setVisitInfo((prevState) => ({ ...prevState, [name]: value }));
                setError((prevState) => ({ ...prevState, [name]: null }));
            }
            return;
        }
        setVisitInfo((prevState) => ({ ...prevState, [name]: value }));
        setError((prevState) => ({ ...prevState, [name]: null }));
    }

    function handleAcknowledgeChange(event) {
        const { name, checked } = event.target;
        setVisitInfo((prevState) => ({
            ...prevState,
            [name]: checked
        }));
        if(checked) {
            setError(prev => ({
                ...prev,
                is_acknowledge: null
            }))
        }
    }

    function handleConfirmHSSChange(event) {
        const { name, checked } = event.target;
        setVisitInfo((prevState) => ({
            ...prevState,
            [name]: checked
        }));
        if(checked) {
            setError(prev => ({
                ...prev,
                confirm_no_health_insurance: null
            }))
        }
    }

    function handleMedicalConditionChange(event) {
        const { name, checked } = event.target;
        setVisitInfo((prevState) => ({
            ...prevState,
            medical_issues: {
                ...prevState.medical_issues,
                [name]: checked
            }
        }));
        setError((prevState) => ({ ...prevState, no_medical_condition_selected: false }));
    }

    const validateForm = () => {
        let isError = false;
        const errorObj = {};
        if (!visitInfo.date_of_birth) {
            errorObj.date_of_birth =  'This field is required';

            isError = true;
        }
        if (!visitInfo.patient_relationship) {
            errorObj.patient_relationship =  'This field is required';
            isError = true;
        }
        if (!visitInfo.patient_first_name.trim()) {
            errorObj.patient_first_name = 'This field is required';
            isError = true;
        }
        if (!visitInfo.patient_last_name.trim()) {
            errorObj.patient_last_name = 'This field is required';
            isError = true;
        }
        if (!visitInfo.address_line_1.trim()) {
            errorObj.address_line_1 = 'This field is required';
            isError = true;
        }
        if (!visitInfo.country.trim()) {
            errorObj.country = 'This field is required';
            isError = true;
        }
        if (!visitInfo.state.trim()) {
            errorObj.state = 'This field is required';
            isError = true;
        }
        if (!visitInfo.city.trim()) {
            errorObj.city = 'This field is required';
            isError = true;
        }
        if (!visitInfo.zipCode.trim()) {
            errorObj.zipCode = 'This field is required';
            isError = true;
        }
        if (!visitInfo.gender) {
            errorObj.gender = 'This field is required';
            isError = true;
        }
        if (!visitInfo.race) {
            errorObj.race = 'This field is required';
            isError = true;
        }
        if (!visitInfo.ethnicity) {
            errorObj.ethnicity = 'This field is required';
            isError = true;
        }
        if (
            !visitInfo.patient_email ||
            /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
                visitInfo.patient_email
            ) === false
        ) {
            errorObj.patient_email = 'This field is required';
            isError = true;
        }
        if (
            !visitInfo.patient_mobile ||
            !isPossiblePhoneNumber(visitInfo.patient_mobile)
        ) {
            errorObj.patient_mobile = 'This field is required';
            isError = true;
        }
        if (!visitInfo.is_primary_phone) {
            errorObj.is_primary_phone = 'This field is required';
            isError = true;
        }
        if (visitInfo.is_primary_phone === '0' && (!visitInfo.primary_phoneNumber || !isPossiblePhoneNumber(visitInfo.primary_phoneNumber))) {
            errorObj.primary_phoneNumber = 'This field is required';
            isError = true;
        }
        if (!visitInfo.received_vaccine) {
            errorObj.received_vaccine = 'This field is required';
            isError = true;
        }
        if (visitInfo.received_vaccine === '1' && !visitInfo.vaccine_dose) {
            errorObj.vaccine_dose = 'This field is required';
            isError = true;
        }
        if (visitInfo.received_vaccine === '1') {
            if(visitInfo.vaccine_dose === '1' && !visitInfo.first_dose_date) {
                errorObj.first_dose_date = 'This field is required';
                isError = true;
            }
            if (visitInfo.vaccine_dose === '1' && visitInfo.first_dose_date && new Date(visitInfo.first_dose_date) > new Date()) {
                errorObj.first_dose_date = 'This field is required';
                isError = true;
            }
            if (visitInfo.vaccine_dose === '1' && !visitInfo.vaccine_manufacturer_1) {
                errorObj.vaccine_manufacturer_1 = 'This field is required';
                isError = true;
            }
            if (visitInfo.vaccine_dose === '2' && !visitInfo.vaccine_manufacturer_2) {
                errorObj.vaccine_manufacturer_2 = 'This field is required';
                isError = true;
            }
            if (visitInfo.vaccine_dose === '2' && !visitInfo.taken_2_dose) {
                errorObj.taken_2_dose = 'This field is required';
                isError = true;
            }
            if (visitInfo.vaccine_dose === '2' && visitInfo.taken_2_dose === '1') {
                if(!visitInfo.first_dose_date) {
                    errorObj.first_dose_date = 'This field is required';
                    isError = true;
                }
                if (visitInfo.first_dose_date && new Date(visitInfo.first_dose_date) > new Date()) {
                    errorObj.first_dose_date = 'This field is required';
                    isError = true;
                }
                if(!visitInfo.second_dose_date) {
                    errorObj.second_dose_date = 'This field is required';
                    isError = true;
                }
                if (visitInfo.second_dose_date && new Date(visitInfo.second_dose_date) > new Date()) {
                    errorObj.second_dose_date = 'This field is required';
                    isError = true;
                }
            }
        }
        if (!visitInfo.first_time_vaccine) {
            errorObj.first_time_vaccine = 'This field is required';
            isError = true;
        }
        if (visitInfo.first_time_vaccine === '0' && !visitInfo.previous_test_positive) {
            errorObj.previous_test_positive = 'This field is required';
            isError = true;
        }
        if (!visitInfo.medical_condition) {
            errorObj.medical_condition = 'This field is required';
            isError = true;
        }
        let selectedMedicalIssue = false;
        Object.keys(visitInfo.medical_issues).forEach((key) => {
            if(visitInfo.medical_issues[key]) {
                selectedMedicalIssue = true;
            }
        });
        if(visitInfo.medical_condition === '1' && !selectedMedicalIssue) {
            errorObj.no_medical_condition_selected = true;
            isError = true;
        }
        if (!visitInfo.payment_select) {
            errorObj.payment_select = 'This field is required';
            isError = true;
        }
        if (visitInfo.payment_select === '0') {
            if(!visitInfo.heath_insurance) {
                errorObj.heath_insurance = 'This field is required';
                isError = true;
            }
            if (visitInfo.heath_insurance === '1') {
                if(!visitInfo.insurance_company.trim()) {
                    errorObj.insurance_company = 'This field is required';
                    isError = true;
                }
                if(!visitInfo.insurance_id.trim()) {
                    errorObj.insurance_id = 'This field is required';
                    isError = true;
                }
                if(!visitInfo.confirm_insurance_id.trim()) {
                    errorObj.confirm_insurance_id = 'This field is required';
                    isError = true;
                }
                if(visitInfo.insurance_id !== visitInfo.confirm_insurance_id) {
                    errorObj.confirm_insurance_id = 'Incorrect confirm member number';
                    isError = true;
                }
                if(!visitInfo.policy_holder.trim()) {
                    errorObj.policy_holder = 'This field is required';
                    isError = true;
                }
                if(!visitInfo.front_insurance_card_img.trim()) {
                    errorObj.front_insurance_card_img = 'This field is required';
                    isError = true;
                }
                if(!visitInfo.back_insurance_card_img.trim()) {
                    errorObj.back_insurance_card_img = 'This field is required';
                    isError = true;
                }
            }
            if (visitInfo.heath_insurance === '0') {
                if(!visitInfo.confirm_no_health_insurance) {
                    errorObj.confirm_no_health_insurance = 'This field is required';
                    isError = true;
                }
                if(!visitInfo.ssn.trim() && !visitInfo.driver_license.trim() && !visitInfo.hhs_state.trim()) {
                    errorObj.ssn = 'This field is required';
                    errorObj.driver_license = 'This field is required';
                    errorObj.hhs_state = 'This field is required';
                    isError = true;
                }
                if(!visitInfo.ssn.trim() && (!visitInfo.driver_license.trim() || !visitInfo.hhs_state.trim())) {
                    errorObj.driver_license = 'This field is required';
                    errorObj.hhs_state = 'This field is required';
                    isError = true;
                }
                if(visitInfo.ssn.trim()) {
                    errorObj.driver_license = null;
                    errorObj.hhs_state = null;
                }
                if(!visitInfo.ssn.trim() && (visitInfo.driver_license.trim() && visitInfo.hhs_state.trim())) {
                    errorObj.ssn = null;
                }
            }
        }

        if (!visitInfo.health_info_available) {
            errorObj.health_info_available = 'This field is required';
            isError = true;
        }
        if (!visitInfo.is_acknowledge) {
            errorObj.is_acknowledge = 'This field is required';
            isError = true;
        }

        if(isError) {
            setError(prev => {
                return {
                    ...prev,
                    ...initError,
                    ...errorObj
                }
            });
        }
        return isError;
    };

    const processSubmit = async (payload) => {
        setOnSavingVisitInfo(true);
        await dispatch(covidAction.saveVisitInfo(payload));
        const confirmAppointmentResponse = await dispatch(covidAction.confirmAppointment());
        setOnSavingVisitInfo(false);
        if(!confirmAppointmentResponse.isSuccess) {
            NotificationManager.error(confirmAppointmentResponse.errorMessage);
            if(confirmAppointmentResponse.resetCharge) {
                setVisitInfo(prev => ({
                    ...prev,
                    payment_charge_id: ''
                }));
            }
            return;
        }
        await dispatch(covidAction.setConfirmationCode(confirmAppointmentResponse.data.confirmationCode));
        props.history.push('/covid-19-testing/covid-scheduler/covid-confirmation');
    };

    const onSaveVisitInfo = async () => {
        let isError = validateForm();

        if(isError) {
            NotificationManager.error("Missing or invalid information. Please check the form again.");
            return;
        }
        setError(initError);
        // check if payment is from card
        if(visitInfo.payment_select === '1' && !visitInfo.payment_charge_id) {
            setShowPaymentModal(true);
            return;
        }

        await processSubmit(visitInfo);
    };

    const onCompletePayment = async (chargeId) => {
        if(!chargeId) {
            NotificationManager.error("Payment failed. Please try again");
            return;
        }
        const payload = {
            ...visitInfo,
            payment_charge_id: chargeId
        };
        await setVisitInfo(payload);
        setShowPaymentModal(false);
        await processSubmit(payload);
    };

    const triggerFrontInsuranceClick = () => {
        frontInsurance.current.click();
    };

    const removeInsurance = async (type) => {
        if(type === 'front_insurance_card_img') {
            setFrontImageUploading(true);
        } else {
            setBackImageUploading(true);
        }
        const key = visitInfo[type].split('amazonaws.com/')[1];
        await removeImage('accugenedx-files', key);
        setVisitInfo((prevState) => ({
            ...prevState,
            [type]: ''
        }));
        setError((prevState) => ({ ...prevState, [type]: 'Image required' }));
        if(type === 'front_insurance_card_img') {
            document.getElementById("frontFileUploadInp").value = "";
            setFrontImageUploading(false);
        } else {
            document.getElementById("backFileUploadInp").value = "";
            setBackImageUploading(false);
        }
    };

    const triggerBackInsuranceClick = () => {
        backInsurance.current.click();
    };

    const readImage = (image, type) => {
        if(type === 'front_insurance_card_img') {
            setFrontImageUploading(true);
        } else {
            setBackImageUploading(true);
        }
        let reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onloadend = async () => {
            if (reader?.result) {
                const filePath = await uploadImage(
                    {
                        dataUrl: reader.result,
                        format: image.type
                    },
                    'accugenedx-files',
                    `insurance`
                );
                if(type === 'front_insurance_card_img') {
                    setFrontImageUploading(false);
                } else {
                    setBackImageUploading(false);
                }
                setVisitInfo((prevState) => ({
                    ...prevState,
                    [type]: filePath
                }));
                setError((prevState) => ({ ...prevState, [type]: '' }));
            }
        };
    }

    const frontInsuranceSelectHandler = (e) => {
        if (e.target.files[0] && e.target.files[0].type) {
            let imgType = e.target.files[0].type.split('/');
            // console.log(imgType, 'imgType');
            if (imgType[0] === 'image') {
                setError((prevState) => ({ ...prevState, front_insurance_card_img: '' }));
                let frontImage = e.target.files[0];
                readImage(frontImage, 'front_insurance_card_img');
            } else {
                setError((prevState) => ({ ...prevState, front_insurance_card_img: 'Invalid Image' }));
            }
        }
        setError((prevState) => ({ ...prevState, front_insurance_card_img: 'Invalid Image' }));
    };

    const backInsuranceSelectHandler = (e) => {
        if (e.target.files[0] && e.target.files[0].type) {
            let imgType = e.target.files[0].type.split('/');
            // console.log(imgType, 'imgType');
            if (imgType[0] === 'image') {
                setError((prevState) => ({ ...prevState, back_insurance_card_img: '' }));
                let backImage = e.target.files[0];
                readImage(backImage, 'back_insurance_card_img');
            } else {
                setError((prevState) => ({ ...prevState, back_insurance_card_img: 'Invalid Image' }));
            }
        }
        setError((prevState) => ({ ...prevState, back_insurance_card_img: 'Invalid Image' }));
    };

    return (
        <div id="visit-container">
            <div id="visit-row" className="row justify-content-center">
                <div className={"visit-heading"}>
                    <h3>Complete your COVID-19 {testType} test registration</h3>
                    <p>
                        Your reservation will be held for the next 25 minutes while you complete your registration.
                        This time cannot be extended; however, you may be able to choose a new time if available.
                        In order to guarantee your spot, do not navigate away from this page until you've submitted the form.
                    </p>
                    <p>Your reservation expires in <b>{timer.m}:{timer.s} minutes</b></p>
                </div>
                <hr/>
                <div className={"visit-information"}>
                    <h4>Visit Information</h4>
                    <div className={'d-flex flex-column'}>
                        <span>{appointmentDateTime()} at {getAppointmentStartTime()}</span>
                        <span>{getAppointmentLocationName()}</span>
                        <span>{getAppointmentLocationAddress()}</span>
                    </div>
                </div>
                <hr/>
                <div className={"alert alert-info mt-2 w-50"}>
                    <span className={"fa fa-circle"}> &nbsp; Test type: <b>COVID-19 {testType} Test</b></span>
                </div>
                <div className={"visit-info"}>
                    <div className={"alert alert-secondary"}>
                        <span className={"fa fa-info-circle"}> &nbsp;</span>
                        <h6 className={'d-inline'}>
                            <b>Special instructions for minors</b>
                        </h6>
                        <ul>
                            <li><b>Age 3-15: &nbsp;</b> Parent or legal guardian needs to fill out form and accompany patient to visit</li>
                            <li><b>Age 16-17: &nbsp;</b>Parent or legal guardian needs to fill out form only.</li>
                            <li><b>Age 18 in Nebraska: &nbsp;</b>Parent or legal guardian needs to fill out form.</li>
                        </ul>
                    </div>
                </div>
                <div className={"visit-form"}>
                    <div>
                        <span className={"fa fa-info-circle"}></span> All fields are required unless marked optional.
                    </div>
                    <div className={"section"}>
                        <h5>Patient Information</h5>
                        <div className={"section-content col-md-12"}>
                            <div>
                                <div className="form-group" data-hasError={!!error.date_of_birth}>
                                    <label>
                                        <b>Patient date of birth</b>
                                    </label>
                                    <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                        <input type="date" name="date_of_birth" onChange={(e) => handleDOBChange(e)} />
                                    </div>
                                </div>
                                <div className="form-group" data-hasError={!!error.patient_relationship}>
                                    <label>
                                        <b>Relationship to patient</b>
                                    </label>
                                    <div className="col-md-12 p-0 pt-2 d-flex flex-column" onChange={(e) => handleRadioChange(e)}>
                                        <label className="radio-inline mb-2">
                                            <input
                                                type="radio"
                                                name="patient_relationship" value="patient" className={'mr-2'}
                                                checked={visitInfo.patient_relationship === 'patient'}
                                            />
                                            I am the patient
                                        </label>
                                        <label className="radio-inline mb-2">
                                            <input
                                                type="radio"
                                                name="patient_relationship" value="parent" className={'mr-2'}
                                                checked={visitInfo.patient_relationship === 'parent'}
                                            />
                                            Parent of the patient
                                        </label>
                                        <label className="radio-inline mb-2">
                                            <input
                                                type="radio"
                                                name="patient_relationship" value="legal_guardian" className={'mr-2'}
                                                checked={visitInfo.patient_relationship === 'legal_guardian'}
                                            />
                                            Legal guardian of the patient
                                        </label>
                                        <label className="radio-inline mb-2">
                                            <input
                                                type="radio"
                                                name="patient_relationship" value="auth_representative" className={'mr-2'}
                                                checked={visitInfo.patient_relationship === 'auth_representative'}
                                            />
                                            Auth. representative of the patient
                                        </label>
                                    </div>
                                </div>
                                <div className={"alert alert-secondary mt-2"}>
                                        <span className={"fa fa-info-circle"}> &nbsp;
                                            <b>If using insurance, be sure to enter your name exactly as it appears on your insurance card.</b></span>
                                </div>
                                <div className="form-group mt-4" data-hasError={!!error.patient_first_name}>
                                    <label>
                                        <b>Patient First Name</b>
                                    </label>
                                    <input type="text" name="patient_first_name"
                                           className="form-control"
                                           value={visitInfo.patient_first_name}
                                           onChange={(e) => handleTxtChange(e)}
                                    />
                                </div>
                                <div className="form-group mt-4" data-hasError={!!error.patient_last_name}>
                                    <label>
                                        <b>Patient Last name</b>
                                    </label>
                                    <input type="text" name="patient_last_name"
                                           className="form-control"
                                           value={visitInfo.patient_last_name}
                                           onChange={(e) => handleTxtChange(e)}
                                    />
                                </div>
                                <div className="form-group mt-4" data-hasError={!!error.address_line_1}>
                                    <label>
                                        <b>Address line 1</b>
                                    </label>
                                    <input type="text" name="address_line_1"
                                           className="form-control"
                                           value={visitInfo.address_line_1}
                                           onChange={(e) => handleTxtChange(e)}
                                    />
                                </div>
                                <div className="form-group mt-4">
                                    <label>
                                        <b>Address line 2</b>
                                    </label>
                                    <input type="text" name="address_line_2"
                                           className="form-control"
                                           value={visitInfo.address_line_2}
                                           onChange={(e) => handleTxtChange(e)}
                                    />
                                </div>
                                <div className="form-group mt-4 d-flex flex-row justify-content-between address">
                                   <div data-hasError={!!error.country}>
                                       <label>
                                           <b>Country</b>
                                       </label>
                                       <input type="text" name="country"
                                              className="form-control"
                                              value={visitInfo.country}
                                              onChange={(e) => handleTxtChange(e)}
                                       />
                                   </div>
                                   <div data-hasError={!!error.state}>
                                       <label>
                                           <b>State</b>
                                       </label>
                                       <input type="text" name="state"
                                              className="form-control"
                                              value={visitInfo.state}
                                              onChange={(e) => handleTxtChange(e)}
                                       />
                                   </div>
                                   <div data-hasError={!!error.city}>
                                       <label>
                                           <b>City</b>
                                       </label>
                                       <input type="text" name="city"
                                              className="form-control"
                                              value={visitInfo.city}
                                              onChange={(e) => handleTxtChange(e)}
                                       />
                                   </div>
                                    <div data-hasError={!!error.zipCode}>
                                        <label>
                                            <b>Zip Code</b>
                                        </label>
                                        <input type="text" name="zipCode"
                                               className="form-control"
                                               value={visitInfo.zipCode}
                                               onChange={(e) => handleTxtChange(e)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group mt-4">
                                    <label>
                                        <b>Demographic information</b>
                                    </label>
                                    <p className={'info-text'}>
                                        The Centers for Disease Control and Prevention (CDC) requires we collect this demographic information.
                                        We’re committed to providing equal access to COVID-19 {testType} testing.
                                        Your gender identity, race, or ethnicity are in no way limiting factors in scheduling your COVID-19 {testType} test.
                                    </p>
                                    <div className="col-md-12 p-0 pt-2 d-flex flex-column"
                                         onChange={(e) => handleRadioChange(e)}
                                         data-hasError={!!error.gender}
                                    >
                                        <label>
                                            <b>Gender</b>
                                        </label>
                                        <label className="radio-inline mb-2">
                                            <input
                                                type="radio"
                                                name="gender" value="female"
                                                checked={visitInfo.gender === 'female'} className={'mr-2'}
                                            />
                                            Female
                                        </label>
                                        <label className="radio-inline mb-2">
                                            <input
                                                type="radio"
                                                name="gender" value="male"
                                                checked={visitInfo.gender === 'male'} className={'mr-2'}
                                            />
                                            Male
                                        </label>
                                        <label className="radio-inline mb-2">
                                            <input
                                                type="radio"
                                                name="gender" value="no_specify"
                                                checked={visitInfo.gender === 'no_specify'} className={'mr-2'}
                                            />
                                            Prefer not to answer
                                        </label>
                                    </div>
                                    <div className="form-group mt-4 d-flex flex-row justify-content-start flex-basis">
                                        <div className={'mr-3'} data-hasError={!!error.race}>
                                            <label>
                                                <b>Race</b>
                                            </label>
                                            <select className="form-control"
                                                    defaultValue={''}
                                                    name="race"
                                                    onChange={(e) => handleTxtChange(e)}>
                                                <option value="" disabled>--Select--</option>
                                                <option value="American Indian or Alaska Native"> American Indian or Alaska Native </option>
                                                <option value="Asian"> Asian </option>
                                                <option value="Black or African American"> Black or African American </option>
                                                <option value="Hispanic"> Hispanic </option>
                                                <option value="Native Hawaiian or Other Pacific Islander"> Native Hawaiian or Other Pacific Islander </option>
                                                <option value="Other"> Other </option>
                                                <option value="Patient Refused"> Patient Refused </option>
                                                <option value="Unknown"> Unknown </option>
                                                <option value="White or Caucasian"> White or Caucasian </option>
                                            </select>
                                        </div>
                                        <div data-hasError={!!error.ethnicity}>
                                            <label>
                                                <b>Ethnicity</b>
                                            </label>
                                            <select className="form-control"
                                                    defaultValue={''}
                                                    name="ethnicity"
                                                    onChange={(e) => handleTxtChange(e)}>
                                                <option value="" disabled>--Select--</option>
                                                <option value="Hispanic or Latino"> Hispanic or Latino </option>
                                                <option value="Not Hispanic or Latino"> Not Hispanic or Latino </option>
                                                <option value="Patient Refused"> Patient Refused </option>
                                                <option value="Unknown"> Unknown </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"section"}>
                        <h5>Contact Information</h5>
                        <div className={"section-content col-md-12"}>
                            <p className={'info-text'}>
                                We will send your test results by email. Your contact information is only used for messages about your COVID-19 {testType} test.
                            </p>
                            <div>
                                <div className="form-group mt-4"
                                data-hasError={!!error.patient_email}>
                                    <label>
                                        <b>Email Address</b>
                                    </label>
                                    <input type="text" name="patient_email"
                                           className="form-control"
                                           value={visitInfo.patient_email}
                                           onChange={(e) => handleTxtChange(e)}
                                    />
                                </div>
                                <div className="form-group mt-4"
                                data-hasError={!!error.patient_mobile}>
                                    <label>
                                        <b>Mobile Number</b>
                                    </label>
                                        {phoneInput}
                                </div>
                                <div className={"mt-4"}>
                                    <div className="form-group"
                                         data-hasError={!!error.is_primary_phone}>
                                        <label>
                                            <b>Is your mobile number your primary number?</b>
                                        </label>
                                        <div className="col-md-12 p-0 pt-2 d-flex flex-row" onChange={(e) => handleRadioChange(e)}>
                                            <label className="radio-inline mr-3">
                                                <input
                                                    type="radio"
                                                    name="is_primary_phone" value="1" className={'mr-2'}
                                                    checked={visitInfo.is_primary_phone === '1'}
                                                />
                                                Yes
                                            </label>
                                            <label className="radio-inline">
                                                <input
                                                    type="radio"
                                                    name="is_primary_phone" value="0" className={'mr-2'}
                                                    checked={visitInfo.is_primary_phone === '0'}
                                                />
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {
                                    visitInfo.is_primary_phone === '0' &&
                                    <div className={"mt-4"}>
                                        <div className="form-group" data-hasError={!!error.primary_phoneNumber}>
                                            <label>
                                                <b>Primary Phone Number</b>
                                            </label>
                                            {primartPhoneInput}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={"section"}>
                        <h5>COVID-19 Government Reporting</h5>
                        <div className={"section-content col-md-12"}>
                            <p className={'info-text'}>
                                We ask for the following information because we’re required to report it to the U.S. Department of Health and Human Services.
                            </p>
                            <p className={'info-text'}>
                                Based on your input, additional fields may be required.
                            </p>
                            <h5>Federal government mandated questions</h5>
                            <div>
                                <div className={"mt-4"}>
                                    <div className="form-group" data-hasError={!!error.received_vaccine}>
                                        <label>
                                            <b>Have you received a COVID-19 vaccine?</b>
                                        </label>
                                        <div className="col-md-12 p-0 pt-2 d-flex flex-row"  onChange={(e) => handleRadioChange(e)}>
                                            <label className="radio-inline mr-3">
                                                <input
                                                    type="radio"
                                                    name="received_vaccine" value="1" className={'mr-2'}
                                                    checked={visitInfo.received_vaccine === '1'}
                                                />
                                                Yes
                                            </label>
                                            <label className="radio-inline">
                                                <input
                                                    type="radio"
                                                    name="received_vaccine" value="0" className={'mr-2'}
                                                    checked={visitInfo.received_vaccine === '0'}
                                                />
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {
                                    visitInfo.received_vaccine === '1' &&
                                    <div className={"mt-4"}>
                                        <div className="form-group" data-hasError={!!error.vaccine_dose}>
                                            <label>
                                                <b>How many dose did your vaccine require?</b>
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row"  onChange={(e) => handleRadioChange(e)}>
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="vaccine_dose" value="1" className={'mr-2'}
                                                        checked={visitInfo.vaccine_dose === '1'}
                                                    />
                                                    1 dose
                                                </label>
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="vaccine_dose" value="2" className={'mr-2'}
                                                        checked={visitInfo.vaccine_dose === '2'}
                                                    />
                                                    2 dose
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="vaccine_dose" value="no_specify" className={'mr-2'}
                                                        checked={visitInfo.vaccine_dose === 'no_specify'}
                                                    />
                                                    Prefer not to specify
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    visitInfo.received_vaccine === '1' && visitInfo.vaccine_dose === '1' &&
                                    <div className={"mt-4"}>
                                        <div className="form-group" data-hasError={!!error.vaccine_manufacturer_1}>
                                            <label>
                                                <b>Who was the manufacturer of your vaccine?</b>
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row"  onChange={(e) => handleRadioChange(e)}>
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="vaccine_manufacturer_1" value="Johnson & Johnson" className={'mr-2'}
                                                        checked={visitInfo.vaccine_manufacturer_1 === 'Johnson & Johnson'}
                                                    />
                                                    Johnson & Johnson
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="vaccine_manufacturer_1" value="other" className={'mr-2'}
                                                        checked={visitInfo.vaccine_manufacturer_1 === 'other'}
                                                    />
                                                    Other / I don't know
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    visitInfo.received_vaccine === '1' && visitInfo.vaccine_dose === '2' &&
                                    <>
                                        <div className={"mt-4"}>
                                            <div className="form-group" data-hasError={!!error.vaccine_manufacturer_2}>
                                                <label>
                                                    <b>Who was the manufacturer of your vaccine?</b>
                                                </label>
                                                <div className="col-md-12 p-0 pt-2 d-flex flex-row"  onChange={(e) => handleRadioChange(e)}>
                                                    <label className="radio-inline mr-3">
                                                        <input
                                                            type="radio"
                                                            name="vaccine_manufacturer_2" value="Moderna" className={'mr-2'}
                                                            checked={visitInfo.vaccine_manufacturer_2 === 'Moderna'}
                                                        />
                                                        Moderna
                                                    </label>
                                                    <label className="radio-inline mr-3">
                                                        <input
                                                            type="radio"
                                                            name="vaccine_manufacturer_2" value="Pfizer" className={'mr-2'}
                                                            checked={visitInfo.vaccine_manufacturer_2 === 'Pfizer'}
                                                        />
                                                        Pfizer
                                                    </label>
                                                    <label className="radio-inline">
                                                        <input
                                                            type="radio"
                                                            name="vaccine_manufacturer_2" value="other" className={'mr-2'}
                                                            checked={visitInfo.vaccine_manufacturer_2 === 'other'}
                                                        />
                                                        Other / I don't know
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"mt-4"}>
                                            <div className="form-group" data-hasError={!!error.taken_2_dose}>
                                                <label>
                                                    <b>Have you taken both doses?</b>
                                                </label>
                                                <div className="col-md-12 p-0 pt-2 d-flex flex-row" onChange={(e) => handleRadioChange(e)}>
                                                    <label className="radio-inline mr-3">
                                                        <input
                                                            type="radio"
                                                            name="taken_2_dose" value="1" className={'mr-2'}
                                                            checked={visitInfo.taken_2_dose === '1'}
                                                        />
                                                        Yes
                                                    </label>
                                                    <label className="radio-inline">
                                                        <input
                                                            type="radio"
                                                            name="taken_2_dose" value="0" className={'mr-2'}
                                                            checked={visitInfo.taken_2_dose === '0'}
                                                        />
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    visitInfo.received_vaccine === '1' && (visitInfo.vaccine_dose === '1' || visitInfo.vaccine_dose === '2') &&
                                    <>
                                        <div className={"mt-4"}>
                                            <div className="form-group" data-hasError={!!error.first_dose_date}>
                                                <label>
                                                    <b>Date you received first dose</b>
                                                </label>
                                                <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                    <input type="date" name="first_dose_date"
                                                           value={visitInfo.first_dose_date}
                                                           onChange={(e) => handleDoseDateChange(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    visitInfo.received_vaccine === '1' && visitInfo.vaccine_dose === '2' && visitInfo.taken_2_dose === '1' &&
                                    <>
                                        <div className={"mt-4"}>
                                            <div className="form-group" data-hasError={!!error.second_dose_date}>
                                                <label>
                                                    <b>Date you received second dose</b>
                                                </label>
                                                <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                    <input type="date" name="second_dose_date"
                                                           value={visitInfo.second_dose_date}
                                                           onChange={(e) => handleDoseDateChange(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className={"mt-4"}>
                                    <div className="form-group" data-hasError={!!error.first_time_vaccine}>
                                        <label>
                                            <b>Is this your first time taking the COVID-19 {testType} test?</b>
                                        </label>
                                        <div className="col-md-12 p-0 pt-2 d-flex flex-row"  onChange={(e) => handleRadioChange(e)}>
                                            <label className="radio-inline mr-3">
                                                <input
                                                    type="radio"
                                                    name="first_time_vaccine" value="1" className={'mr-2'}
                                                    checked={visitInfo.first_time_vaccine === '1'}
                                                />
                                                Yes
                                            </label>
                                            <label className="radio-inline">
                                                <input
                                                    type="radio"
                                                    name="first_time_vaccine" value="0" className={'mr-2'}
                                                    checked={visitInfo.first_time_vaccine === '0'}
                                                />
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {
                                    visitInfo.first_time_vaccine === '0' &&
                                    <div className={"mt-4"}>
                                        <div className="form-group" data-hasError={!!error.previous_test_positive}>
                                            <label>
                                                <b>Have you previously tested positive for COVID-19?</b>
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row"  onChange={(e) => handleRadioChange(e)}>
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="previous_test_positive" value="1" className={'mr-2'}
                                                        checked={visitInfo.previous_test_positive === '1'}
                                                    />
                                                    Yes
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="previous_test_positive" value="0" className={'mr-2'}
                                                        checked={visitInfo.previous_test_positive === '0'}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className={"mt-4"}>
                                    <div className="form-group" data-hasError={!!error.medical_condition}>
                                        <label>
                                            <b>Do you have any medical conditions?</b>
                                        </label>
                                        <div className="col-md-12 p-0 pt-2 d-flex flex-row"  onChange={(e) => handleRadioChange(e)}>
                                            <label className="radio-inline mr-3">
                                                <input
                                                    type="radio"
                                                    name="medical_condition" value="1" className={'mr-2'}
                                                    checked={visitInfo.medical_condition === '1'}
                                                />
                                                Yes
                                            </label>
                                            <label className="radio-inline">
                                                <input
                                                    type="radio"
                                                    name="medical_condition" value="0" className={'mr-2'}
                                                    checked={visitInfo.medical_condition === '0'}
                                                />
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {
                                    visitInfo.medical_condition === '1' &&
                                    <div className={"mt-4"} data-hasError={error.no_medical_condition_selected}>
                                        <label>
                                            <b>Which medical conditions do you have?</b> (Select any that apply)
                                        </label>
                                        <div className="form-group checklist">
                                            <div className="col-md-12 p-0 d-flex flex-column">
                                                <label className="form-group mb-2 align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_asthma"
                                                        checked={visitInfo.medical_issues.is_asthma}
                                                        onChange={(e) => handleMedicalConditionChange(e)}
                                                    />
                                                    <span className={'d-block pt-2'}>Asthma or chronic lung disease</span>
                                                </label>
                                                <label className="form-group mb-2 align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_cirrhosis"
                                                        checked={visitInfo.medical_issues.is_cirrhosis}
                                                        onChange={(e) => handleMedicalConditionChange(e)}
                                                    />
                                                    <span className={'d-block pt-2'}>Cirrhosis of the liver</span>
                                                </label>
                                                <label className="form-group mb-2 align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_cancer"
                                                        checked={visitInfo.medical_issues.is_cancer}
                                                        onChange={(e) => handleMedicalConditionChange(e)}
                                                    />
                                                    <span className={'d-block pt-2'}>Conditions that result in a weakened immune system, including cancer treatment</span>
                                                </label>
                                                <label className="form-group mb-2 align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_diabetes"
                                                        checked={visitInfo.medical_issues.is_diabetes}
                                                        onChange={(e) => handleMedicalConditionChange(e)}
                                                    />
                                                    <span className={'d-block pt-2'}>Diabetes</span>
                                                </label>
                                                <label className="form-group mb-2 align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_hard_cough"
                                                        checked={visitInfo.medical_issues.is_hard_cough}
                                                        onChange={(e) => handleMedicalConditionChange(e)}
                                                    />
                                                    <span className={'d-block pt-2'}>Disease or condition that make it harder to cough</span>
                                                </label>
                                                <label className="form-group mb-2 align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_obesity"
                                                        checked={visitInfo.medical_issues.is_obesity}
                                                        onChange={(e) => handleMedicalConditionChange(e)}
                                                    />
                                                    <span className={'d-block pt-2'}>Extreme obesity</span>
                                                </label>
                                                <label className="form-group mb-2 align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_hypertension"
                                                        checked={visitInfo.medical_issues.is_hypertension}
                                                        onChange={(e) => handleMedicalConditionChange(e)}
                                                    />
                                                    <span className={'d-block pt-2'}>
                                                        Hypertension or high blood pressure
                                                    </span>
                                                </label>
                                                <label className="form-group mb-2 align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_kidney_failure"
                                                        checked={visitInfo.medical_issues.is_kidney_failure}
                                                        onChange={(e) => handleMedicalConditionChange(e)}
                                                    />
                                                    <span className={'d-block pt-2'}>
                                                        Kidney failure or end stage renal disease
                                                    </span>
                                                </label>
                                                <label className="form-group mb-2 align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_heart_problem"
                                                        checked={visitInfo.medical_issues.is_heart_problem}
                                                        onChange={(e) => handleMedicalConditionChange(e)}
                                                    />
                                                    <span className={'d-block pt-2'}>
                                                        Serious heart condition, such as congestive heart failure
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className={"mt-4"}>
                                    <div className="form-group" data-hasError={!!error.is_pregnant}>
                                        <label>
                                            <b>If applicable, are you currently pregnant?</b>
                                        </label>
                                        <div className="col-md-12 p-0 pt-2 d-flex flex-row"  onChange={(e) => handleRadioChange(e)}>
                                            <label className="radio-inline mr-3">
                                                <input
                                                    type="radio"
                                                    name="is_pregnant" value="1" className={'mr-2'}
                                                    checked={visitInfo.is_pregnant === '1'}
                                                />
                                                Yes
                                            </label>
                                            <label className="radio-inline mr-3">
                                                <input
                                                    type="radio"
                                                    name="is_pregnant" value="0" className={'mr-2'}
                                                    checked={visitInfo.is_pregnant === '0'}
                                                />
                                                No
                                            </label>
                                            <label className="radio-inline">
                                                <input
                                                    type="radio"
                                                    name="is_pregnant" value="2" className={'mr-2'}
                                                    checked={visitInfo.is_pregnant === '2'}
                                                />
                                                I don't know
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        testType === 'RT-PCR' &&
                        <div className={"section"}>
                            <h5>Payment</h5>
                            <div className={"section-content col-md-12"}>

                                <div className="form-group" data-hasError={!!error.payment_select}>
                                    <label>
                                        <b>Do you want to pay the test with credit card?</b>
                                    </label>
                                    <div className="col-md-12 p-0 pt-2 d-flex flex-row" onChange={(e) => handleRadioChange(e)}>
                                        <label className="radio-inline mr-3">
                                            <input
                                                type="radio"
                                                name="payment_select" value="1" className={'mr-2'}
                                                checked={visitInfo.payment_select === '1'}
                                            />
                                            Yes
                                        </label>
                                        <label className="radio-inline">
                                            <input
                                                type="radio"
                                                name="payment_select" value="0" className={'mr-2'}
                                                checked={visitInfo.payment_select === '0'}
                                            />
                                            No
                                        </label>
                                    </div>
                                </div>
                                {
                                    visitInfo.payment_select === '0' &&
                                    <>
                                        <p className={'info-text'}>
                                            <b>COVID-19 testing is performed at no cost to you.</b>
                                            Health insurance companies are required to cover 100% of the cost.
                                            If you have insurance, we will ask for those details. If not, we may ask for identifying information in order to bill the federal program for the uninsured.
                                            In either case, you will owe nothing out of pocket.
                                        </p>
                                        <div>
                                            <div className={"mt-4"}>
                                                <div className="form-group" data-hasError={!!error.heath_insurance}>
                                                    <label>
                                                        <b>Do you have health insurance, Medicare, Medicaid or any commercial or government-funded health benefit plan?</b>
                                                    </label>
                                                    <div className="col-md-12 p-0 pt-2 d-flex flex-row"  onChange={(e) => handleRadioChange(e)}>
                                                        <label className="radio-inline mr-3">
                                                            <input
                                                                type="radio"
                                                                name="heath_insurance" value="1" className={'mr-2'}
                                                                checked={visitInfo.heath_insurance === '1'}
                                                            />
                                                            Yes
                                                        </label>
                                                        <label className="radio-inline">
                                                            <input
                                                                type="radio"
                                                                name="heath_insurance" value="0" className={'mr-2'}
                                                                checked={visitInfo.heath_insurance === '0'}
                                                            />
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                visitInfo.heath_insurance === '1' &&
                                                <div className={"mt-4"}>
                                                    <div className="form-group" data-hasError={!!error.insurance_company}>
                                                        <label>
                                                            <b>Insurance Company/or Medicare /or Medical Plan</b>
                                                        </label>
                                                        <input type="text" name="insurance_company"
                                                               className="form-control"
                                                               value={visitInfo.insurance_company}
                                                               onChange={(e) => handleTxtChange(e)}
                                                        />
                                                    </div>
                                                    <div className="form-group mt-4" data-hasError={!!error.insurance_id}>
                                                        <label>
                                                            <b>Member Number/ or ID Number</b> (usually found on your insurance card. Eg: ID W2222 66666)
                                                        </label>
                                                        <input type="text" name="insurance_id"
                                                               className="form-control"
                                                               value={visitInfo.insurance_id}
                                                               onChange={(e) => handleTxtChange(e)}
                                                        />
                                                    </div>
                                                    <div className="form-group mt-4" data-hasError={!!error.confirm_insurance_id}>
                                                        <label>
                                                            <b>Confirm Member Number/ or ID Number</b>
                                                        </label>
                                                        <input type="text" name="confirm_insurance_id"
                                                               className="form-control"
                                                               value={visitInfo.confirm_insurance_id}
                                                               onChange={(e) => handleTxtChange(e)}
                                                        />
                                                    </div>
                                                    <div className="form-group mt-4">
                                                        <label>
                                                            <b>Group number (optional)</b> (usually found on your insurance card. Eg: 111100-010-00001)
                                                        </label>
                                                        <input type="text" name="group_number"
                                                               className="form-control"
                                                               value={visitInfo.group_number}
                                                               onChange={(e) => handleTxtChange(e)}
                                                        />
                                                    </div>
                                                    <div className="form-group mt-4" data-hasError={!!error.policy_holder}>
                                                        <label><b>Who is the policyholder?</b></label>
                                                        <p className={'info-text'}>
                                                            This is the person in whose name the policy is registered, and typically pays the premium.
                                                        </p>
                                                        <select className="form-control"
                                                                value={visitInfo.policy_holder}
                                                                name="policy_holder"
                                                                onChange={(e) => handleTxtChange(e)}>
                                                            <option value="Self"> Self</option>
                                                            <option value="Spouse"> Spouse</option>
                                                            <option value="Child"> Child</option>
                                                            <option value="Mother"> Mother</option>
                                                            <option value="Father"> Father</option>
                                                            <option value="Stepson or Stepdaughter"> Stepson or Stepdaughter</option>
                                                            <option value="Grandfather or Grandmother"> Grandfather or Grandmother</option>
                                                            <option value="Grandson or Granddaughter"> Grandson or Granddaughter</option>
                                                            <option value="Niece or Nephew"> Niece or Nephew</option>
                                                            <option value="Significant Other"> Significant Other</option>
                                                            <option value="Foster Child"> Foster Child</option>
                                                            <option value="Dependent of a Minor Dependent"> Dependent of a Minor Dependent</option>
                                                            <option value="Ward"> Ward</option>
                                                            <option value="Emancipated Minor"> Emancipated Minor</option>
                                                            <option value="Other Relationship"> Other Relationship</option>
                                                        </select>
                                                    </div>

                                                    <div className="form-group mt-4" data-haserror={!!error.front_insurance_card_img || !!error.back_insurance_card_img}>
                                                        <label><b>Share an image of your insurance card</b></label>
                                                        <p className={'info-text'}>
                                                            Provide an image of your insurance card to help avoid errors in billing your insurance company.
                                                            Please upload the front of the insurance card and back of the insurance card both. You still need
                                                            to upload the back of the insurance card even though it is blank.
                                                        </p>
                                                        <div className={"insurance-upload-container"}>
                                                            <div>
                                                                <div className={'insurance-front insurance-card'}>
                                                                    <span>Front</span>
                                                                </div>
                                                                <input
                                                                    ref={frontInsurance}
                                                                    className="drop-file-input"
                                                                    type="file"
                                                                    id={'frontFileUploadInp'}
                                                                    onChange={frontInsuranceSelectHandler}
                                                                    style={{ display: "none" }}
                                                                    accept="image/*"
                                                                />
                                                                <div className="upload-btn">
                                                                    {
                                                                        !visitInfo.front_insurance_card_img ?
                                                                            <button onClick={triggerFrontInsuranceClick} type="button"
                                                                                    className="secondary-outline-btn">Upload Front Image
                                                                                <span className={frontImageUploading ? "spinner-border" : ""}></span>
                                                                            </button>:
                                                                            <button onClick={() => removeInsurance('front_insurance_card_img')} type="button"
                                                                                    className="red-btn-border">Remove
                                                                                <span className={frontImageUploading ? "spinner-border" : ""}></span>
                                                                            </button>

                                                                    }
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className={'insurance-back insurance-card'}>
                                                                    <span>Back</span>
                                                                </div>
                                                                <input
                                                                    ref={backInsurance}
                                                                    className="drop-file-input"
                                                                    type="file"
                                                                    id={'backFileUploadInp'}
                                                                    onChange={backInsuranceSelectHandler}
                                                                    style={{ display: "none" }}
                                                                    accept="image/*"
                                                                />
                                                                <div className="upload-btn">
                                                                    {
                                                                        !visitInfo.back_insurance_card_img ?
                                                                            <button onClick={triggerBackInsuranceClick} type="button"
                                                                                    className="secondary-outline-btn">Upload Back Image
                                                                                <span className={backImageUploading ? "spinner-border" : ""}></span>
                                                                            </button>:
                                                                            <button onClick={() => removeInsurance('back_insurance_card_img')} type="button"
                                                                                    className="red-btn-border">Remove
                                                                                <span className={backImageUploading ? "spinner-border" : ""}></span>
                                                                            </button>

                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            }
                                            {
                                                visitInfo.heath_insurance === '0' &&
                                                <>
                                                    <div className={"mt-4"}>
                                                        <div className="form-group col-md-12 p-0 pl-4 d-flex flex-row align-items-center"
                                                             data-hasError={!!error.confirm_no_health_insurance}>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    name="confirm_no_health_insurance"
                                                                    onChange={(e) => handleConfirmHSSChange(e)}
                                                                />
                                                                <p className={'info-text pt-1 ml-3 d-block'}>
                                                                    I confirm and attest that I have no active health insurance, Medicare, Medicaid, or any
                                                                    commercial or government-funded health benefit plan.
                                                                    <b>
                                                                        I understand If I fail to report active health insurance I may be charged $99.
                                                                    </b>
                                                                </p>
                                                            </label>
                                                        </div>
                                                        <p className={'info-text'}>
                                                            Please provide a valid Social Security Number OR a driver's license/state ID number, which is used
                                                            for the HHS Uninsured program administration. You may continue to book an appointment if you do not have or prefer
                                                            not to provide this information.
                                                        </p>
                                                    </div>
                                                    <div className={"mt-4"}>
                                                        <div className="form-group" data-hasError={!!error.ssn}>
                                                            <label>
                                                                <b>Social Security Number</b>(preferred)
                                                            </label>
                                                            <input type="password" name="ssn"
                                                                   className="form-control"
                                                                   value={visitInfo.ssn}
                                                                   onChange={(e) => handleTxtChange(e)}
                                                            />
                                                        </div>
                                                        <div className={'or-block mt-4 mb-4'}>
                                                            <span>OR</span>
                                                            <hr/>
                                                        </div>
                                                        <div className="form-group mt-3 d-flex flex-row justify-content-start flex-basis">
                                                            <div className="form-group mr-5" data-hasError={!!error.driver_license}>
                                                                <label>
                                                                    <b>Driver's license/state ID number</b>
                                                                </label>
                                                                <input type="text" name="driver_license"
                                                                       className="form-control"
                                                                       value={visitInfo.driver_license}
                                                                       onChange={(e) => handleTxtChange(e)}
                                                                />
                                                            </div>
                                                            <div className="form-group" data-hasError={!!error.hhs_state}>
                                                                <label>
                                                                    <b>State</b>
                                                                </label>
                                                                <input type="text" name="hhs_state"
                                                                       className="form-control"
                                                                       value={visitInfo.hhs_state}
                                                                       onChange={(e) => handleTxtChange(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </>
                                }
                                {
                                    visitInfo.payment_select === '1' &&
                                    <>
                                        <p className={'info-text'}>
                                            You have to make the payment of <b>$120</b> to book this appointment. The payment is <b>not refundable</b>, just in case if
                                            you do not show up during the scheduled appointment date.
                                        </p>
                                    </>
                                }
                            </div>
                        </div>
                    }
                    <div className={"section"}>
                        <h5>Consent & Acknowledgments</h5>
                        <div className={"section-content col-md-12"}>
                            <div>
                                <div className="form-group" data-hasError={!!error.health_info_available}>
                                    <label>
                                        <b>My personal health information may be made available electronically to other healthcare providers
                                            who have a treatment relationship with me or to my health plan for operations and care coordination as permitted by Federal and state laws.</b>
                                    </label>
                                    <div className="col-md-12 p-0 pt-2 d-flex flex-row"
                                         onChange={(e) => handleRadioChange(e)}
                                    >
                                        <label className="radio-inline mr-3">
                                            <input
                                                type="radio"
                                                name="health_info_available" value="1" className={'mr-2'}
                                                checked={visitInfo.health_info_available === '1'}
                                            />
                                            Yes
                                        </label>
                                        <label className="radio-inline">
                                            <input
                                                type="radio"
                                                name="health_info_available" value="0" className={'mr-2'}
                                                checked={visitInfo.health_info_available === '0'}
                                            />
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div className={"mt-5"}>
                                    <div className="form-group col-md-12 p-0 pl-4 d-flex flex-row align-items-center"
                                         data-hasError={!!error.is_acknowledge}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                name="is_acknowledge"
                                                onChange={(e) => handleAcknowledgeChange(e)}
                                            />
                                            <b className={'ml-3 pt-1 d-block'}>
                                              I understand that I am scheduling to receive testing for COVID-19 for myself or a minor on whose behalf I am authorized to provide consent.
                                                I have been informed that testing is voluntary, that I have the right to decline any tests and that test results will be reported to the state health department where required by law.
                                                I consent to testing for COVID-19 and agree to these terms.
                                            </b>
                                            <b className={'ml-3 pt-1 d-block'}>
                                                <i>Test cancellation is non-refundable. Please confirm before booking an appointment</i>
                                            </b>
                                            {
                                                testType === 'Antigen' &&
                                                <b className={'ml-3 pt-1 d-block'}>
                                                    TEST COST: $70
                                                </b>
                                            }
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mt-4">
                        <button type="button" className="btn red-btn btn-md"
                                onClick={onSaveVisitInfo}
                                disabled={onSavingVisitInfo}
                        > Submit
                            <span className={onSavingVisitInfo ? "spinner-border" : ""}></span>
                        </button>
                    </div>
                </div>
            </div>

            <Modal show={showTimeComplete}>
                <Modal.Header>
                    <Modal.Title>Time Expired</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>The appointment time has been expired. Choose another time for the same day and book your appointment within 25 minutes</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onSelectNewTime}>
                        Select New Time
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showPaymentModal} onHide={() => setShowPaymentModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Card Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="col-lg-12 pl-0 stripe-payment">
                            <div>
                                <img src={Visa} alt="visa"/>
                                <img src={Master} alt="master_card"/>
                                <img src={AmericanExpress} alt="american_express"/>
                            </div>
                            <p>All transactions are secure and encrypted.</p>
                            <div className="stripe-container">
                                <Elements stripe={stripeElement}>
                                    <PaymentMethod onCompletePayment={onCompletePayment}/>
                                </Elements>
                            </div>
                        </div>
                    </>
                </Modal.Body>
            </Modal>
        </div>
    )
};

export default CovidVisitInfo;
