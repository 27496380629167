import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import "../Employees/employees.scss";
import {NotificationManager} from "react-notifications";
import EmployeesTestHistory from "./employeesTestHistory";
import EmployeesPaymentHistory from "./employeesPaymentHistory";

const EmployeeHistory = (props) => {
    const initEmployeeHistory = {
        fullName: '',
        email: '',
        phone: '',
        address: '',
        state_id: 0,
        city_id: 0,
        country_id: 0,
        zipCode: '',
        isActive: '',
        loadTestHistory: true,
        loadPaymentHistory: false,
    };
    const authData = useSelector((state) => state.authReducer.authData);
    const [employeeHistory, setEmployeeHistory] = useState(initEmployeeHistory);

    useEffect(() => {
        if(authData.isOrganisationUser === 0) {
            NotificationManager.error('You do not have permission to access this page');
            props.history.push('/');
            return;
        }
    }, [authData.isOrganisationUser]);

    const prepareTab = (tabName) => {
        const obj = {
            loadTestHistory: tabName === 'testHistory',
            loadPaymentHistory: tabName === 'paymentHistory',
        };
        setEmployeeHistory(prev => ({
            ...prev,
            ...obj
        }));
    };

    return (
        <div className="add-employees-main">
            <div className="container">
                <div className="custom-tab">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <a className="nav-item nav-link active" id="test-history-tab" data-toggle="tab"
                               href="#test-history" role="tab" aria-controls="custom-nav-test-history"
                               aria-selected="true" onClick={() => prepareTab('testHistory')}>Test History</a>
                            <a className="nav-item nav-link" id="payment-history-tab" data-toggle="tab"
                               href="#payment-history" role="tab" aria-controls="custom-nav-payment-history"
                               aria-selected="true" onClick={() => prepareTab('paymentHistory')}>Payment History</a>
                        </div>
                    </nav>

                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="test-history" role="tabpanel"
                             aria-labelledby="test-history-tab">
                            <div className="col-md-12 mt-2">
                                {employeeHistory.loadTestHistory && <EmployeesTestHistory />}
                            </div>
                        </div>

                        <div className="tab-pane fade" id="payment-history" role="tabpanel"
                             aria-labelledby="payment-history-tab">
                            <div className="col-md-12 mt-2">
                                {employeeHistory.loadPaymentHistory && <EmployeesPaymentHistory />}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeHistory;
