import React, { useState, useEffect } from "react";
import {orderAction} from "../../Redux/Actions/index";
import {withRouter} from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import "../Orders/orders.scss";
// import Test from "../../Images/test.png";
import Stripe from "../../Images/stripe-icon.png";
import { Rating } from 'react-simple-star-rating'
import { NotificationManager } from "react-notifications";

const Orders = (props) => {
  let dispatch = useDispatch();
  // const orderList = useSelector((state) => state.orderReducer.orderList);
  const orderDetails = useSelector((state) => state.orderReducer.orderDetails);
  const orderTrackStatusRecord = useSelector((state) => state.orderReducer.getAllTracks)?.map((i)=>i.elements[0].text)
  const [address, setAddress] = useState({});
  const [rating, setRating] = useState(0);
  const [feedBackText, setFeedBackText] = useState('');
  const [feedBackError, setFeedBackError] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [orderDetailLoading, setOrderDetailLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setOrderDetailLoading(true);
      await dispatch(
        orderAction.getOrderDetails(props?.match?.params?.orderId)
      );
      setOrderDetailLoading(false);
      if(props.location.hash) {
        const id = props.location.hash.replace('#', '');
        const element = document.getElementById(id);
        if(element) {
          window.scrollTo({
            top: (element.offsetTop - 200),
            behavior:"smooth"
          });
        }
      }
      // await dispatch(orderAction.getTrackingData(props?.match?.params?.orderId))
    })();

  }, []);

  useEffect(() => {
    let addressNew = orderDetails?.address;
    if (addressNew) {
      setAddress(JSON.parse(addressNew));
    }
  }, [orderDetails?.address]);

  useEffect(() => {
    if (orderDetails) {
      setRating(orderDetails.rating);
      setFeedBackText(orderDetails.feedback_text || "");
      if(orderDetails.rating && orderDetails.feedback_text) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
  }, [JSON.stringify(orderDetails)]);

  const trackOrders =()=> {
    window.open(orderDetails.trackingURLProvider, "_blank");
  };

  const handleRating = (rate) => {
    setRating(rate);
    setFeedBackError(prev => ({...prev, ratingError: ''}));
  };

  const handleFeedbackText = (e) => {
    setFeedBackError(prev => ({...prev, feedBackTextError: false}));
    setFeedBackText(e.target.value);
  };

  const handleFeedbackSubmit = async () => {
    const feedbackErrorObj = {};
    let hasError = false;
    if(!rating) {
      hasError = true;
      feedbackErrorObj.ratingError = "Please give star rating for your experience on this product"
    }

    if(!feedBackText || !feedBackText.trim()) {
      hasError = true;
      feedbackErrorObj.feedBackTextError = true
    }
    setFeedBackError(feedbackErrorObj);
    if(hasError) {
      return;
    }
    setIsSaving(true);
    const payload = {
      orderId: props?.match?.params?.orderId,
      rating,
      feedBackText,
      productId: orderDetails.items[0] && orderDetails.items[0].productId
    };
    let feedbackStatus = await dispatch(orderAction.saveFeedback(payload));
    if(!feedbackStatus.isSuccess) {
      NotificationManager.error(feedbackStatus.errorMessage);
      setIsSaving(false);
      return;
    }
    NotificationManager.success(feedbackStatus.message);
    setIsSaving(false);
    setIsDisabled(true);
  };

  return (
    <div className="orders-main">
      <div className="container">
        <h2>My Orders</h2>
        <div className="details-order-invoice">
          <div className="invoice-details">
            <h4>Order Details</h4>
            <p>Order Number: {orderDetails?.orderNumber}</p>
            {/* <p>Ordered by 20 July 2020</p> */}
            {/* <p>
              <b>Arriving 25 July 2020 by 9 PM</b>
            </p> */}
          </div>
          {
            orderDetails.status === 'Shipped' &&
            <button className="red-btn" onClick={()=>trackOrders()}>Track Orders</button>
          }
          {/* <div className="invoice-text">
            <p>Invoice</p>
          </div> */}
        </div>

        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Product</th>
                <th>Order ID</th>
                <th>Quantity</th>
                {/* <th>Sub Total</th>
                <th>Total</th> */}
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {orderDetails?.items?.map((data, i) => {
                return (
                  <tr>
                    <td>
                      <div className="product-test-base">
                        <div className="test-base-img">
                          <img src={ data.image_name} />
                        </div>
                        <p>{data.name}</p>
                      </div>
                    </td>
                    <td>{orderDetails?.orderNumber}</td>
                    <td>{data.orderedQuantity}</td>
                    {/* <td>${orderDetails?.subTotal}</td>
                  <td>${orderDetails?.total}</td> */}
                    <td>{orderDetails?.status}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="groupof-method-summary">
          <div className="row">
            <div className="col-lg-4">
              <div className="address-group-sum">
                <h4>Shipping Address</h4>
                {address ? (
                  <p>
                    {" "}
                    {address?.address},{address?.city},{address?.state},
                    {address?.country}{" "} - {address?.zipCode}
                  </p>
                ) : null}
              </div>
            </div>

            <div className="col-lg-4">
              <div className="address-group-sum">
                <h4>Payment Method</h4>
                <div className="payment-stripe">
                  <div className="payment-stripe-img">
                    <img src={Stripe} />
                  </div>
                  {/* <p>**** 1000</p> */}
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="address-group-sum">
                <h4>Order Summary</h4>
                <div className="amount-details-digit">
                  <p>Item(s) Subtotal:</p>
                  <p>${orderDetails.subTotal}</p>
                </div>
                <div className="amount-details-digit">
                  <p>Shipping:</p>
                  <p>$00.00</p>
                </div>
                <div className="amount-details-digit">
                <p>Coupon Code({orderDetails.discount}%)</p>
                  <p>${parseFloat(orderDetails.subTotal-orderDetails.total).toFixed(2)}</p>
                </div>
                <div className="amount-details-digit">
                  <p>Total:</p>
                  <p>${orderDetails.total}</p>
                </div>
                <div className="amount-details-digit bold">
                  <p>Grand Total:</p>
                  <p>${orderDetails.total}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          !orderDetailLoading && orderDetails?.status === 'Shipped' &&
          <div className="feedback-section" id={"feedback"}>
            <div className="row col-md-12">
              <div className={"w-100"}>
                <h4>Feedback</h4>
                <div>
                  <Rating
                      onClick={handleRating}
                      ratingValue={rating}
                      allowHalfIcon={true}
                      showTooltip={true}
                      readonly={isDisabled}
                  />
                  {feedBackError.ratingError ? (
                      <p className="error-validation">{feedBackError.ratingError}</p>
                  ) : null}
                </div>
                <div className={"pl-0 col-md-6 mt-2"}>
                <textarea className={`form-control ${feedBackError.feedBackTextError ? 'error': ''}`} name={'feedback-text'} rows="5" value={feedBackText}
                          onChange={(e) => handleFeedbackText(e)}
                          readOnly={isDisabled}
                          disabled={isDisabled}
                ></textarea>
                  <i className={"help-text"}>Please write some feedback for your experience with this product</i>
                </div>
                {
                  !isDisabled &&
                  <div className={"col-md-6 pl-0"}>
                    <button className="red-btn-border" onClick={handleFeedbackSubmit} disabled={isSaving}>Save
                      <span
                          className={isSaving && "spinner-border"}
                      ></span>
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
        }

      </div>
    </div>
  );
};

export default withRouter(Orders);
