import Api from "../../Api";
import { handleError } from "./commonAction";
import { THREE_MONTHS_SUB, SIX_MONTHS_SUB, TWELVE_MONTHS_SUB } from "../../constant"
function setDataInLocalstorage(paramName, paramValue) {
    localStorage.setItem(paramName, JSON.stringify(paramValue));
    return;
}
function dispatchCartItems(dispatch, cartItems) {
    console.log("dispatching cartItems now", cartItems);
    dispatch({
        type: "CARTITEMS",
        payload: cartItems,
    });
}

export const getCartItems = () => async (dispatch) => {
    if (localStorage.getItem("cartItems") && localStorage.getItem("cartItems") !== null && localStorage.getItem("cartItems") !== undefined) {
        let cartItems = await JSON.parse(localStorage.getItem("cartItems"));
        // if total
        if (cartItems && cartItems.length > 0) {
            cartItems = cartItems?.map((i) => ({ discountPricePerItem: i.discountPricePerItem ? i.discountPricePerItem : i.price, ...i }))
            dispatchCartItems(dispatch, cartItems);

        } else {
            dispatchCartItems(dispatch, []);
        }
    }

}

const addToSubscribeCart = (dispatch, cartItems, item, selectedQuantity, subscription, subscriptionDuration, priceId) => {
    let itemIndex = cartItems.findIndex(elem => (elem.id === item.id && elem.subscriptionDuration === subscriptionDuration));
    if (itemIndex >= 0) {
        let itemInCart = cartItems[itemIndex]
        let totalSelectedQuantity = itemInCart.selectedQuantity + selectedQuantity;

        if (totalSelectedQuantity > item.quantity)
            itemInCart.selectedQuantity = item.quantity;
        else
            itemInCart.selectedQuantity = totalSelectedQuantity;
        itemInCart["subscription"] = subscription
        if (subscription) {
            itemInCart["subscriptionDuration"] = subscriptionDuration
            // itemInCart["subscriptionType"] = subscriptionType
            itemInCart[`priceId`] = priceId
        }
        itemInCart.price = itemInCart['basePrice'] * totalSelectedQuantity;
        cartItems[itemIndex] = itemInCart;
        setDataInLocalstorage("cartItems", cartItems);
        dispatchCartItems(dispatch, cartItems);
    }
    else {
        console.log('******************************************');
        item["selectedQuantity"] = selectedQuantity;
        item["basePrice"] = item["price"];
        item["price"] = item["price"] * selectedQuantity;
        item["subscription"] = subscription
        if (subscription) {
            item["subscriptionDuration"] = subscriptionDuration
            item[`priceId`] = priceId
        }
        cartItems.push(item)
        setDataInLocalstorage("cartItems", cartItems);
        dispatchCartItems(dispatch, cartItems)
    }
}


const addToOneTimePaymentCart = (dispatch, cartItems, item, selectedQuantity, subscription, subscriptionDuration, priceId) => {
    let itemIndex = cartItems.findIndex(elem => (elem.id === item.id && elem.subscription === false));
    if (itemIndex >= 0) {
        // console.log("itemIndex insideeee ifffffffffffffffffffffffffff", elem.subscriptionDuration === subscriptionDuration)
        let itemInCart = cartItems[itemIndex]
        let totalSelectedQuantity = itemInCart.selectedQuantity + selectedQuantity;

        if (totalSelectedQuantity > item.quantity)
            itemInCart.selectedQuantity = item.quantity;
        else
            itemInCart.selectedQuantity = totalSelectedQuantity;
        itemInCart["subscription"] = subscription
        if (subscription) {
            itemInCart["subscriptionDuration"] = subscriptionDuration
            itemInCart[`priceId`] = priceId
        }
        itemInCart.price = itemInCart['basePrice'] * totalSelectedQuantity;
        cartItems[itemIndex] = itemInCart;
        setDataInLocalstorage("cartItems", cartItems);
        dispatchCartItems(dispatch, cartItems);
    }
    else {
        console.log('******************************************');
        item["selectedQuantity"] = selectedQuantity;
        item["basePrice"] = item["price"];
        item["price"] = item["price"] * selectedQuantity;
        item["subscription"] = subscription
        if (subscription) {
            item["subscriptionDuration"] = subscriptionDuration
            item[`priceId`] = priceId
        }
        cartItems.push(item)
        setDataInLocalstorage("cartItems", cartItems);
        dispatchCartItems(dispatch, cartItems)
    }
}

export const addToCart = (item, selectedQuantity, subscription, subscriptionDuration, priceId) => (dispatch) => {
    // console.log(`subscriptionnnnnnnnnnnnn`, subscription)
    let cartItems = []
    if (localStorage.getItem("cartItems")) {
        cartItems = JSON.parse(localStorage.getItem("cartItems"));
    }
    if (cartItems && cartItems.length > 0) {
        if (subscription === true) {
            addToSubscribeCart(dispatch, cartItems, item, selectedQuantity, subscription, subscriptionDuration, priceId)
        } else {
            addToOneTimePaymentCart(dispatch, cartItems, item, selectedQuantity, subscription, subscriptionDuration, priceId)
        }
    }
    else {
        item["selectedQuantity"] = selectedQuantity;
        item["basePrice"] = item["price"];
        item["price"] = item["price"] * selectedQuantity
        item["subscription"] = subscription
        if (subscription) {
            item["subscriptionDuration"] = subscriptionDuration
            item[`priceId`] = priceId
        }
        let cartItems = [item];
        setDataInLocalstorage("cartItems", cartItems);
        dispatchCartItems(dispatch, cartItems)
    }
    // }


}


export const removeItemFromCart = (item) => (dispatch) => {
    // console.log("removeItemFromCart parameters", item);
    let cartItems = JSON.parse(localStorage.getItem("cartItems"));
    let itemIndex = cartItems.findIndex(elem => elem.id === item.id);
    cartItems.splice(itemIndex, 1);
    setDataInLocalstorage("cartItems", cartItems);
    dispatchCartItems(dispatch, cartItems);
}

export const changeItemQuantity = (item, newQuantity) => (dispatch) => {
    item["selectedQuantity"] = newQuantity;
    let cartItems = JSON.parse(localStorage.getItem("cartItems"));
    if (cartItems) {
        let itemIndex = cartItems.findIndex(elem => elem.id === item.id);
        cartItems[itemIndex] = item;
        setDataInLocalstorage("cartItems", cartItems);
        dispatchCartItems(dispatch, cartItems)
    }
}

export const changeQuantity = (item, selectedQuantity, type) => (dispatch) => {

    let cartItems = JSON.parse(localStorage.getItem("cartItems"));
    console.log(item, "itmeitme")
    if (cartItems) {
        let itemIndex = cartItems.findIndex(elem => elem.id === item.id && elem.subscriptionDuration === item.subscriptionDuration);
        if (itemIndex >= 0) {
            let itemInCart = cartItems[itemIndex]

            let totalSelectedQuantity = null;
            if (type === "plus") {
                totalSelectedQuantity = itemInCart.selectedQuantity + selectedQuantity;
            } else {
                totalSelectedQuantity = itemInCart.selectedQuantity - selectedQuantity;
            }
            // let totalSelectedQuantity = itemInCart.selectedQuantity + selectedQuantity;

            if (totalSelectedQuantity > item.quantity)
                itemInCart.selectedQuantity = item.quantity;
            else
                itemInCart.selectedQuantity = totalSelectedQuantity;
            itemInCart.price = item.basePrice * totalSelectedQuantity;

            cartItems[itemIndex] = itemInCart;
            setDataInLocalstorage("cartItems", cartItems);
            calculateSubTotal(cartItems)
            dispatchCartItems(dispatch, cartItems);
        }
        else {
            item["selectedQuantity"] = selectedQuantity;
            item["basePrice"] = item["price"];
            item["price"] = item["price"] * selectedQuantity;
            cartItems.push(item)
            setDataInLocalstorage("cartItems", cartItems);
            calculateSubTotal(cartItems)
            dispatchCartItems(dispatch, cartItems)
        }
    }
    else {
        item["selectedQuantity"] = selectedQuantity;
        item["basePrice"] = item["price"];
        let cartItems = [item];
        setDataInLocalstorage("cartItems", cartItems);
        calculateSubTotal(cartItems)
        dispatchCartItems(dispatch, cartItems)
    }
}

export const orderItems = (body, cartItems) => async (dispatch) => {
    let userId = JSON.parse(localStorage.getItem("userData")).id
    let couponCode = localStorage.getItem("couponCode")
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await Api.post(`/api/v1/cart/`, { ...body, id: userId, cartItems, couponCode: couponCode ? couponCode : null });
        // console.log("RE,",res)
        // localStorage.setItem("userData",JSON.stringify(res.data.data))

        dispatch({ type: "LOADING_COMPLETED" });
        return res.data;
    } catch (error) {
        dispatch({ type: "LOADING_FAILURE" });
        dispatch(handleError(error));

    }
};

export const validateAppliedCoupon = (total, couponCode, cartItems) => async (dispatch) => {
    console.log("params are", { total, couponCode })
    try {
        dispatch({ type: "LOADING_STARTED" });
        let totalDiscount = 0
        let cartDetails = []
        const productIds = cartItems.map(item => item.id);
        const res = await Api.get(`/api/v1/cart/coupon?total=${total}&code=${couponCode}&productIds=${productIds.toString()}`);
        if (res) {
            totalDiscount = res.data.discountPercent
            cartDetails = cartItems?.map((i) => ({ ...i, discountPricePerItem: i.price * (1 - (totalDiscount) / 100) }))
            setDataInLocalstorage("cartItems", cartDetails);
            console.log(`cartitems*******************`, cartDetails)
        }


        dispatch({ type: "LOADING_COMPLETED" });

        dispatch({ type: "VALIDATE_COUPON_DATA", payload: res })

        return res;
    }
    catch (error) {
        console.log("error while validate applied coupon", error);
        dispatch(handleError(error));
    }
}

export const changePriceForSubscripation = (item) => async (dispatch) => {
    let cartItems = JSON.parse(localStorage.getItem("cartItems"));
    if (cartItems && cartItems.length > 0) {
        let itemIndex = cartItems.findIndex(elem => (elem.id === item.id));
        if (itemIndex > 0) {

        }
    }
}
export const calculateSubTotal = (cartDetails, discount) => async (dispatch) => {
    let totalDiscount = 0
    let subTotal = 0;
    let total = 0;
    let cartItems = []
    await cartDetails?.map((data, i) => {
        if (data) {
            subTotal = data.price + subTotal;
        }
    })
    if (localStorage.getItem('couponCode') !== null) {
        // api call
        const productIds = cartDetails.map(item => item.id);
        const res = await Api.get(`/api/v1/cart/coupon?total=${subTotal}&code=${localStorage.getItem('couponCode')}&productIds=${productIds.toString()}`)
        if(!res.data.isValid || !res.data.isApplied) {
            dispatch({ type: "FORCE_REMOVE_COUPON_CODE"});
        } else {
            totalDiscount = res.data.discountPercent
            cartItems = cartDetails?.map((i) => ({ ...i, discountPricePerItem: i.price * (1 - (totalDiscount) / 100) }))
            setDataInLocalstorage("cartItems", cartItems);
        }

    }
    total += subTotal * (1 - ((totalDiscount ? totalDiscount : 0) / 100))
    dispatch({ type: "TOTAL_CALCULATE", subTotal: subTotal.toFixed(2), total: total.toFixed(2) })
};

export const setCouponCode = (discountCode) => async (dispatch) => {
    localStorage.setItem('couponCode', discountCode)
    dispatch({ type: "SET_COUPON_CODE", payload: true })
}
export const removeCouponCode = () => async (dispatch) => {
    localStorage.removeItem('couponCode')
    dispatch({ type: "SET_COUPON_CODE", payload: false })
}
export const checkCoupnCodeExist = () => async (dispatch) => {
    dispatch({ type: "SET_COUPON_CODE", payload: true })
}

export const checkOut = (cartItems, payload, paymentMethod, orderData, preferredPaymentOption) => async (dispatch) => {
    let { id, stripeCustomerId } = JSON.parse(localStorage.getItem("userData"))
    let orderId = localStorage.getItem("orderId")
    let couponCode = localStorage.getItem("couponCode")

    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await Api.post(`/api/v1/stripe/createSubscription`, { cartItems, ...payload, preferredPaymentOption, userId: id, orderId, stripeCustomerId, paymentMethod, orderData, couponCode: couponCode ? couponCode : null });
        dispatch({ type: "LOADING_COMPLETED" });

        return res;
    } catch (error) {
        dispatch({ type: "LOADING_FAILURE" });
        dispatch(handleError(error));
    }
}

export const resetForceRemoveCouponCode = () => async (dispatch) => {
    dispatch({ type: "RESET_FORCE_REMOVE_COUPON_CODE" })
}
