import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {NotificationManager} from 'react-notifications';
import RetrogenLogo from "../../Images/retrogen-logo.png";

import "./covid.scss";
import { covidAction } from "../../Redux/Actions";

const CovidBooking = (props) => {
    const dispatch = useDispatch();

    const [userEmail, setUserEmail] = useState('');
    const [onProcessingSearch, setOnProcessingSearch] = useState(false);
    const [error, setError] = useState({
        emailError: ''
    });

    const onSubmit = (e) => {
        e.preventDefault();
    };

    const onCheckBooking = async () => {
        if(!userEmail || !userEmail.trim()) {
            setError(prev => {
                return {
                    ...prev,
                    emailError: 'This field is required'
                }
            });
            return;
        }
        if((userEmail.length > 0 &&
            /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(userEmail) === false)
        ) {
            setError(prev => {
                return {
                    ...prev,
                    emailError: 'Invalid email address'
                }
            });
            return;
        }
        setOnProcessingSearch(true);
        const userBookingResponse = await dispatch(covidAction.getUserBookings(userEmail));
        setOnProcessingSearch(false);
        if(!userBookingResponse.isSuccess) {
            NotificationManager.error(userBookingResponse.errorMessage || 'Something went wrong. Please try again later');
            return;
        }
        const bookingData = {
            userEmail,
            bookingData: userBookingResponse.data
        };
        await dispatch(covidAction.saveBookingInfo(bookingData));
        props.history.push('/covid-19-testing/my-bookings');
    };

    const onCancelBooking = () => {
        props.history.push('/covid-19-testing');
    };

    return (
        <div id="get-started">
            <div className="container">
                <div id="search-row" className="row justify-content-center align-items-center">
                    <div id="search-column" className="col-md-6">
                        <div id="search-box" className="col-md-12">
                            <form id="search-form" className="form" onSubmit={onSubmit}>
                                <div className={'logo'}>
                                    <img src={RetrogenLogo} alt="logo"/>
                                </div>
                                <h3 className="text-center text-info">Check My Booking</h3>
                                <div className="form-group">
                                    <label htmlFor="location" className="text-info">Email Address</label><br/>
                                    <input type="text" name="location" id="location" className="form-control"
                                           onChange={(e) => setUserEmail(e.target.value)}
                                    />
                                    {
                                        error.emailError && <p className={"error-text"}>{error.emailError}</p>
                                    }
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn red-btn btn-md btn-block" disabled={onProcessingSearch}
                                            onClick={onCheckBooking}
                                    > { onProcessingSearch ? 'Checking': 'Check Booking'}
                                        <span className={onProcessingSearch ? "spinner-border" : ""}></span>
                                    </button>
                                </div>
                                <div className="form-group text-right">
                                    <span className={"status-link"} onClick={onCancelBooking}>
                                        <i className={'fa fa-arrow-left'}></i> Back
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CovidBooking;
