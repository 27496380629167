import React  from "react";
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import "../Breadcrumbs/breadcrumbs.scss";

const Breadcrumbs = (props) => {

    return (
        <div className="breadcrumbs-main">
            <div className="container-fluid">
                <ul>
                    {props.location.pathname.split("/").map((data, index) => {
                        if (index === 0) {
                            return (<li key={index}><Link to={`/`}>Home  &nbsp;
                                {props.location.pathname.trim() !== '/' && <span>/</span>}
                            </Link></li>)
                        }
                        else if (props.location.pathname.split("/").length - 1 === index) {
                            function capitalize(str) {
                                return str?.charAt(0)?.toUpperCase() + str?.slice(1);
                            }
                            let str = data?.replace('-', ' ')
                            return (<li>{str?.split(' ').map(capitalize).join(' ')} </li>)
                        } else {
                            function capitalize(str) {
                                return str?.charAt(0)?.toUpperCase() + str?.slice(1);
                            }
                            let str = data?.replace('-', ' ')
                            return (<li><Link to={`/${data}`}>{str?.split(' ').map(capitalize).join(' ')}&nbsp;&nbsp;/</Link></li>)
                        }
                    })}
                    {/* </li> */}
                </ul>
            </div>
        </div>
    )
}

export default withRouter(Breadcrumbs);