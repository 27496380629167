//---Coded by Piyush!
//--- 09/2020
import axios from 'axios';
import { API_URL } from './config';

const axiosInstance = axios.create({ baseURL: API_URL });


const errHandling = (error) => Promise.reject(error);


const reqHandling = (config) => {
  const obj = { ...config };
  const token = localStorage.getItem('token');

  if (token) obj.headers['Authorization'] = token;
  return obj;
};


const resHandling = (response) => response;
axiosInstance.interceptors.request.use(reqHandling, errHandling);
axiosInstance.interceptors.response.use(resHandling, errHandling);


export default axiosInstance;
