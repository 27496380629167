export const startLoadig = () => async (dispatch) => {
  dispatch({ type: "LOADING_STARTED" });
};

export const stopLoadig = () => async (dispatch) => {
  dispatch({ type: "LOADING_COMPLETED" });
};


export const resetError = () => async (dispatch) => {
  dispatch({ type: "CLEAN_ERROR" });
};

export const handleError = (error) => async (dispatch) => {
  dispatch({ type: "LOADING_FAILURE" });
  if (error.response) {
    if (error.response.status === 401) {
      if(error.response.data.isExpire || error.response?.statusText =='Unauthorized'){
        localStorage.clear()
        window.location.reload();
      }
      //
      dispatch({
        type: "ERROR",
        payload: { error: error?.response?.data?.message },
      });
    } else if (error.response.data?.errorData ) {
      dispatch({
        type: "ERROR",
        payload: { error: error.response.data },
      });
    } else if (error.response.data?.data ) {
      dispatch({
        type: "ERROR",
        payload: { error: error.response.data?.data.error  },
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { errorMsg: error.response.data },
      });
      //   toast.error(error.response.data.error);
    }
  } else
    dispatch({
      type: "ERROR",
      payload: { errorMsg: error.message },
    });
};
