import Api from "../../Api";
import { handleError } from "./commonAction";

export const getOrderList = () => async (dispatch) => {
  try {
    const userId = JSON.parse(localStorage.getItem("userData")).id;
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/orders/${userId}`);

    console.log("ORDER LIST", res)

    await dispatch({
      type: "ORDER_LIST",
      payload: { data: res?.data?.data }
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};


export const getOrderData = (orderId) => async (dispatch) => {
  try {
    const userId = JSON.parse(localStorage.getItem("userData")).id;
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/orders/${userId}/orderData/${orderId}`);

    console.log("ORDER_DATA", JSON.parse(res.data.data.address))

    await dispatch({
      type: "ORDER_DATA",
      payload: { data: res?.data?.data }
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};

export const updateOrderData = (orderId, body) => async (dispatch) => {
  try {
    const userId = JSON.parse(localStorage.getItem("userData")).id;
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.patch(`/api/v1/orders/${userId}/updateOrderData/${orderId}`, body);

    await dispatch({
      type: "ORDER_DATA_UPDATE",
      payload: { data: res?.data?.data }
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};



export const getOrderDetails = (orderId) => async (dispatch) => {
  try {
    const userId = JSON.parse(localStorage.getItem("userData")).id;
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/orders/${userId}/${orderId}`);

    console.log("ORDER_DETAILS", res)

    await dispatch({
      type: "ORDER_DETAILS",
      payload: { data: res?.data?.data }
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};


export const getTrackingData = (orderId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/usps/${orderId}`);
    console.log(`responseeeeeeeeeeee`, res?.data?.trackData?.elements)
    await dispatch({
      type: "GET_ORDER_TRACKING_DETAILS",
      payload: { data: res?.data?.trackData?.elements }
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};


export const orderConfirmationDetails = (orderId, status) => async (dispatch) => {
  const userId = JSON.parse(localStorage.getItem("userData")).id;
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/orders/${userId}/${orderId}/${status}`);
    console.log("ssssssssssssss", res)
    await dispatch({
      type: "GET_ORDER_CONFIRMATION_DETAILS",
      payload: { data: res?.data?.data }
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
}



export const getTestReports = () => async (dispatch) => {
  const email = JSON.parse(localStorage.getItem("userData")).email;
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/api/v1/testReports/${email}`);
    console.log("ssssssssssssss", res)
    await dispatch({
      type: "GET_TEST_REPORTS",
      payload: { data: res?.data?.data }
    });
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
}


export const submitQuery = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });

    const res = await Api.post(`/api/v1/testReports/submitEnquiry`, { ...body });

    await dispatch({
      type: "GET_TEST_REPORTS",
      payload: { data: res?.data }
    });
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }

}

export const resetSuccess = () => async (dispatch) => {
  dispatch({ type: "CLEAN_MESSAGE" });
}


export const registerKit = (body) => async (dispatch) => {
  try {
    dispatch({ type: "SAVING_KIT_ADD" });
    const res = await Api.post(`/api/v1/kit-registration/register`, { ...body });
    dispatch({ type: "SAVED_KIT_ADD" });
    return res.data;
  } catch (error) {
    dispatch({ type: "SAVED_KIT_FAILURE" });
    dispatch(handleError(error));
  }
};

export const checkKitId = (registerKitId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/testReports/checkKitId`, { registerKitId });
    dispatch({ type: "LOADING_COMPLETED" });

    if(res.data.isFound) {
      dispatch({
        type: "KIT_ID_VERIFIED",
        payload: {
          registerKitId,
          testName: res.data.kitDetail?.testName,
          testImage: res.data.kitDetail?.productImage,
        }
      });
    }
    return res.data
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }

};

export const saveTesterDetail = (testerDetail) => (dispatch) => {
  dispatch({ type: "KIT_TESTER_DETAIL_ADD", payload: testerDetail });
};

export const resetKitRegistration = () => (dispatch) => {
  dispatch({ type: "KIT_REGISTRATION_RESET" });
};

export const saveFeedback = (payload) => async (dispatch) => {
  try {
    payload = {
      ...payload,
      userId: JSON.parse(localStorage.getItem("userData")).id
    };
    const res = await Api.post(`/api/v1/order-feedback`, payload);
    return res.data;
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};
