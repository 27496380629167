import Api from "../../Api";

export const setLocation = (searchLocation) => async (dispatch) => {
    try {
        dispatch({ type: "SET_SEARCH_LOCATION", payload: searchLocation });
    } catch (error) {
        dispatch({ type: "SET_SEARCH_LOCATION_ERROR", payload: error });
    }
};

export const setLocationDetail = (searchLocationDetail) => async (dispatch) => {
    try {
        dispatch({ type: "SET_SEARCH_LOCATION_DETAIL", payload: searchLocationDetail });
    } catch (error) {
        dispatch({ type: "SET_SEARCH_LOCATION_ERROR", payload: error });
    }
};

export const setAppointmentDetail = (appointment) => async (dispatch) => {
    try {
        dispatch({ type: "SET_APPOINTMENT_DETAIL", payload: appointment });
    } catch (error) {
        dispatch({ type: "SET_SEARCH_LOCATION_ERROR", payload: error });
    }
};

export const setAssessment = (assessmentDetail) => async (dispatch) => {
    try {
        dispatch({ type: "SET_ASSESSMENT_DETAIL", payload: assessmentDetail });
    } catch (error) {
        dispatch({ type: "SET_ASSESSMENT_DETAIL_ERROR", payload: error });
    }
};

export const getTestLocation = (testType) => async (dispatch, store) => {
    try {
        const location = store().covidReducer.searchLocation;
        const res = await Api.get(`/api/v1/test-location/list?location=${location}&testType=${testType}`);
        return res.data;
    } catch (error) {
        dispatch({ type: "GET_TEST_LOCATION_ERROR", payload: error });
    }
};

export const resetError = () => async (dispatch) => {
    dispatch({ type: "RESET_ERROR" });
};

export const getAvailableAppointments = (date, locationId) => async (dispatch) => {
    try {
        const res = await Api.get(`/api/v1/appointments/available/${date}/${locationId}`);
        return res.data;
    } catch (error) {
        dispatch({ type: "GET_AVAILABLE_APPOINTMENT_ERROR", payload: error });
    }
};

export const reserveAppointment = (appointmentId) => async (dispatch) => {
    try {
        const res = await Api.get(`/api/v1/appointments/reserve/${appointmentId}`);
        return res.data;
    } catch (error) {
        dispatch({ type: "GET_AVAILABLE_APPOINTMENT_ERROR", payload: error });
    }
};

export const saveVisitInfo = (visitInfo) => async (dispatch) => {
    try {
        dispatch({ type: "SET_COVID_VISIT_DETAIL", payload: visitInfo });
    } catch (error) {
        dispatch({ type: "SET_VISIT_INFO_DETAIL_ERROR", payload: error });
    }
};

export const setConfirmationCode = (code) => async (dispatch) => {
    dispatch({ type: "SET_CONFIRMATION_CODE", payload: code });
};

export const confirmAppointment = () => async (dispatch, store) => {
    try {
        const covidReducer = store().covidReducer;
        const res = await Api.post(`/api/v1/appointments/confirm/${covidReducer.appointmentDetail.id}`, covidReducer);
        return res.data;
    } catch (error) {
        dispatch({ type: "GET_AVAILABLE_APPOINTMENT_ERROR", payload: error });
    }
};

export const createCharge = (tokenObj) => async (dispatch, store) => {
    try {
        const testType = store().covidReducer.searchLocationDetail.testType;
        const res = await Api.post(`/api/v1/appointments/stripe/create-charge`, {...tokenObj, testType});
        return res.data;
    } catch (error) {
        dispatch({ type: "CREATE_CHARGE_ERROR", payload: error });
    }
};

export const getUserBookings = (userEmail) => async (dispatch, store) => {
    try {
        const res = await Api.post(`/api/v1/appointments/customer/bookings`, {userEmail});
        return res.data;
    } catch (error) {
        dispatch({ type: "FETCH_BOOKING_ERROR", payload: error });
    }
};

export const saveBookingInfo = (bookingData) => async (dispatch) => {
    try {
        dispatch({ type: "SET_BOOKING_DETAIL", payload: bookingData });
    } catch (error) {
        dispatch({ type: "SET_BOOKING_DETAIL_ERROR", payload: error });
    }
};

export const cancelBooking = (bookingId) => async (dispatch) => {
    try {
        const res = await Api.put(`/api/v1/appointments/cancel-booking/${bookingId}`);
        return res.data;
    } catch (error) {
        dispatch({ type: "CANCEL_BOOKING_ERROR", payload: error });
    }
};
