import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { formatPhoneNumber } from "react-phone-number-input";
import "./covid.scss";
import moment from "moment";
import {covidAction} from "../../Redux/Actions";
import {NotificationManager} from "react-notifications";

const CovidMyBooking = (props) => {
    const dispatch = useDispatch();
    const covidReducer = useSelector((state) => state.covidReducer);
    const [processingCancel, setProcessingCancel] = useState('');

    useEffect(() => {
        if(!covidReducer.bookingInfo.userEmail) {
            props.history.push('/covid-19-testing/check-booking');
        }
    }, []);

    const getAppointmentDate = (booking) => {
        return (
            <div className={'d-flex flex-column'}>
                <div>{moment(booking.appointmentDate).format('ddd MMM DD/YY')}</div>
                <div style={{'font-size': '12px'}}>{booking.appointmentStart}-{booking.appointmentEnd}</div>
            </div>
        );
    };

    const getAppointmentLocation = (booking) => {
        return (
            <div className={'d-flex flex-column'}>
                <div>{booking.locationName}</div>
                <div style={{'font-size': '12px'}}>{booking.locationStreet}</div>
                <div style={{'font-size': '12px'}}>{booking.locationCity}, {booking.locationState} - {booking.locationZipCode}</div>
            </div>
        );
    };

    const userEmail = covidReducer.bookingInfo.userEmail;

    const viewReport = (booking) => {
        window.open(booking.report_doc, "_blank");
    };

    const cancelBooking = async (booking) => {
        setProcessingCancel(booking.id);
        const cancelResponse = await dispatch(covidAction.cancelBooking(booking.id));
        setProcessingCancel(null);
        if(!cancelResponse.isSuccess) {
            NotificationManager.error(cancelResponse.errorMessage || 'Something went wrong. Please try again.');
            return;
        }
        const bookings = covidReducer.bookingInfo.bookingData.map((bookingObj) => {
            if(bookingObj.id === booking.id) {
                bookingObj.status = 'cancelled';
            }
            return bookingObj;
        });
        const bookingData = {
            userEmail,
            bookingData: bookings
        };
        await dispatch(covidAction.saveBookingInfo(bookingData));
        NotificationManager.success('Appointment cancelled successfully');
    };

    const renderAppointments = () => {
        const bookings = covidReducer.bookingInfo.bookingData;
        if(bookings.length === 0) {
            return (
                <tr>
                    <td colspan={8} className={'text-center'}>
                        <span>There are no bookings for this user</span>
                    </td>
                </tr>
            )
        }
        return bookings?.map((booking) => {
            return (
                <tr key={`${booking.id}`}>
                    <td>{booking.patient_first_name} {booking.patient_last_name}</td>
                    <td>{formatPhoneNumber(booking.primary_phoneNumber || booking.patient_mobile)}</td>
                    <td>{getAppointmentDate(booking)}</td>
                    <td>{getAppointmentLocation(booking)}</td>
                    <td className={'confirmation'}>
                        <span>{booking.confirmation_code}</span>
                    </td>
                    <td className={'test-status'}>
                        {
                            booking.status === 'cancelled'?
                                <span className={"cancelled"}>Cancelled</span>:
                                     booking.is_checked_in ?
                                        booking.is_report_generated?
                                            <span className={"generated"}>Report Generated</span>:
                                                <span className={"in-progress"}>In-Progress</span>:
                                                    <span className={"pending"}>Pending</span>

                        }
                    </td>
                    <td>{booking.test_type}</td>
                    <td>
                        {
                            booking.is_checked_in === 1 && booking.is_report_generated === 1 &&
                            <button
                                type="button"
                                className="orange"
                                onClick={() => viewReport(booking)}
                            >
                                View Report <i className="fa fa-eye"></i>
                            </button>
                        }
                        {
                            booking.status === 'active' && booking.is_checked_in === 0 &&
                            <button
                                type="button"
                                className="secondary-outline-btn"
                                onClick={() => cancelBooking(booking)}
                                disabled={processingCancel === booking.id}
                            >
                                Cancel Booking
                                {
                                    processingCancel === booking.id &&
                                    <span className={"spinner-border"} ></span>

                                }

                            </button>
                        }
                    </td>
                </tr>
            );
        });
    };

    return (
        <div id="bookings-container">
            <div id="booking-row" className="row">
                <div>
                    <label><b>Booking for user: </b> &nbsp;</label>
                    <span> {userEmail}</span>
                </div>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                         <tr>
                            <th>Full Name</th>
                            <th>Phone</th>
                            <th>Appointment</th>
                            <th>Location</th>
                            <th>Code</th>
                            <th>Status</th>
                            <th>Test Type</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                            {renderAppointments()}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
};

export default CovidMyBooking;
