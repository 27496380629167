import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom'
import { cartAction } from "../Redux/Actions/index";
import Coupon from "../Images/discount.png";
import {resetForceRemoveCouponCode} from "../Redux/Actions/cartAction";

const OrderSummary = (props) => {
  const dispatch = useDispatch();
  const [subTotals, setSubTotal] = useState(0.00);
  const [state, setState] = useState({
    showCouponBtnLoader: true,
    couponErrorResMessage: "",
    couponSuccessResMessage: "",
    discountId: null,
    discountPercent: 0,
    totalDiscount: 0
  });
  const [total, setTotal] = useState(0.00);
  const [prevCartDetails, setPrevCartDetails] = useState([]);
  const [cartHasSubscription, setCartHasSubscription] = useState([]);

  const authData = useSelector((state) => state.authReducer.authData);
  const cartDetails = useSelector((state) => state.cartReducer.cartItems);
  const forceRemoveCouponCode = useSelector((state) => state.cartReducer.forceRemoveCouponCode);
  const cartReducer = useSelector((state) => state.cartReducer);
  const couponCode = useSelector((state) => state.cartReducer)

  useEffect(() => {
    (async () => {
      console.log("SUMMERY PAGE ON MOUNT")
      if (localStorage.getItem('couponCode') !== null) {

        await dispatch(cartAction.checkCoupnCodeExist())
      }
    })();
  }, []);

  useEffect(() => {
    setCartHasSubscription((cartDetails || []).some(cartItem => cartItem.subscription && cartItem.subscriptionDuration));
    if (cartDetails?.length !== prevCartDetails?.length) {

      (async () => {
        console.log("SUMMERY PAGE ON UPDATE")
        setPrevCartDetails(cartDetails)
        await dispatch(cartAction.calculateSubTotal(cartDetails, state?.discountPercent))
      })();
    }
  }, [cartDetails]);

  useEffect(() => {
    if (forceRemoveCouponCode) {
      removeCoupon();
      dispatch(resetForceRemoveCouponCode());
    }
  }, [forceRemoveCouponCode]);

  // const calculateSubTotal = async () => {
  //   let subTotal = 0.0;
  //   let total = 0.0;
  //   await cartDetails?.map((data, i) => {
  //     if (data) {
  //       subTotal = data.price + subTotal;
  //     }
  //   });

  //   total = subTotal * (1 - (20 / 100))

  //   setSubTotal(subTotal);
  //   setTotal(total)
  // };

  const handleCouponCode = (e) => {
    e.preventDefault();
    let code = e.target.value;
    setState({ ...state, discountCode: code.toUpperCase() })
  }



  const onApplyCoupon = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("token")) {
      setState({
        ...state,
        showCouponBtnLoader: true,
        couponErrorResMessage: "",
        couponSuccessResMessage: "",
        discountId: null,
        discountPercent: 0,
        totalDiscount: 0
      })

      let couponCode = state.discountCode?.trim();
      console.log("couponCode is", couponCode);
      if (couponCode?.length > 0) {

        if (state.isErrorItem) {
          setState({
            ...state,
            couponErrorResMessage: "Please remove the error item to apply.",
            showCouponBtnLoader: false
          })
          return;
        }

        const res = await dispatch(cartAction.validateAppliedCoupon(cartReducer?.subTotal, state.discountCode,cartDetails))

        if (res && res.data) {
          if (res.data.isValid && res.data.isApplied) {
            await dispatch(cartAction.setCouponCode(state.discountCode))
            // coupon applied
            // let discount = (this.state.cartSubtotal * res.data.discountPercent) / 100;
            setState({
              ...state,
              showCouponBtnLoader: true,
              discountId: res.data.discountCodeId,
              couponSuccessResMessage: res.data.message,
              discountPercent: +res.data.discountPercent,
            });
            await dispatch(cartAction.calculateSubTotal(cartDetails, res.data.discountPercent))
          }
          else {
            // coupon not applied or coupon does not exist
            setState({
              ...state,
              showCouponBtnLoader: false,
              couponErrorResMessage: res.data.message,
            });
          }

        }
        else {
          // something went wrong
          setState({
            ...state,
            showCouponBtnLoader: false,
            couponErrorResMessage: "something went wrong"
          })
        }
      }
      else {
        setState({
          ...state,
          showCouponBtnLoader: false,
          couponErrorResMessage: "",
          couponSuccessResMessage: ""
        })

      }
    }
    else {
      // user is not logged in
      props.history.push('/signin');
    }
  }
  const removeCoupon = async () => {
    setState({
      ...state,
      couponErrorResMessage: "",
      couponSuccessResMessage: ""
    });
    await dispatch(cartAction.removeCouponCode())
    await dispatch(cartAction.calculateSubTotal(cartDetails))
  }

  return (<div className="order-main-sum">
    <h3>Order Summary</h3>
    <div>
      <div className={"item"}>
        <p>Subtotal</p>
        <p>${cartReducer?.subTotal}</p>
      </div>
    </div>
    <div>
      <div className={"item"}>
        <p>Delivery</p>
        <p>$00.00</p>
      </div>
    </div>
    {
      (authData.userType !== 'Normal User' || cartHasSubscription)? null:
          <>
            {
              !couponCode.couponCode &&
              <div className="d-flex justify-content-between my-4">
                <input className={"w-100"} placeholder="Coupon Code" value={state.discountCode} onChange={e => handleCouponCode(e)} ></input>
                <button className="coupon_apply" onClick={(e) => onApplyCoupon(e)}>Apply</button>
              </div>
            }
            {(() => {
              if(couponCode.couponCode) {
                if (state.couponSuccessResMessage !== '' && state.couponSuccessResMessage !== undefined) {
                  return <div className="alert alert-success alert-dismissible">{state.couponSuccessResMessage}
                    <button onClick={removeCoupon} className="close" title="close">×</button>
                  </div>
                } else {
                  return <div className="alert alert-success alert-dismissible">Discount Applied!
                    <button onClick={removeCoupon} className="close" title="close">×</button>
                  </div>
                }
              }
              else if (state.couponErrorResMessage !== '' && state.couponErrorResMessage !== undefined) {
                return <div className="alert alert-danger alert-dismissible">{state.couponErrorResMessage}</div>
              }
            })()}
          </>
    }
    <div className="d-flex justify-content-between total-div">
      <p>Total</p>
      <p>${cartReducer?.total}</p>
    </div>
  </div>);
}

export default  React.memo(withRouter(OrderSummary));
