export const THREE_MONTHS_SUB =  1;
export const SIX_MONTHS_SUB =  2;
export const TWELVE_MONTHS_SUB =  3;


export const ONE_TIME_PAYMENT =  'one_time_purchase';
export const SUBSCRIPTION_PAYMENT =  'subscription';


export const subscriptionDuration = {
    1:"Every 3 Months",
    2:"Every 6 Months",
    3:"Every 12 Months",
}

export const SHIPPING_PAGE = "shipping";

export const PAYMENT_PAGE = 'payment';

export const REGISTER_KIT = 'register-kit';
